export const currencyFormatter = (
  amount: number | undefined,
  currency: string | undefined
) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ?? "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount ?? 0);
};
