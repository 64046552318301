import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RootRouter } from "./router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { makeServer } from "./mockServer";
import { SnackbarProvider } from "./hooks/useSnackbar";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

if (process.env.REACT_APP_USE_MOCK_SERVER === "true") {
  makeServer({
    environment: "development",
  });
}

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={googleClientId}>
      <React.StrictMode>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <RootRouter />
          </QueryClientProvider>
        </SnackbarProvider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </Provider>
);
