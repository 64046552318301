import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const OverviewContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 1.125rem;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: ${Spaces.spacing_3xl};
  padding-top: ${Spaces.spacing_2xl};
  padding-bottom: ${Spaces.spacing_sm};
  background-color: ${Colors.bg_primary};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  width: 48%;
  height: fit-content;
`;

export const DataContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Spaces.spacing_md};
`;

export const DataContainerTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const GeneralDataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.bg_primary_hover};
  border-radius: ${Radiuses.radius_md};
  padding: ${Spaces.spacing_md} ${Spaces.spacing_xl};
  box-sizing: border-box;
  margin-bottom: ${Spaces.spacing_sm};
`;

export const GeneralDataLabel = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const GeneralDataValue = styled(Typography)<{ error?: boolean }>`
  color: ${({ error }) =>
    error ? Colors.text_error_primary : Colors.text_secondary_hover};

  white-space: break-spaces;
  text-align: right;
`;

export const GeneralDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const NoContractContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Spaces.spacing_md};
`;

export const NoContractText = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_3xl};
`;

export const ExpiringContractBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_xl};
  background-color: ${Colors.bg_warning_primary};
  border-radius: ${Radiuses.radius_md};
  gap: ${Spaces.spacing_xl};
  margin-bottom: ${Spaces.spacing_md};
`;

export const ExpiringContractTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const ExpiringContractDescription = styled(Typography)`
  color: ${Colors.gray_Dar_mode_600};
  white-space: pre-line;
`;

export const ExpiringContractButtons = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
`;
