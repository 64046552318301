import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../Typography";

export const TabsContainer = styled.div`
  margin-top: ${Spaces.spacing_2xl};
  display: flex;
  border-bottom: 1px solid ${Colors.border_secondary};
`;

export const Tab = styled(Typography)<{ active: boolean }>`
  color: ${({ active }) =>
    active ? Colors.text_secondary_700 : Colors.text_quarterary_500};
  cursor: pointer;
  margin-right: ${Spaces.spacing_xl};
  border-bottom: ${({ active }) =>
    active ? `2px solid ${Colors.fg_brand_primary_alt}` : "transparent"};
  padding-bottom: ${Spaces.spacing_md};
`;
