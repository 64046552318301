import React, { useState } from "react";
import {
  Container,
  Subtitle,
  Title,
  TitlesContainer,
  AccountTypeContainer,
  UserIConContainer,
  AccountTypeOption,
  CheckBoxRow,
  OptionName,
  OptionSubtext,
  ButtonsGroup,
  NextButton,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import UsersIcon from "../../../../Images/icons/usersIcon.svg";
import Checkbox from "../../../shared/Checkbox";
import ArrowLeft from "../../../../Images/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { HOME_SCREEN } from "../../../../constants/routesNames";
import { RegistrationSteps } from "../../../../constants/registration";
import Button from "../../../shared/Button";

const AccountType: React.FC = () => {
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();

  return (
    <Container>
      <TitlesContainer>
        <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
          Choose your account type{" "}
        </Title>
        <Subtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Choose your account type
        </Subtitle>
      </TitlesContainer>

      <AccountTypeContainer>
        <UserIConContainer>
          <img src={UsersIcon} alt="user-icon" />
        </UserIConContainer>

        <AccountTypeOption>
          <CheckBoxRow>
            <OptionName size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
              Business
            </OptionName>
            <Checkbox label="" checked={checked} onChange={() => {}} />
          </CheckBoxRow>
          <OptionSubtext size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
            Sign in as an organization
          </OptionSubtext>
        </AccountTypeOption>
      </AccountTypeContainer>

      <ButtonsGroup>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowLeft} alt={"arrow-left"} />}
          onClick={() => navigate(HOME_SCREEN)}
        />

        <NextButton
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() =>
            navigate(`/register/${RegistrationSteps.user_registration}`)
          }
        />
      </ButtonsGroup>
    </Container>
  );
};

export default AccountType;
