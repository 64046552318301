import appStateSlice from "./slices/appState";
import authSlice from "./slices/authSlice";
import companiesSlice from "./slices/companiesSlice";
import peopleSlice from "./slices/peopleSlice";
import payrollSlice from "./slices/payrollSlice";
import paymentSlice from "./slices/paymentSlice";
import assetsHubSlice from "./slices/assetsHubSlice";

export const rootReducer = {
  auth: authSlice,
  companies: companiesSlice,
  people: peopleSlice,
  appState: appStateSlice,
  payroll: payrollSlice,
  payment: paymentSlice,
  assetsHub: assetsHubSlice,
};
