import {
  PaymentAlertContainer,
  PaymentAlertContent,
  PaymentAlertDescription,
  PaymentAlertTitle,
} from "./styled";
import AlertIcon from "../../../../Images/icons/alertWarningYellow.svg";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";

const PaymentAlert = () => {
  return (
    <PaymentAlertContainer>
      <img alt="warning-icon" width={20} height={20} src={AlertIcon} />
      <PaymentAlertContent>
        <PaymentAlertTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
          We have not received your payments yet
        </PaymentAlertTitle>
        <PaymentAlertDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          Please send the payments as soon as possible to make sure team is paid
          on time.
        </PaymentAlertDescription>

        <Button
          text="View Payments"
          variant={ButtonVariants.tertiary_color}
          size={ButtonSizes.sm}
          style={{ width: "fit-content" }}
        />
      </PaymentAlertContent>
    </PaymentAlertContainer>
  );
};

export default PaymentAlert;
