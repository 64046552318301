import { useNavigate } from "react-router-dom";
import { FontSizes, FontWeights, Spaces } from "../../../../constants/styles";
import { Container, ContentContainer, Title, SubTitle } from "./styled";
import LogoutButton from "../../LogoutButton";
import { useAppSelector } from "../../../../redux/reduxHooks";
import { selectToken } from "../../../../redux/slices/authSlice";
import OrganizationDetailsForm from "./OrganizationDetailsFrom";

const OrganizationDetails = () => {
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);

  if (!token) navigate("/login");
  return (
    <Container>
      <LogoutButton style={{ marginBottom: Spaces.spacing_6xl }} />
      <ContentContainer>
        <div>
          <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Add you organization details
          </Title>
          <SubTitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
            Please provide your Organization information accurately, it will
            {"\n"} be used in all your communications on the platform.
          </SubTitle>
        </div>

        <OrganizationDetailsForm />
      </ContentContainer>
    </Container>
  );
};

export default OrganizationDetails;
