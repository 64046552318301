import styled from "styled-components";
import { Colors, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const PayslipDetails = styled.div`
  width: 100%;
  padding-block: ${Spaces.spacing_3xl};
`;
export const PayslipDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block-end: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;
export const PayslipDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PayslipDetailsHeaderButtons = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 23px;
  flex-wrap: wrap;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;
