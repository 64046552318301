import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../../components/shared/Typography";
import { Table } from "../../../../components/shared/Table/styled";

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Spaces.spacing_2xl};
  width: 35%;
`;

export const PaymentsTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${Radiuses.radius_xl};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  margin-top: ${Spaces.spacing_2xl};
  background-color: ${Colors.bg_primary};
`;

export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const TableHeadWithIcon = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
  cursor: pointer;
  align-items: center;
`;

export const ManageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: ${Spaces.spacing_2xl};
  gap: ${Spaces.spacing_2xl};
`;

export const PaymentsTable = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  border-collapse: collapse;
  position: relative;
  scrollbar-width: none;
  overflow: hidden;
`;