import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Button from "../../../shared/Button";
import {
  ButtonsContainer,
  Container,
  ConfirmationCard,
  ConfirmationCardRow,
} from "./styled";
import {
  CardHeader,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import { onPreviousPayrollStep } from "../../../../redux/slices/payrollSlice";
import { useNavigate } from "react-router-dom";
import { OVARC_BANK_ACCOUNT } from "../../../../mockServer/MockData";
import {
  useGetPayrollProcess,
  useSubmitPayroll,
} from "../../../../queries/payroll";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";

const Confirmation = () => {
  const { data } = useGetPayrollProcess();

  const payrollProcessData = useAppSelector(
    (state) => state.payroll.payrollProcessData
  );
  const { mutateAsync: submitPayroll } = useSubmitPayroll();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const onDone = async () => {
    try {
      // await submitPayroll({
      //   paymentMethod: payrollProcessData.paymentMethod || "",
      //   manualTransferMethod: payrollProcessData.manualTransferMethod || "",
      //   bankDetails: {
      //     location: payrollProcessData.bankDetails.location.label,
      //     currency: payrollProcessData.bankDetails.currency.label,
      //   },
      // });
      showSnackbar({
        open: true,
        title: "Payment is now processing",
        description:
          "Please send the payment from your bank account. If it's late, some employees might get their pay late too.",
        type: SnackbarTypes.SUCCESS,
      });

      navigate("/payment");
    } catch (error) {
      showSnackbar({
        open: true,
        title: "Something went wrong while processing your payroll",
        description: "Please try again later.",
        type: SnackbarTypes.ERROR,
      });
    }
  };

  return data ? (
    <Container>
      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payment Details
          </CardTitle>
        </CardHeader>
        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Amount Due
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {currencyFormatter(data?.total_cost, data?.currency)}
          </CardRowValue>
        </ConfirmationCardRow>
      </ConfirmationCard>

      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Manual transfer details
          </CardTitle>
        </CardHeader>
        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Method
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {payrollProcessData.manualTransferMethod}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Account Location
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {payrollProcessData.bankDetails.location.label}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Currency
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {payrollProcessData.bankDetails.currency.label}
          </CardRowValue>
        </ConfirmationCardRow>
      </ConfirmationCard>

      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Ovarc bank account details
          </CardTitle>
        </CardHeader>
        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Currency
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.currency}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Beneficiary
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.beneficiary}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Account Number
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.account_number}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Swift Code
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.swift_code}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            IBAN
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.iban}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Address
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.address}
          </CardRowValue>
        </ConfirmationCardRow>
      </ConfirmationCard>

      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            dispatch(onPreviousPayrollStep());
          }}
        />
        <Button
          text="Done"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            onDone();
          }}
        />
      </ButtonsContainer>
    </Container>
  ) : null;
};

export default Confirmation;
