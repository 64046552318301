import styled from "styled-components";
import { Colors, FontSizes, FontWeights, Spaces } from "../../constants/styles";
import BgImg from "../../Images/404.svg";
import Typography from "../../components/shared/Typography";

export const ErrorScreenWrapper = styled.div<{ loggedIn: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${Colors.bg_active};
  justify-content: center;
  padding-top: ${Spaces.spacing_7xl};
  background-image: url(${BgImg});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  ${(props) =>
    props.loggedIn &&
    `justify-content: center; padding-top: ${Spaces.spacing_none};`}
`;

export const ErrorScreenContent = styled.div<{ loggedIn: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_3xl};
  margin-top: ${Spaces.spacing_11xl};
  margin-bottom: ${Spaces.spacing_6xl};

  ${(props) => props.loggedIn && `margin-top: ${Spaces.spacing_none};`}
`;
export const ErrorScreenTitle = styled(Typography)`
  color: ${Colors.text_primary};
  text-align: center;
`;
export const ErrorScreenDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  text-align: center;
  white-space: pre-line;
`;

export const EmailSpan = styled.span`
  font-size: ${FontSizes.TX_XL};
  font-weight: ${FontWeights.REGULAR};
  color: ${Colors.text_secondary_on_brand};
`;

export const ErrorScreenButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spaces.spacing_6xl};
`;
