import React, { ReactNode, createContext, useContext, useState } from "react";
import Snackbar from "../components/shared/Snackbar";
import { SnackbarProps, SnackbarTypes } from "../types/componentsProps";

interface SnackbarContextProps {
  showSnackbar: (props: {
    open: boolean;
    title: string;
    description: string;
    type: SnackbarTypes;
    actionBtnText?: string;
    actionBtnCallback?: () => void;
    duration?: number;
  }) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

export const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error("useSetsContext must be used within a SnackbarProvider");
  }

  return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    open: false,
    title: "",
    description: "",
    type: SnackbarTypes.SUCCESS,
    onClose: () => {},
  });

  const showSnackbar = (props: {
    open: boolean;
    title: string;
    description: string;
    type: SnackbarTypes;
    actionBtnText?: string;
    actionBtnCallback?: () => void;
    duration?: number;
  }) => {
    setSnackbarProps({
      ...props,
      onClose: () => {
        setSnackbarProps((prev) => ({ ...prev, open: false }));
      },
    });
  };

  const contextValue: SnackbarContextProps = {
    showSnackbar,
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar {...snackbarProps} />
    </SnackbarContext.Provider>
  );
};
