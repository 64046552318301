export type StatusKey = 'Onboarding' | 'Offboarding';

export enum UPCOMING_ACTIONS_ENUM {
    INVOICES = "Invoices",
    PAYROLLS = "Payrolls",
    CONTRACTS = "Contracts",
}

export enum PAYMENT_METHODS {
    CRYPTO = "Crypto-Currency",
    TRANSFER = "Transfer",
    PAYPAL = "PayPal",
    DEBIT_CARD = "Debit Card",
    CREDIT_CARD = "Credit Card"
}

export type UpcomingActionsKey = typeof UPCOMING_ACTIONS_ENUM[keyof typeof UPCOMING_ACTIONS_ENUM];

export type PaymentMethodsKey = typeof PAYMENT_METHODS[keyof typeof PAYMENT_METHODS];