import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";

export const PartialDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputsContainer = styled.div<{ error: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Radiuses.radius_md};
  background-color: #fff;
  justify-content: space-between;
  padding: 0px;
  margin-top: ${Spaces.spacing_sm};

  &:focus-within {
    border: 1px solid ${Colors.border_brand};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(158, 119, 237, 0.24);
  }
  ${(props) =>
    props.error &&
    `
    border: 1px solid ${Colors.border_error};
   
  `};
`;

export const ExtraMessage = styled.p<{ error: boolean }>`
  display: ${(props) => (props.error ? "block" : "none")};
  margin-block-end: ${Spaces.spacing_none};
  margin-block-start: ${Spaces.spacing_sm};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.REGULAR};
  color: ${Colors.text_error_primary};
`;
