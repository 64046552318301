import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const ManualTransferStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
export const StepTitleCard = styled.div`
  display: flex;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_3xl};
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  margin-bottom: ${Spaces.spacing_md};
`;

export const StepTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_lg};
  border-radius: ${Radiuses.radius_xl};
  border: 2px solid ${Colors.bg_brand_solid};
  padding: ${Spaces.spacing_xl};
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_tertiary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Title = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const Subtext = styled(Typography)`
  color: ${Colors.gray_dark_mode_400};
`;

export const BannerContainer = styled.div`
  display: flex;
  padding: ${Spaces.spacing_xl};
  gap: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_disabled};
  align-items: start;
  margin-bottom: ${Spaces.spacing_md};
`;
export const BannerText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
