import React from "react";
import { FontSizes, FontWeights, Spaces } from "../../../constants/styles";
import { Colors } from "../../../constants/styles";
import Typography from "../Typography";

const Label: React.FC<{ text: string }> = ({ text, ...props }) => {
  return (
    <Typography
      size={FontSizes.TX_SM}
      weight={FontWeights.MEDIUM}
      style={{ color: Colors.text_secondary_700, margin: Spaces.spacing_none }}
      {...props}
    >
      {text}
    </Typography>
  );
};

export default Label;
