import React from "react";
import {
  ErrorScreenContent,
  ErrorScreenWrapper,
  ErrorScreenTitle,
  ErrorScreenDescription,
  EmailSpan,
  ErrorScreenButtonsContainer,
} from "./styled";
import Logo from "../../Images/icons/LogoColored.svg";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../constants/styles";
import Button from "../../components/shared/Button";
import ArrowLeft from "../../Images/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { LOGIN_SCREEN } from "../../constants/routesNames";
import { useAppSelector } from "../../redux/reduxHooks";
import { selectToken } from "../../redux/slices/authSlice";

const ErrorScreen: React.FC = () => {
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();
  const isLogged = !!token;

  return (
    <ErrorScreenWrapper loggedIn={isLogged}>
      {!isLogged && <img src={Logo} alt="logo" width={214} height={60} />}

      <ErrorScreenContent loggedIn={isLogged}>
        <ErrorScreenTitle size={FontSizes.XL} weight={FontWeights.REGULAR}>
          We can’t find this page
        </ErrorScreenTitle>
        <ErrorScreenDescription
          size={FontSizes.TX_XL}
          weight={FontWeights.REGULAR}
        >
          The page you are looking for doesn't exist or has been moved.{"\n"}If
          this issue persist, kindly reach out to our support team{"\n"}at{" "}
          <EmailSpan> help@ovarc.io </EmailSpan>
          for assistance.{" "}
        </ErrorScreenDescription>
      </ErrorScreenContent>

      <ErrorScreenButtonsContainer>
        <Button
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.xxl}
          text="Go back"
          iconLeading={<img src={ArrowLeft} alt="arrow-left" />}
          onClick={() => navigate(-1)}
        />
        {!isLogged && (
          <Button
            variant={ButtonVariants.primary}
            size={ButtonSizes.xxl}
            text="Go To Login"
            onClick={() => navigate(LOGIN_SCREEN)}
          />
        )}
      </ErrorScreenButtonsContainer>
    </ErrorScreenWrapper>
  );
};

export default ErrorScreen;
