import { ASSETS_DETAILS, ASSETS_HUB_LIST, ASSETS_OVERVIEW } from "../mockServer/MockData";
import { BadgeTypes } from "./componentsProps";

export type UserAsset = (typeof ASSETS_HUB_LIST)[0];
export type UserAssetsOverview = typeof ASSETS_OVERVIEW;
export type UserAssetDetails = (typeof ASSETS_DETAILS)[0];

export enum ASSETS_HUB_ASSETS_LIST_STATUS {
    inuse = "In-Use",
    inactive = "In-Active",
    pendingDelivery = "Pending Delivery",
    underMaintenance = "Under Maintenance",
    decommissioned = "Decommissioned",
    lost = "Lost",
  }
  
  export const ASSETS_LIST_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
    [ASSETS_HUB_ASSETS_LIST_STATUS.inuse]: BadgeTypes.success,
    [ASSETS_HUB_ASSETS_LIST_STATUS.inactive]: BadgeTypes.gray,
    [ASSETS_HUB_ASSETS_LIST_STATUS.pendingDelivery]: BadgeTypes.blueLight,
    [ASSETS_HUB_ASSETS_LIST_STATUS.underMaintenance]: BadgeTypes.warning,
    [ASSETS_HUB_ASSETS_LIST_STATUS.decommissioned]: BadgeTypes.error,
    [ASSETS_HUB_ASSETS_LIST_STATUS.lost]: BadgeTypes.error,
  };
  