import { ReactNode } from "react";

export enum DASHBOARD_PAYMENTS_STATUS {
  WaitingForPaymentToArrive = "Waiting for payment to arrive",
  Processing = "Processing",
}
export type SideBarEntry = {
  text: string;
  icon: string;
  path: string;
  extraPaths?: string[];

  children?: {
    text: string;
    path: string;
    extraPaths?: string[];
  }[];
};

export interface DashboardBanner {
  id: string;
  title: string;
  description: string;
  btnText: string;
  onClick: () => void;
  color: string;
  image: ReactNode;
}

export type Country = {
  id: number;
  name: string;
  flag?: string;
};
