import React, { useEffect } from "react";
import { Company } from "../../../../types/companies";
import Modal from "../../../shared/Modal";
import {
  CompanyDetailsLabel,
  CompanyDetailsRow,
  CompanyDetailsValue,
  DataContainer,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleAndIcon,
  Country,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import CloseIcon from "../../../../Images/icons/alertClose.svg";
import CompanyDetailsIcon from "../../../../Images/icons/companyDetailsIcon.svg";
import Button from "../../../shared/Button";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useGetCountries } from "../../../../queries/auth";

interface CompanyDetailsModalProps {
  company: Company | null;
  onClose: () => void;
  open: boolean;
}

const CompanyDetailsModal: React.FC<CompanyDetailsModalProps> = ({
  company,
  onClose,
  open,
}) => {
  const { showSnackbar } = useSnackbar();
  const { data: countries, error: countriesError } = useGetCountries();
  const [country, setCountry] = React.useState<string>("");
  useEffect(() => {
    if (countriesError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description:
          "An error occurred while fetching the data. Please try again later.",
      });
    }
  }, [countriesError]);

  useEffect(() => {
    if (countries && company) {
      const country = countries.find((country) => {
        return country.id === company?.country;
      });
      setCountry(country?.name || "");
    }
  }, [countries, company]);
  const renderCompanyDetails = () => {
    if (!company) return null;
    return (
      <DataContainer>
        <CompanyDetailsRow>
          <CompanyDetailsLabel>Country</CompanyDetailsLabel>
          <Country>
            <CompanyDetailsValue>{country}</CompanyDetailsValue>
          </Country>
        </CompanyDetailsRow>
        <CompanyDetailsRow>
          <CompanyDetailsLabel>Company Type</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.corporation_type}</CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Industry</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.industry}</CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Legal Company Name</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.name}</CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Phone</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.phone}</CompanyDetailsValue>
        </CompanyDetailsRow>
        {company.established && (
          <>
            <CompanyDetailsRow>
              <CompanyDetailsLabel>Tax ID</CompanyDetailsLabel>
              <CompanyDetailsValue>{company.tax_id}</CompanyDetailsValue>
            </CompanyDetailsRow>
            <CompanyDetailsRow>
              <CompanyDetailsLabel>Registration Number</CompanyDetailsLabel>
              <CompanyDetailsValue>
                {company.registration_number}
              </CompanyDetailsValue>
            </CompanyDetailsRow>    
          </>
        )}

      </DataContainer>
    );
  };

  if (!company) return null;
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer>
        <ModalHeader>
          <ModalTitleAndIcon>
            <img
              src={CompanyDetailsIcon}
              alt="company-details-icon"
              width={48}
              height={48}
            />
            <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
              Company details
            </ModalTitle>
          </ModalTitleAndIcon>

          <img src={CloseIcon} alt="close-icon" onClick={onClose} />
        </ModalHeader>
        {renderCompanyDetails()}

        <ModalFooter>
          <Button
            text="Close"
            onClick={onClose}
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
          />
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
};

export default CompanyDetailsModal;
