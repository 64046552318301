import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import Button from "../../../shared/Button";

export const Container = styled.div`
  display: flex;
  gap: 9px;
  padding-top: ${Spaces.spacing_xl};
`;

export const PeopleDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: ${Spaces.spacing_3xl};
  padding-top: ${Spaces.spacing_2xl};
  padding-bottom: 20px;
  background-color: ${Colors.bg_primary};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  width: 48%;
  height: fit-content;
`;

export const PeopleDataHeader = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  align-items: center;
  margin-bottom: ${Spaces.spacing_md};
`;

export const PeopleDataHeaderTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PeopleNumberContainer = styled.div`
  display: flex;
  padding: 2px ${Spaces.spacing_sm};
  align-items: center;
  border-radius: ${Radiuses.radius_sm};
  background-color: ${Colors.utility_gray_50};
  border: 1px solid ${Colors.utility_gray_200};
`;

export const PeopleDataList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_sm};
`;

export const PeopleDataListItem = styled.div`
  display: flex;
  gap: ${Spaces.spacing_sm};
  padding-block: ${Spaces.spacing_md};
  padding-inline: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary_hover};
  align-items: center;
`;

export const PeopleDataListItemImg = styled.div`
  display: flex;
  padding: 5px 5px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.Brand_50};
`;

export const PeopleDataListItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PeopleDataListItemFirstRow = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  align-items: center;
`;

export const PeopleDataListItemTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const InvitedBadge = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
  align-items: center;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.utility_gray_50};
  border: 1px solid ${Colors.utility_gray_200};
  padding: 2px ${Spaces.spacing_md} 2px ${Spaces.spacing_sm};
`;
export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${Colors.utility_gray_500};
  border-radius: ${Radiuses.radius_full};
`;
export const PeopleDataListItemExtraInfo = styled(Typography)`
  color: ${Colors.gray_dark_mode_400};
`;

export const Arrow = styled.img<{ active: boolean }>`
  margin-left: auto;
  transform: ${({ active }) => (active ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: ${Spaces.spacing_3xl};
  padding-block: 13px;
  align-items: flex-start;
  background-color: ${Colors.bg_primary};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  flex: 1;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
`;

export const CloseBtn = styled.img`
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: ${Spaces.spacing_md};
`;
export const ViewProfileBtn = styled(Button)`
  margin-left: auto;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_xl};
  gap: ${Spaces.spacing_md};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary_hover};
`;

export const BannerTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const BannerDescription = styled(Typography)`
  color: ${Colors.text_tertiary};
`;

export const BannerBtn = styled(Button)`
  background-color: ${Colors.utility_error_100};
  color: ${Colors.button_tertiary_error_fg};
  width: fit-content;
`;

export const WarningBanner = styled.div`
  padding: ${Spaces.spacing_xl};
  display: flex;
  gap: ${Spaces.spacing_md};
  border-radius: ${Radiuses.radius_xl};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: ${Colors.bg_warning_primary};
  margin-block: ${Spaces.spacing_md};
  align-items: start;
`;

export const WarningBannerText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const PayDepositContainer = styled.div`
  display: flex;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary_hover};
  width: 100%;
  box-sizing: border-box;
  margin-block-end: ${Spaces.spacing_md};

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${Spaces.spacing_md};
  }
`;

export const PayDepositContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${Spaces.spacing_md};
`;
export const PayDepositTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const PayDepositDescription = styled(Typography)`
  color: ${Colors.gray_dark_mode_400};
`;

export const PayDepositBtn = styled(Button)`
  margin-left: auto;
  width: fit-content;

  @media screen and (max-width: 1150px) {
    margin-left: 0;
  }
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_xl} ${Spaces.spacing_md};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary_hover};
  width: 100%;
  box-sizing: border-box;
  margin-block-end: ${Spaces.spacing_xs};
`;

export const DataLabel = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const DataValue = styled(Typography)`
  color: ${Colors.text_secondary_hover};
`;
