import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
  justify-content: center;
  align-items: center;
  max-width: 350px;
  flex-wrap: wrap;
`;
export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  padding: ${Spaces.spacing_lg};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_brand_secondary};
  box-sizing: border-box;
`;

export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
  text-align: center;
  width: 90%;
  margin-top: ${Spaces.spacing_xl};
  white-space: pre-wrap;
`;
export const ModalDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  text-align: center;
  width: 80%;
  margin-bottom: ${Spaces.spacing_2xl};
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  width: 100%;
  justify-content: space-between;
  margin-top: ${Spaces.spacing_4xl};
`;
