import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Typography from "../../../shared/Typography";

export const ReviewStepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;

export const ReviewStepButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ConfirmButton = styled(Button)`
  width: 48%;
`;

export const AgreementLink = styled.a`
  color: ${Colors.text_quarterary_on_brand};
  text-decoration: none;
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.SEMIBOLD};
`;

export const AssetsAgreementWarning = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  padding: ${Spaces.spacing_xl};
  align-items: flex-start;
  width: 100%;
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.fg_warning_primary};
  background-color: ${Colors.bg_warning_primary};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: ${Spaces.spacing_md};
`;

export const WarningText = styled(Typography)`
  color: ${Colors.text_tertiary_hover};
`;
export const AssetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
