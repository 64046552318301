import styled from "styled-components";
import { Colors, Breakpoints, Spaces } from "../../constants/styles";
import BG from "../../Images/registerBG.png";
import Typography from "../../components/shared/Typography";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: ${Colors.bg_secondary_hover};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex: 1;
  margin-bottom: 96px;
`;

export const SidePanelContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: ${Spaces.spacing_4xl};
  width: 28.34%;
  min-width: 300px;
  max-width: 440px;
  height: 100%;
  left: 0px;
  background: linear-gradient(0deg, rgba(2, 41, 77, 0.9), rgba(2, 41, 77, 0.9)),
    url(${BG});
  background-size: cover;
  background-position: center;

  @media (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin-top: ${Spaces.spacing_4xl};
`;

export const Title = styled(Typography)`
  color: ${Colors.text_white};
  margin-block: ${Spaces.spacing_none};
`;

export const Subtext = styled(Typography)`
  color: ${Colors.text_white};
  margin-block: ${Spaces.spacing_none};
`;

