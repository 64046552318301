import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../../components/shared/Typography";
import { Table } from "../../../../components/shared/Table/styled";

export const Container = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
  background-color: ${Colors.bg_primary};
  margin-bottom: 13px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const DropDownsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  width: 35%;
  justify-content: "flex-end";
`;

export const PayrollTableContainer = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  position: relative;
`;

export const TextFaded = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
