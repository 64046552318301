import { Country } from "../../types";

export const USERS = [
  {
    id: 1,
    type: "employer_root",
    email: "admin@ex.com",
    password: "12345678",
    first_name: "John",
    last_name: "Doe",
    gender: "Male",
    verified: true,
    user_information: {
      id: 1,
      position: "CTO",
      date_of_birth: "1995-09-19",
      phone: "+201099999999",
      country_of_citizenship: "Egypt",
    },
    organization: {
      id: 1,
      name: "Ovarc",
      country: 52,
      created_at: "1995-05-05",
      size_min: 10,
      size_max: 50,
    },
    profile_picture: {
      id: 11,
      type: "Image",
      entries: [
        {
          id: 22,
          label: "large",
          url: "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
        },
      ],
    },
  },
];

export const ACCESS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";

export const COUNTRIES: Country[] = [
  {
    id: 184,
    name: "United Arab Emirates",
  },
  {
    id: 151,
    name: "Saudi Arabia",
  },
  {
    id: 52,
    name: "Egypt",
  },
];
