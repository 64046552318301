import styled from "styled-components";
import { Colors, Radiuses } from "../../../constants/styles";

export const InitialsProfilePicContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_disabled};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.text_placeholder};
`;
