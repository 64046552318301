import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import AssetStepper from "../AssetsStepper";
import AssetInfoStep from "../addAssetSteps/AssetInfoStep";
import DeliveryPreferenceStep from "../addAssetSteps/DeliveryPreferenceStep";
import FinancialInfoStep from "../addAssetSteps/FinancialInfoStep";
import ReviewStep from "../addAssetSteps/ReviewStep";

import {
  AddAssetSubtitle,
  AddAssetTitle,
  AddAssetContainer,
  AddAssetHeader,
  AddAssetContent,
  StepsContainer,
} from "./styled";

const AddAsset = () => {
  const { addAssetActiveStep: activeStep, addAssetSteps: steps } =
    useAppSelector((state) => state.assetsHub);

  const renderActiveStep = () => {
    switch (activeStep) {
      case 0:
        return <AssetInfoStep />;
      case 1:
        return <FinancialInfoStep />;
      case 2:
        return <DeliveryPreferenceStep />;
      case 3:
        return <ReviewStep />;
    }
  };

  return (
    <AddAssetContainer>
      <AddAssetHeader>
        <AddAssetTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Add Asset
        </AddAssetTitle>
        <AddAssetSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Fill in the form below to add an asset
        </AddAssetSubtitle>
      </AddAssetHeader>
      <AddAssetContent>
        <StepsContainer>{renderActiveStep()}</StepsContainer>
        <AssetStepper steps={steps} activeStep={activeStep} />
      </AddAssetContent>
    </AddAssetContainer>
  );
};

export default AddAsset;
