import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  PayrollDetailsContainer,
  PayrollDetailsHeader,
  PayrollDetailsHeaderTitleContainer,
  PayrollDetailsHeadersButtonsContainer,
  PayrollDetailsSubtitle,
  PayrollDetailsTitle,
  CardsContainer,
  CardsColumn,
  TransparentRow,
  Header,
  PayrollDetailsCardRow,
  PayslipDownloadSubtitle,
} from "./styled";
import {
  Card,
  CardHeader,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
  CardRowValuesContainer,
  CardRowTitleWithIcon,
} from "../../shared/Card";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Check from "../../../Images/icons/thin-check.svg";
import Badge from "../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../types/componentsProps";
import moment from "moment";
import Button from "../../shared/Button";
import Banknote from "../../../Images/icons/bank-note-white.svg";
import ZipIcon from "../../../Images/icons/zip.svg";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Download from "../../../Images/icons/download.svg";
import { useGetPayroll } from "../../../queries/payroll";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { PAYROLL_STATUS_BADGE_COLOR, PayrollStatus } from "../../../types/payroll";
import SubmitPayrollReportModal from "../SubmitPayrollReportModal";
import { useGetPayslips } from "../../../queries/payslip";
import { downloadFile } from "../../../utilities/file";

const PayrollDetails = () => {
  const { payroll_id } = useParams<{ payroll_id: string }>();
  const { data: payroll, error } = useGetPayroll(payroll_id);
  const [startDownload, setStartDownload] = useState(false);
  const {
    data: file,
    error: downloadError,
    isFetching,
  } = useGetPayslips({ payroll_id, enabled: startDownload });

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [date, setDate] = useState({ label: "", value: "" });
  const [openSubmitPayrollModal, setOpenSubmitPayrollModal] = useState(false);

  useEffect(() => {
    if (downloadError) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while downloading payslip.",
        description: "Please try again later.",
      });
    }
  }, [downloadError]);

  useEffect(() => {
    if (file && startDownload) {
      downloadFile(file, "Payslips", "application/zip");
    }
    setStartDownload(false);
  }, [file]);

  useEffect(() => {
    switch (payroll?.status) {
      case PayrollStatus.PENDING_REVIEW:
        setDate({
          label: "Payroll Due Date",
          value: moment(payroll.submission_due_date).format("DD MMM. YYYY"),
        });
        break;
      case PayrollStatus.PENDING_PAYMENT:
        setDate({
          label: "Payment Due Date",
          value: moment(payroll.payrollCycle.payment_due_date).format(
            "DD MMM. YYYY"
          ),
        });
        break;
      case PayrollStatus.PENDING_PROCESSING:
      case PayrollStatus.PROCESSING:
        setDate({
          label: "Payroll Processing Date",
          value: moment(payroll.payrollCycle.payroll_processing_date).format(
            "DD MMM. YYYY"
          ),
        });
        break;
      case PayrollStatus.PROCESSED:
        setDate({
          label: "Payroll Processed At",
          value: moment(payroll.processed_at).format("DD MMM. YYYY"),
        });
        break;
      default:
        break;
    }
  }, [payroll]);

  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching payroll review.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  if (!payroll_id) {
    <Navigate to="/404" />;
  }

  const renderReportDetails = () => {
    return (
      <CardsContainer>
        <CardsColumn>
          <Card style={{ marginBottom: "0rem" }}>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Employees
              </CardTitle>
            </CardHeader>

            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total Employees
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payroll?.payroll_employee_count}
              </CardRowValue>
            </CardRow>

            <TransparentRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Active
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payroll?.payroll_employee_count}
              </CardRowValue>
            </TransparentRow>
          </Card>

          <Card style={{ marginBlockStart: "0rem" }}>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Payroll Cost
              </CardTitle>
            </CardHeader>

            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total Cost
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  payroll?.total,
                  payroll?.total_currency || "USD"
                )}
              </CardRowValue>
            </CardRow>

            <TransparentRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Net Salary
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  payroll?.total_net,
                  payroll?.total_currency || "USD"
                )}
              </CardRowValue>
            </TransparentRow>
            <TransparentRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Deductions
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payroll
                  ? currencyFormatter(
                      payroll.total - payroll.total_net,
                      payroll.total_currency
                    )
                  : null}
              </CardRowValue>
            </TransparentRow>
          </Card>
        </CardsColumn>

        <CardsColumn>
          <Card>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Payroll Documents
              </CardTitle>
            </CardHeader>
            <CardRow>
              <CardRowTitleWithIcon
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                <img src={ZipIcon} alt="zip icon" width={40} height={40} />{" "}
                {/* Add the image here */}
                <div>
                  Payslips
                  {payroll?.status === PayrollStatus.PROCESSED ? null : (
                    <PayslipDownloadSubtitle
                      size={FontSizes.TX_XS}
                      weight={FontWeights.REGULAR}
                    >
                      Payslips will be available once payroll is processed.
                    </PayslipDownloadSubtitle>
                  )}
                </div>
              </CardRowTitleWithIcon>
              <Button
                text="Download"
                iconLeading={
                  <img src={Download} width={20} height={20} alt="" />
                }
                size={ButtonSizes.md}
                variant={ButtonVariants.secondary_color}
                onClick={() =>
                  payroll?.status === PayrollStatus.PROCESSED &&
                  setStartDownload(true)
                }
                loading={isFetching}
                disabled={payroll?.status !== PayrollStatus.PROCESSED}
                style={
                  payroll?.status !== PayrollStatus.PROCESSED
                    ? {
                        pointerEvents: "none",
                        opacity: 0.9,
                        cursor: "not-allowed",
                      }
                    : {}
                }
              />
            </CardRow>
          </Card>
        </CardsColumn>
      </CardsContainer>
    );
  };

  return payroll ? (
    <PayrollDetailsContainer>
      <PayrollDetailsHeader>
        <div>
          <PayrollDetailsHeaderTitleContainer>
            <PayrollDetailsTitle
              size={FontSizes.SM}
              weight={FontWeights.SEMIBOLD}
            >
              Payroll Summary
            </PayrollDetailsTitle>
            <Badge
              color={BadgeTypes.grayBlue}
              text={`${moment(payroll.payrollCycle.start_date).format(
                "DD MMM"
              )} - ${moment(payroll.payrollCycle.end_date).format(
                "DD MMM. YYYY"
              )}`}
            />
          </PayrollDetailsHeaderTitleContainer>
          <PayrollDetailsSubtitle
            size={FontSizes.TX_MD}
            weight={FontWeights.REGULAR}
          >
            {payroll.company.name}
          </PayrollDetailsSubtitle>
        </div>
        <PayrollDetailsHeadersButtonsContainer>
          <Button
            text="View Details"
            variant={ButtonVariants.primary}
            size={ButtonSizes.sm}
            onClick={() => {
              navigate(`/payroll/payroll-review/${payroll.id}`);
            }}
          />
          {payroll.status === PayrollStatus.PENDING_PAYMENT ? (
            <Button
              text="Proceed with payment"
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              iconLeading={
                <img src={Banknote} alt="ban-note" width={20} height={20} />
              }
              onClick={() => navigate("/payment")}
            />
          ) : payroll.status === PayrollStatus.PENDING_REVIEW ? (
            <Button
              text="Submit"
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              iconLeading={
                <img src={Check} alt="Close" width={20} height={20} />
              }
              onClick={() => setOpenSubmitPayrollModal(true)}
            />
          ) : null}
        </PayrollDetailsHeadersButtonsContainer>
      </PayrollDetailsHeader>
      <Header>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payroll report
          </CardTitle>
        </CardHeader>
        <CardRowValuesContainer>
          <PayrollDetailsCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {date.label}
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {date.value}
            </CardRowValue>
          </PayrollDetailsCardRow>

          <PayrollDetailsCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payroll Status
            </CardRowTitle>
            <Badge text={payroll.status} color={PAYROLL_STATUS_BADGE_COLOR[payroll.status]} dot />
          </PayrollDetailsCardRow>
        </CardRowValuesContainer>
      </Header>
      {renderReportDetails()}
      <SubmitPayrollReportModal
        open={openSubmitPayrollModal}
        onClose={() => {
          setOpenSubmitPayrollModal(false);
        }}
        payroll={payroll ? payroll : null}
      />
    </PayrollDetailsContainer>
  ) : null;
};

export default PayrollDetails;
