import { useEffect, useState } from "react";
import { ModalProps, SnackbarTypes } from "../../../types/componentsProps";
import VerticalModal from "../../shared/VerticalModal";
import {
  AssignAssetModalContentContainer,
  AssignAssetModalContentHeader,
  AssignAssetTitle,
  CloseIcon,
  EmployeeDataContainer,
  EmployeeEmail,
  EmployeeName,
  ModalFooter,
} from "./styled";
import Close from "../../../Images/icons/x-close.svg";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
  LoaderSizes,
  LoaderVariants,
} from "../../../constants/styles";
import SearchInput from "../../shared/SearchInput";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../shared/Table/styled";
import Typography from "../../shared/Typography";
import { useGetActiveEmployees } from "../../../queries/people";
import Loader from "../../shared/Loader";
import Button from "../../shared/Button";
import { Employee } from "../../../types/people";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useAppSelector } from "../../../redux/reduxHooks";

const AssignAssetModal = ({
  open,
  onClose,
  onSubmit,
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  onSubmit: (id: string) => void;
}) => {
  const activeCompany = useAppSelector(
    (state) => state.companies.activeCompany
  );
  const { showSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [assignedId, setAssignedId] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const {
    data: activeEmployees,
    error: activeEmployeesError,
    isPending,
  } = useGetActiveEmployees(activeCompany?.id);

  useEffect(() => {
    if (activeEmployees) {
      setFilteredEmployees(activeEmployees.employees);
    }
  }, [activeEmployees]);

  useEffect(() => {
    if (activeEmployeesError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description:
          "Something went wrong while fetching active employees, please try again later",
      });
    }
  }, [activeEmployeesError]);

  useEffect(() => {
    if (activeEmployees) {
      setFilteredEmployees(
        search === ""
          ? activeEmployees.employees
          : filteredEmployees.filter(
              (employee: Employee) =>
                employee.first_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                employee.last_name.toLowerCase().includes(search.toLowerCase())
            )
      );
    }
  }, [search]);

  const renderRows = () => {
    return filteredEmployees.map((employee: Employee) => (
      <TableRow key={employee.id}>
        <TableCell>
          <EmployeeDataContainer>
            <InitialsProfilePic
              fullName={`${employee.first_name} ${employee.last_name}`}
            />
            <div>
              <EmployeeName size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {employee.first_name} {employee.last_name}
              </EmployeeName>
              <EmployeeEmail
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {employee.email}
              </EmployeeEmail>
            </div>
          </EmployeeDataContainer>
        </TableCell>
        <TableCell>
          <Button
            size={ButtonSizes.sm}
            variant={
              assignedId === employee.id
                ? ButtonVariants.destructive_secondary
                : ButtonVariants.primary
            }
            text={assignedId === employee.id ? "Unassign" : "Assign"}
            disabled={assignedId !== employee.id && assignedId !== ""}
            onClick={() =>
              setAssignedId(
                assignedId === employee.id ? "" : employee.id
              )
            }
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <VerticalModal open={open} onClose={onClose}>
      <AssignAssetModalContentContainer>
        <AssignAssetModalContentHeader>
          <AssignAssetTitle
            size={FontSizes.TX_XL}
            weight={FontWeights.SEMIBOLD}
          >
            Assign Employee
          </AssignAssetTitle>
          <CloseIcon onClick={onClose} src={Close} />
        </AssignAssetModalContentHeader>
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search for employees"
          containerStyle={{ marginBottom: "1.5rem" }}
        />
        {isPending ? (
          <Loader size={LoaderSizes.lg} variant={LoaderVariants.primary} />
        ) : (
          <Table style={{ flex: 1 }}>
            <TableHeader>
              <TableCell>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Employee
                </Typography>
              </TableCell>

              <TableCell>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Action
                </Typography>
              </TableCell>
            </TableHeader>
            {renderRows()}
          </Table>
        )}
        <ModalFooter>
          <Button
            text="Cancel"
            size={ButtonSizes.md}
            variant={ButtonVariants.link_color}
            onClick={onClose}
          />
          <Button
            text="Submit"
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            onClick={() => {
              onSubmit(assignedId);
            }}
            disabled={assignedId === ""}
          />
        </ModalFooter>
      </AssignAssetModalContentContainer>
    </VerticalModal>
  );
};

export default AssignAssetModal;
