import styled from "styled-components";

export const CarouselContainer = styled.div`
  grid-area: banners;
  position: relative;
`;

export const PaginationContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  gap: 5px;
`;
export const PaginationDot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${(props) => (props.active ? "#033462" : "#B8B8B8")};
  border-radius: 50%;
  cursor: pointer;
`;
