import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const AssetDetailsHeader = styled.div`
  width: 100%;
  margin-bottom: ${Spaces.spacing_lg};
  border-bottom: 1px solid ${Colors.border_secondary};
  padding-bottom: ${Spaces.spacing_2xl};
`;

export const AssetDetailsHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const AssetDetailsHeaderButtons = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const AssetDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const AssetDetailsSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const AssetDetailsCardsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const AssetDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
  min-width: 260px;
  justify-content: start;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  flex: 1;
`;
export const AssetDetailsCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AssetDetailsCardTitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const Arrow = styled.img`
  cursor: pointer;
`;

export const AssigneeContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  margin-top: ${Spaces.spacing_md};
`;

export const StatusesContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-wrap: wrap;
  margin-top: ${Spaces.spacing_md};
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${Spaces.spacing_md};
`;
export const CardsColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LogContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spaces.spacing_lg};
  align-items: flex-start;
  width: 100%;
  min-height: 92px;
`;

export const LogHeaderContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  align-items: center;
  margin-top: ${Spaces.spacing_sm};
`;

export const LogEmployeeName = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;
export const LogTime = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const LogsConnector = styled.div<{
  isLast?: boolean;
}>`
  width: 2px;
  background-color: ${Colors.border_secondary};
  border-radius: ${Radiuses.radius_xxs};
  background-color: ${Colors.border_secondary};
  display: block;
  margin-bottom: ${Spaces.spacing_xs};
  flex: 1;

  ${(props) =>
    props.isLast &&
    `display: none;
        `}
`;

export const LogIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Spaces.spacing_xs};
  height: 100%;
`;

export const LogAction = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const LogData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LogSubject = styled.span`
  size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.MEDIUM};

  color: ${Colors.text_tertiary_600};
`;

export const LogRequestContainer = styled.div`
  margin-top: ${Spaces.spacing_lg};
  display: flex;
  width: 100%;
  padding: 10px ${Spaces.spacing_lg};
  border-radius: ${Radiuses.radius_md};
  border-top-right-radius: ${Radiuses.none};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_secondary};
  margin-bottom: ${Spaces.spacing_4xl};
  box-sizing: border-box;
`;

export const LogRequest = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const LogFileContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  margin-top: ${Spaces.spacing_lg};
`;

export const LogFileData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogFileName = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const LogFileSize = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
