import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ContactUsBannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: ${Spaces.spacing_3xl};
  padding-block: ${Spaces.spacing_2xl} ${Spaces.spacing_2xl};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.fg_brand_primary_alt};
  border: 1px solid ${Colors.border_secondary};
  justify-content: space-between;
  position: relative;

  grid-area: 4 / 2 / span 1 / span 1;

  @media screen and (max-width: 950px) {
    grid-area: contactUs;
  }
`;

export const ContactUsBannerTitle = styled(Typography)`
  color: ${Colors.text_primary_on_brand};
`;
export const ContactUDescription = styled(Typography)`
  color: ${Colors.text_primary_on_brand};
  width: 55%;
`;

export const ContactUsBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_sm};
`;

export const BannerImage = styled.img`
  position: absolute;
  bottom: 0;
  right: -8px;
`;
