import React from "react";
import { Container, Description, Logo, SidePanelContainer, Subtext, Title } from "./styled";
import { Link, useParams } from "react-router-dom";
import OvarcLogoWhite from "../../Images/logos/LogoWhite.svg";
import { Colors, FontSizes, FontWeights } from "../../constants/styles";
import EmailButton from "../../components/shared/EmailButton";
import { CopyRightText } from "../../components/shared/CopyRight";
import ResetPassword from "../../components/login/ResetPassword";

const ResetPasswordScreen: React.FC = () => {
    return (
        <Container>
            <SidePanelContainer>
                <Link to="/" style={{ width: "fit-content", height: "fit-content" }}>
                    <Logo src={OvarcLogoWhite} alt="ovarc-logo" />
                </Link>
                <Description>
                    <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
                        Reset Password
                    </Title>
                    <Subtext size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                        Forgot your password? No worries.
                    </Subtext>
                </Description>
                <CopyRightText style={{ color: Colors.text_white }} />
                <EmailButton />
            </SidePanelContainer>
            <ResetPassword />
        </Container>
    );
};

export default ResetPasswordScreen;
