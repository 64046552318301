import { ButtonSizes, ButtonVariants } from "../../../../../constants/styles";
import InputField from "../../../../shared/InputField";
import { FormContainer, NameInputs, ButtonsGroup, NextButton } from "../styled";
import ArrowLeft from "../../../../../Images/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import {
  PhoneCodes,
  RegistrationSteps,
  emailRegex,
  howDidYouHearAboutOvarcOptions,
} from "../../../../../constants/registration";
import Button from "../../../../shared/Button";
import DropdownList from "../../../../shared/DropdownList";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxHooks";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useGetCountries, useRegisterUser } from "../../../../../queries/auth";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../../../types/componentsProps";
import { storeToken } from "../../../../../redux/slices/authSlice";
import PartialDropdown from "../../../../shared/PartialDropdown";
import { createCountriesDropdownOptions } from "../../../../../redux/slices/appState";

const UserRegistrationForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { countriesOptions } = useAppSelector((state) => state.appState);

  const { data: countries, error: countriesError } = useGetCountries();
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phoneCode: PhoneCodes[0],
    phone: "",
    country_of_citizenship:
      countriesOptions.length > 0
        ? countriesOptions[0]
        : { label: "", value: "" },
    how_did_you_hear_about_us: howDidYouHearAboutOvarcOptions[0],
    password: "",
  });
  const { mutateAsync: register, isPending } = useRegisterUser({
    email: formData.email,
    password: formData.password,
    first_name: formData.first_name,
    last_name: formData.last_name,
    date_of_birth: formData.date_of_birth,
    country_of_citizenship: formData.country_of_citizenship.label,
    how_did_you_hear_about_us: formData.how_did_you_hear_about_us.value,
    phone: formData.phoneCode.value + formData.phone,
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone: "",
    country_of_citizenship: "",
    how_did_you_hear_about_us: "",
  });

  const setFormDataPoint = (
    key: string,
    value: string | boolean | DropdownListOption
  ) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (countriesOptions.length > 0) {
      setFormDataPoint(
        "country_of_citizenship",
        countriesOptions.filter((country) => country.label === "Egypt")[0]
      );
    }
  }, [countriesOptions]);

  useEffect(() => {
    if (countries) {
      dispatch(createCountriesDropdownOptions(countries));
    }
  }, [countries]);
  const validateForm = () => {
    let isValid = true;
    const errorObj = {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      phone: "",
      country_of_citizenship: "",
      how_did_you_hear_about_us: "",
    };

    if (!formData.email) {
      errorObj.email = "Email is required";
      isValid = false;
    }

    if (!formData.password) {
      errorObj.password = "Password is required";
      isValid = false;
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      errorObj.email = "Invalid email";
      isValid = false;
    }

    if (formData.password && formData.password.length < 8) {
      errorObj.password = "Password must be at least 8 characters";
      isValid = false;
    }
    if (!formData.first_name) {
      errorObj.first_name = "First name is required";
      isValid = false;
    }
    if (!formData.last_name) {
      errorObj.last_name = "Last name is required";
      isValid = false;
    }
    if (!formData.date_of_birth) {
      errorObj.date_of_birth = "Date of birth is required";
      isValid = false;
    }
    if (!formData.phone) {
      errorObj.phone = "Phone number is required";
      isValid = false;
    }
    if (!formData.country_of_citizenship) {
      errorObj.country_of_citizenship = "Country of citizenship is required";
      isValid = false;
    }
    if (formData.phone && formData.phone.length < 10) {
      errorObj.phone = "Invalid phone number";
      isValid = false;
    }

    setError(errorObj);
    return isValid;
  };

  const handleRegister = async () => {
    if (validateForm()) {
      try {
        const data = await register();
        dispatch(storeToken(data.access_token));
        navigate(`/register/${RegistrationSteps.email_confirmation}`);
      } catch (e: any) {
        if (e.response.data.errors) {
          const l = e.response.data.errors.map((error: string) => {
            const key = error.split("]:")[0].split("[")[1];
            const value = error.split("]:")[1];
            setError((prev) => ({
              ...prev,
              [key]: key.split("_").join(" ") + " " + value.replace(".", ".\n"),
            }));
          });
        } else {
          showSnackbar({
            open: true,
            title: "Registration error",
            description: e.response.data.message,
            type: SnackbarTypes.ERROR,
          });
        }
      }
    }
  };

  return (
    <FormContainer>
      <NameInputs>
        <InputField
          label="First Name"
          containerStyle={{ flex: 1 }}
          value={formData.first_name}
          onChange={(e) => {
            setFormDataPoint("first_name", e.target.value);
          }}
          error={error.first_name}
          onFocus={() => {
            setError({
              ...error,
              first_name: "",
            });
          }}
        />
        <InputField
          label="Last Name"
          containerStyle={{ flex: 1 }}
          onChange={(e) => {
            setFormDataPoint("last_name", e.target.value);
          }}
          error={error.last_name}
          onFocus={() => {
            setError({
              ...error,
              last_name: "",
            });
          }}
        />
      </NameInputs>

      <InputField
        label="Email"
        value={formData.email}
        onChange={(e) => {
          setFormDataPoint("email", e.target.value);
        }}
        error={error.email}
        onFocus={() => {
          setError({
            ...error,
            email: "",
          });
        }}
      />
      <InputField
        label="Password"
        type="password"
        value={formData.password}
        onChange={(e) => {
          setFormDataPoint("password", e.target.value);
        }}
        error={error.password}
        onFocus={() => {
          setError({
            ...error,
            password: "",
          });
        }}
        hint="Must be at least 8 characters long, and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
        extraMessageStyle={{ whiteSpace: "pre-line", width: "450px" }}
        containerStyle={{ width: "100%" }}
      />
      <InputField
        label="Date of birth"
        hint="Date of birth is required for regulatory and verification reasons."
        type="date"
        placeholder="11-10-1990"
        value={formData.date_of_birth}
        onChange={(e) => {
          setFormDataPoint("date_of_birth", e.target.value);
        }}
        error={error.date_of_birth}
        onFocus={() => {
          setError({
            ...error,
            date_of_birth: "",
          });
        }}
      />

      <DropdownList
        label="Nationality"
        options={countriesOptions}
        selectedOption={formData.country_of_citizenship}
        setSelectedOption={(option: DropdownListOption) => {
          setFormDataPoint("country_of_citizenship", option);
        }}
        error={error.country_of_citizenship}
        onFocus={() => {
          setError({
            ...error,
            country_of_citizenship: "",
          });
        }}
      />

      <PartialDropdown
        label="Phone number"
        value={formData.phone}
        error={error.phone}
        onFocus={() => {
          setError({
            ...error,
            phone: "",
          });
        }}
        onChange={(e) => {
          setFormDataPoint("phone", e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "e" || e.key === ".") {
            e.preventDefault();
          }
        }}
        options={PhoneCodes}
        setSelectedOption={(option: DropdownListOption) => {
          setFormDataPoint("phoneCode", option);
        }}
        selectedOption={formData.phoneCode}
        type="number"
      />
      <DropdownList
        label="How did you hear about Ovarc"
        options={howDidYouHearAboutOvarcOptions}
        selectedOption={formData.how_did_you_hear_about_us}
        setSelectedOption={(option: DropdownListOption) => {
          setFormDataPoint("how_did_you_hear_about_us", option);
        }}
        error={error.how_did_you_hear_about_us}
        onFocus={() => {
          setError({
            ...error,
            how_did_you_hear_about_us: "",
          });
        }}
      />

      <ButtonsGroup>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowLeft} alt={"arrow-left"} />}
          onClick={() =>
            navigate(`/register/${RegistrationSteps.account_type}`)
          }
        />

        <NextButton
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => handleRegister()}
          loading={isPending}
        />
      </ButtonsGroup>
    </FormContainer>
  );
};

export default UserRegistrationForm;
