import { FontSizes, FontWeights } from "../../../constants/styles";
import { CopyRightTxt } from "./styled";

export const CopyRightText = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
  const currentYear = new Date().getFullYear();
  return (
    <CopyRightTxt size={FontSizes.TX_SM} weight={FontWeights.REGULAR} {...props}>
      © OVARC {currentYear}
    </CopyRightTxt>
  );
};
