import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import BG from "../../../Images/icons/BackgroundDatternDecorative.svg";
import Button from "../../shared/Button";

export const PeopleEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  text-align: center;
`;

export const CenterImg = styled.img`
  margin-top: 188px;

  margin-bottom: ${Spaces.spacing_2xl};
`;

export const PeopleEmptyStateTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_md};
`;

export const PeopleEmptyStateDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  width: 30%;
`;

export const PeopleEmptyStateBtn = styled(Button)`
  margin-top: ${Spaces.spacing_4xl};
`;
