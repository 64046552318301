import styled from "styled-components";
import { Colors, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-inline: ${Spaces.spacing_none};
`;

export const ModalHeader = styled.div`
  padding: ${Spaces.spacing_3xl};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.border_secondary};
`;
export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ModalSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const ModalTitleAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_xl};
`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${Spaces.spacing_2xl};
  padding-bottom: ${Spaces.spacing_4xl};
  padding-inline: ${Spaces.spacing_3xl};
  gap: ${Spaces.spacing_xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_3xl};
  justify-content: end;
`;

export const CompanyDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${Spaces.spacing_4xl};
  align-items: center;
  white-space: nowrap;
`;

export const CompanyDetailsLabel = styled(Typography)`
  color: ${Colors.text_secondary_700};
  width: 45%;
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: ${Spaces.spacing_4xl};
  width: 100%;
  justify-content: flex-start;
`;
