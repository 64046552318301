import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import {
  BannerText,
  ButtonsContainer,
  IconContainer,
  OptionContainer,
  Subtext,
  TextContainer,
  Title,
  BannerContainer,
} from "./styled";
import { Card, CardTitle } from "../../../shared/Card";

import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import { useAppDispatch } from "../../../../redux/reduxHooks";
import Bank from "../../../../Images/icons/bank.svg";
import {
  onNextPayrollStep,
  onPreviousPayrollStep,
} from "../../../../redux/slices/payrollSlice";
import { TitleContainer } from "../PaymentMethod/styled";
import Checkbox from "../../../shared/Checkbox";
import Info from "../../../../Images/icons/alertCircleGray.svg";
const ManualTransfer = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Select a manual transfer method
        </CardTitle>
        <BannerContainer>
          <img src={Info} alt="info" width={24} height={24} />
          <BannerText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Manual transfers are done outside the Ovarc platform through your
            local bank account.
          </BannerText>
        </BannerContainer>
        <OptionContainer>
          <IconContainer>
            <img src={Bank} alt="bank" />
          </IconContainer>
          <TextContainer>
            <TitleContainer>
              <Title size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Bank Transfer
              </Title>
            </TitleContainer>

            <Subtext size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Send money from your bank
            </Subtext>
          </TextContainer>

          <Checkbox
            checked={true}
            onChange={() => {}}
            label={""}
            subtext={""}
            containerStyle={{ marginLeft: "auto" }}
          />
        </OptionContainer>
      </Card>
      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            dispatch(onPreviousPayrollStep());
          }}
        />
        <Button
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            dispatch(onNextPayrollStep());
          }}
        />
      </ButtonsContainer>
    </>
  );
};

export default ManualTransfer;
