import { useEffect } from "react";
import { FontSizes, FontWeights } from "../../../constants/styles";
import NewEmployeeContractStepper from "./NewEmployeeContractStepper";
import {
  PageContainer,
  PageContent,
  PageHeader,
  PageSubtitle,
  PageTitle,
} from "./styled";
import JobDescriptionForm from "./JobDescriptionForm";
import CompensationForm from "./CompensationForm";
import Extras from "./Extras";
import ContractSummary from "./ContractSummary";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmployee } from "../../../queries/people";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import {
  setCreateNewEmployeeContractSteps,
  setCurrentStep,
} from "../../../redux/slices/peopleSlice";

const NewEmployeeContract = () => {
  const { id } = useParams();
  const { data: employee, refetch } = useGetEmployee(id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentStep, newContractSteps: steps } = useAppSelector(
    (state) => state.people
  );

  const setSteps = (steps: any) => {
    dispatch(setCreateNewEmployeeContractSteps(steps));
  };

  useEffect(() => {
    dispatch(setCurrentStep(0));
  }, []);

  useEffect(() => {
    if (employee?.contract) {
      navigate(`/people/profile/${id}/contract`);
    }
  }, [employee]);

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);

  const onNext = () => {
    let newSteps = JSON.parse(JSON.stringify(steps));

    newSteps[currentStep].completed = true;

    setSteps(newSteps);
    dispatch(setCurrentStep(currentStep + 1));
  };

  const onBack = () => {
    let newSteps = JSON.parse(JSON.stringify(steps));
    newSteps[currentStep - 1].completed = false;
    setSteps(newSteps);

    dispatch(setCurrentStep(currentStep - 1));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <JobDescriptionForm onNext={onNext} />;
      case 1:
        return <CompensationForm onNext={onNext} onBack={onBack} />;
      // case 2:
      //   return <Extras onNext={onNext} onBack={onBack} />;
      case 2:
        return <ContractSummary onBack={onBack} />;
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Create an employee contract
        </PageTitle>

        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Craft customized employee contracts effortlessly with our intuitive
          platform.
        </PageSubtitle>
      </PageHeader>
      <PageContent>
        {renderStep()}
        <NewEmployeeContractStepper currentStep={currentStep} steps={steps} />
      </PageContent>
    </PageContainer>
  );
};

export default NewEmployeeContract;
