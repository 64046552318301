import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const PayslipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_primary};
  border: 1px solid ${Colors.border_secondary};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  margin-top: 22px;
`;

export const PayslipsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spaces.spacing_2xl} ${Spaces.spacing_3xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;
export const PayslipsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PayslipPeriod = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PayslipsText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
