import styled from "styled-components";
import Typography from "../../shared/Typography";
import { Colors, Spaces } from "../../../constants/styles";

export const PaymentDetailsHeader = styled.div`
  width: 100%;
`;

export const PaymentDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const PaymentDetailsSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
