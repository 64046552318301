import { useEffect, useState } from "react";
import { PaymentContainer, PaymentHeader, PaymentTitle } from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { PayrollSubtitle } from "../../../screens/Payroll/styled";
import { useLocation, Outlet } from "react-router-dom";
import PaymentsListAdmin from "../../components/payment/PaymentsList";

const PaymentScreenAdmin = () => {
  const [isOutlet, setIsOutlet] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOutlet(
      location.pathname.includes("payment-details") ||
      location.pathname.includes("invoice-details")
    );
  }, [location]);


  return isOutlet ? <Outlet /> : (
    <PaymentContainer>
      <PaymentHeader>
        <PaymentTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Payment
        </PaymentTitle>
        <PayrollSubtitle
          size={FontSizes.TX_MD}
          weight={FontWeights.REGULAR}
        >
          View and manage payments here{" "}
        </PayrollSubtitle>
      </PaymentHeader>
      <PaymentsListAdmin />
    </PaymentContainer>
  );
};

export default PaymentScreenAdmin;