import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import RequestAssetStepper from "../AssetsStepper";
import EquipmentStep from "../requestAssetsSteps/EquipmentStep";
import ReviewStep from "../requestAssetsSteps/ReviewStep";
import {
  RequestAssetSubtitle,
  RequestAssetTitle,
  RequestAssetContainer,
  RequestAssetHeader,
  RequestAssetContent,
  StepsContainer,
} from "./styled";

const RequestAsset = () => {
  const { requestAssetActiveStep: activeStep, requestAssetsSteps: steps } =
    useAppSelector((state) => state.assetsHub);

  const renderActiveStep = () => {
    switch (activeStep) {
      case 0:
        return <EquipmentStep />;
      case 1:
        return <ReviewStep />;
    }
  };

  return (
    <RequestAssetContainer>
      <RequestAssetHeader>
        <RequestAssetTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Request Asset
        </RequestAssetTitle>
        <RequestAssetSubtitle
          size={FontSizes.TX_MD}
          weight={FontWeights.REGULAR}
        >
          Fill in the form below to request an asset
        </RequestAssetSubtitle>
      </RequestAssetHeader>
      <RequestAssetContent>
        <StepsContainer>{renderActiveStep()}</StepsContainer>
        <RequestAssetStepper steps={steps} activeStep={activeStep} />
      </RequestAssetContent>
    </RequestAssetContainer>
  );
};

export default RequestAsset;
