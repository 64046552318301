import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../Typography";

export const ActionsButton = styled.img`
  cursor: pointer;
  margin-inline-start: 70%;
`;

export const ActionsDropdown = styled.div<{ show: boolean }>`
  position: absolute;
  right: 0;
  background-color: ${Colors.bg_primary};
  border: 1px solid ${Colors.border_secondary};
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: ${Radiuses.radius_md};
  padding: ${Spaces.spacing_sm};
  z-index: 1;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  padding: ${Spaces.spacing_xs};
  min-width: 190px;
`;

export const ActionItem = styled.div`
  cursor: pointer;
  padding: 10px;
`;

export const ActionText = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ActionItemLink = styled.a`
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
`;
