import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Badge from "../../shared/Badge";
import { BadgeTypes } from "../../../types/componentsProps";
import Placeholder from "../../../Images/placeholderPP.svg";
import {
  ProfileContainer,
  ProfileHeader,
  ProfileHeaderInfo,
  ProfilePP,
  ProfileName,
  ProfilePersonalInfo,
  BadgesContainer,
} from "./styled";
import { FontSizes, FontWeights, LoaderSizes } from "../../../constants/styles";
import { useEffect, useState } from "react";
import Tabs from "../../shared/Tabs";
import { useGetEmployee } from "../../../queries/people";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { setActiveEmployeeName } from "../../../redux/slices/peopleSlice";
import { PEOPLE_STATUS, PEOPLE_STATUS_BADGE_COLOR } from "../../../types/people";
import Loader from "../../shared/Loader";
import { PROFILE_TABS } from "../../../constants/people";
import { contractExpiringDetails } from "../../../utilities/contractUtils";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [expiring, setExpiring] = useState<string | undefined>(undefined)
  const [isOutlet, setIsOutlet] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const { data: employee, error, isPending } = useGetEmployee(id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  window.onpopstate = () => {
    if (activeTab === 0) navigate("/people");
  };

  useEffect(() => {
    if (employee) {
      dispatch(
        setActiveEmployeeName(
          `${employee.user.first_name} ${employee.user.last_name}`
        )
      );
    }
    if(employee?.contract)
    {
      setExpiring(contractExpiringDetails({status: employee.contract.status, employment_term: employee.contract.employment_term, end_date: employee.contract.end_date}))
    }
  }, [employee]);

  useEffect(() => {
    if (error) {
      navigate("/404");
    }
  }, [error]);

  useEffect(() => {
    const lastPath = location.pathname.split("/").pop();
    if (
      lastPath === "employee-contract" ||
      location.pathname.includes("request-details") ||
      location.pathname.includes("payslip-details")
    ) {
      setIsOutlet(true);
    } else {
      setIsOutlet(false);
      const selectedTab =
        PROFILE_TABS.findIndex((tab) => lastPath === tab.path) || 0;
      if (selectedTab !== -1) {
        setActiveTab(selectedTab);
      } else {
        navigate(`/people/profile/${id}/overview`);
      }
    }
  }, [location.pathname]);

  return isOutlet ? (
    <Outlet />
  ) : isPending ? (
    <Loader size={LoaderSizes.lg} />
  ) : !employee ? null : (
    <ProfileContainer>
      <ProfileHeader>
        <ProfilePersonalInfo>
          <ProfilePP>
            <img
              src={Placeholder}
              alt="Profile Picture"
              width={30}
              height={30}
            />
          </ProfilePP>
          <ProfileHeaderInfo>
            <ProfileName size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
              {employee?.user.first_name + " " + employee?.user.last_name}
            </ProfileName>
            <BadgesContainer>
              {employee?.contract?.status === (null || undefined) ? 
                (<Badge text={PEOPLE_STATUS.not_contracted} color={PEOPLE_STATUS_BADGE_COLOR[PEOPLE_STATUS.not_contracted]} />)
                :
                (<Badge text={employee.contract.status} color={PEOPLE_STATUS_BADGE_COLOR[employee.contract.status]} />)
              }
              {expiring ? (
                <Badge
                  text={`Contract expiring in ${expiring}`}
                  color={BadgeTypes.warning}
                />
              ) : null}
            </BadgesContainer>
          </ProfileHeaderInfo>
        </ProfilePersonalInfo>
        <Tabs
          activeTab={activeTab}
          setActiveTab={(index) => {
            navigate(`/people/profile/${id}/${PROFILE_TABS[index].path}`);
          }}
          tabsTitles={PROFILE_TABS.map((tab) => tab.label)}
        />
      </ProfileHeader>
      <Outlet />
    </ProfileContainer>
  );
};

export default Profile;
