import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { updatePayrollListVariables } from "../../../redux/slices/payrollSlice";
import { SnackbarTypes } from "../../../types/componentsProps";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { useGetAdminPayrolls } from "../../queries/payroll";
import {
  PayrollContainer,
  PayrollHeader,
  PayrollSubtitle,
  PayrollTitle,
} from "./styled";
import PayrollTable from "../../components/payroll/PayrollTable";
import { PAYROLL_STATUS_OPTIONS } from "../../../constants/dropDownLists";

const PayrollScreenAdmin: React.FC = () => {

  return (
    <PayrollContainer>
      <>
        <PayrollHeader>
          <PayrollTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
            Payroll
          </PayrollTitle>
          <PayrollSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
            List of payrolls that are ready to be paid
          </PayrollSubtitle>
        </PayrollHeader>
        <PayrollTable />
      </>
    </PayrollContainer>
  );
};

export default PayrollScreenAdmin;
