import React from "react";
import {
  ActionCardContainer,
  ActionCardDescription,
  ActionCardTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";

interface ActionCardProps {
  title: string;
  description: string;
  btnText: string;
  onClick: () => void;
}

const EmptyActionCard: React.FC<ActionCardProps> = ({
  title,
  description,
  onClick,
  btnText,
}) => {
  return (
    <ActionCardContainer onClick={onClick}>
      <ActionCardTitle size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
        {title}
      </ActionCardTitle>
      <ActionCardDescription
        size={FontSizes.TX_SM}
        weight={FontWeights.REGULAR}
      >
        {description}
      </ActionCardDescription>
      <Button
        text={btnText}
        onClick={onClick}
        size={ButtonSizes.sm}
        variant={ButtonVariants.primary}
        style={{ width: "fit-content" }}
      />
    </ActionCardContainer>
  );
};

export default EmptyActionCard;
