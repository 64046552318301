import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { useAppDispatch } from "../../../../redux/reduxHooks";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Button from "../../../shared/Button";
import { ButtonsContainer, Container } from "./styled";
import {
  Card,
  CardHeader,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
  CardTransparentRow,
} from "../../../shared/Card";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import { onNextPayrollStep } from "../../../../redux/slices/payrollSlice";
import { useNavigate } from "react-router-dom";
import { useGetPayrollProcess } from "../../../../queries/payroll";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useEffect } from "react";
import { SnackbarTypes } from "../../../../types/componentsProps";

const ReviewPayment = () => {
  const { data, error } = useGetPayrollProcess();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while processing your payroll.",
        description: "Please try again later.",
      });
      navigate("/payroll");
    }
  }, [error]);

  return data ? (
    <Container>
      <Card>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Cost
          </CardTitle>
        </CardHeader>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Total
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {currencyFormatter(data?.total_cost, data?.currency)}
          </CardRowValue>
        </CardRow>

        {data?.companies.map((company, index) => (
          <CardTransparentRow key={index}>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {company.name}
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {currencyFormatter(company.cost, data?.currency)}
            </CardRowValue>
          </CardTransparentRow>
        ))}
      </Card>

      <Card>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Employees
          </CardTitle>
        </CardHeader>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Total
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {data?.total_employees} employees
          </CardRowValue>
        </CardRow>

        {data?.companies.map((company, index) => (
          <CardTransparentRow key={index}>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {company.name}
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {company.employees} employees
            </CardRowValue>
          </CardTransparentRow>
        ))}
      </Card>
      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Button
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            dispatch(onNextPayrollStep());
          }}
        />
      </ButtonsContainer>
    </Container>
  ) : null;
};

export default ReviewPayment;
