import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
  Spaces,
} from "../../../../constants/styles";
import {
  Container,
  Title,
  SubtitleContainer,
  SubTitle,
  ResendEmailContainer,
  InfoIconContainer,
  ResendEmailTitle,
  ResendEmailSubtitle,
  ResendEmailButton,
} from "./styled";
import Info from "../../../../Images/icons/info-circle.svg";
import LogoutButton from "../../LogoutButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useResendVerificationEmail,
  useVerifyEmail,
} from "../../../../queries/auth";
import { useEffect, useRef, useState } from "react";
import {
  RegistrationSteps,
  emailRegex,
} from "../../../../constants/registration";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import InputField from "../../../shared/InputField";

const EmailConfirmation = () => {
  const [searchParams] = useSearchParams();
  const {
    mutateAsync: verifyEmail,
    isPending,
    isError,
  } = useVerifyEmail(searchParams.get("token") || "");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [resendUI, setResendUI] = useState(false);
  const hasVerified = useRef(false);
  const {
    mutateAsync: resendEmail,
    isPending: isResendPending,
    isError: isResendError,
  } = useResendVerificationEmail(email);
  
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handelVerifyEmail = async () => {
    try {
      await verifyEmail();
      hasVerified.current = true;
      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Email verified successfully",
        description: "",
      });
      navigate(`/register/${RegistrationSteps.organization_details}`);
      return;
    } catch (error) {}
  };

  const handleResendEmail = async () => {
    setResendUI(true);
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }
    try {
      await resendEmail();
      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Email is resent successfully",
        description: "",
      });
      setResendUI(false);
    } catch (error) {
      setError("Error resending email");
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (token && hasVerified.current !== true) {
      handelVerifyEmail();
    }
  }, [searchParams]);

  useEffect(() => {
    return function() {
      setEmail("");
      setResendUI(false);
      setError("");
    }
  }, [])

  return (
    <Container>
      <LogoutButton style={{ marginBottom: Spaces.spacing_6xl }} />
      <div>
        {isPending || isResendPending ? (
          <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            {isPending ? " verifying email..." : "Resending email..."}
          </Title>
        ) : isError || isResendError ? (
          <>
            <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
              {isError ? "Error verifying email" : "Error resending email"}
            </Title>
            <InputField
              label="Enter your email address to resend verification email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=""
              containerStyle={{ marginBlock: Spaces.spacing_2xl }}
              error={error}
              onFocus={() => setError("")}
            />
            <ResendEmailButton
              variant={ButtonVariants.primary}
              size={ButtonSizes.lg}
              text="Resend Email"
              onClick={() => {
                handleResendEmail();
              }}
              disabled={email === ""}
            />
          </>
        ) : resendUI ? (
          <>
            {" "}
            <InputField
              label="Enter your email address to resend verification email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=""
              containerStyle={{ marginBlock: Spaces.spacing_2xl }}
              error={error}
              onFocus={() => setError("")}
            />
            <ResendEmailButton
              variant={ButtonVariants.primary}
              size={ButtonSizes.lg}
              text="Resend Email"
              onClick={() => {
                handleResendEmail();
              }}
              disabled={email === ""}
            />
          </>
        ) : (
          <>
            <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
              Check your inbox and confirm your{"\n"} email address
            </Title>
            <SubtitleContainer>
              <SubTitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                We sent you a verification email to
              </SubTitle>
              <SubTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {email}
              </SubTitle>
            </SubtitleContainer>

            <ResendEmailContainer>
              <InfoIconContainer>
                <img src={Info} alt="info-icon" width={24} height={24} />
              </InfoIconContainer>

              <ResendEmailTitle
                size={FontSizes.TX_LG}
                weight={FontWeights.SEMIBOLD}
              >
                Didn’t receive an email?
              </ResendEmailTitle>
              <ResendEmailSubtitle
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                If you can’t find the email in your inbox or spam {"\n"}folder,
                please below to send you a new email.
              </ResendEmailSubtitle>

              <ResendEmailButton
                variant={ButtonVariants.primary}
                size={ButtonSizes.lg}
                text="Resend Email"
                onClick={() => {
                  setEmail("");
                  setResendUI(true);
                }}
              />
            </ResendEmailContainer>
          </>
        )}
      </div>
    </Container>
  );
};

export default EmailConfirmation;
