import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const PayrollEmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 25px;
  border-radius: ${Spaces.spacing_md};
  background-color: ${Colors.Brand_500};
  padding: 40px 25px;
  box-sizing: border-box;
  white-space: pre-line;
  justify-content: space-between;
`;

export const PayrollEmptyStateTitle = styled(Typography)`
  color: #fff;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const PayrollEmptyStateDescription = styled(Typography)`
  color: #fff;
`;
