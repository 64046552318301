import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  border-bottom: 1px solid ${Colors.border_secondary};
  color: ${Colors.text_tertiary_600};
  background-color: ${Colors.bg_secondary};
  border-bottom: 1px solid ${Colors.border_secondary};
  border-left: none;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${Colors.border_secondary};
  height: 72px;
`;

export const TableRowDynamic = styled(TableRow)<{ height: string }>`
  height: ${(props) => props.height};
`;
export const TableCell = styled.td`
  padding-inline: ${Spaces.spacing_3xl};
  padding-block: ${Spaces.spacing_lg};
  background-color: transparent;
`;

export const TableHeadWithIcon = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
  cursor: pointer;
  align-items: center;
`;
