import { CORPORATION_TYPE, CURRENCIES } from "../../constants/dropDownLists";
import { Company, COMPANY_STATUS } from "../../types/companies";

export const USER_COMPANIES = [
  {
    value: 0,
    label: "InterLock - USA",
    iconLeading:
      "https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
  },

  {
    value: 1,
    label: "InterLock - UAE",
    iconLeading:
      "https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
  },
];

export const USER_COMPANY: Company = {
  id: 0,
  billing_name: "Maadi",
  name: "Ovarc",
  industry: "Technology",
  phone: "+201234567890",
  legal_company_name: "Ovarc",
  tax_id: "123456789",
  registration_number: "123456789",
  country: 3,
  org_id: 1,
  plan: 1,
  corporation_type: CORPORATION_TYPE[0].value,
  established: true,
  invoice_currency: CURRENCIES[0].value,
  company_status: COMPANY_STATUS.VERIFIED
};

export const PLANS = [
  {
    id: 1,
    name: "General",
    type: "Public",
    currency: "USD",
    description: null,
    fees_type: "Amount",
    pricing_rate: "Flat",
    active: true,
    fee_per_employee: 250,
  },
];

export const UPCOMING_ACTIONS_DASHBOARD = {
  Invoices: 7,
  Payrolls: 3,
  Contracts: 5
}