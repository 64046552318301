import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const AssetsOverViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spaces.spacing_xl};
`;

export const AssetsOverViewCardsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-wrap: wrap;
  width: 100%;
`;

export const AssetsOverViewCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
  gap: ${Spaces.spacing_md};
  min-width: 260px;
  justify-content: start;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  flex: 1;
`;

export const AssetsOverViewCardTitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const AssetsOverViewCardValue = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PieChartContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spaces.spacing_3xl};
  align-items: center;
  flex-wrap: wrap;
`;

export const PeiChartKeysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xs};
  justify-content: center;
`;

export const PeiChartKey = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  align-items: center;
`;

export const PeiChartKeyColor = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${(props) => props.color};
`;
