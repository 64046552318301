import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { TableRowDynamic } from "../../shared/Table/styled";

export const InvoicesDueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 14px;
`;
export const InvoicesDueHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-block: ${Spaces.spacing_md};
  padding-inline: ${Spaces.spacing_xl} ${Spaces.spacing_md};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  box-sizing: border-box;
  margin-bottom: 15px;
`;

export const InvoicesDueSelectedCounter = styled(Typography)`
  color: #000;
`;

export const InvoicesDueTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${Radiuses.radius_xl};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border: 1px solid ${Colors.border_secondary};
`;

export const TableCustomHeader = styled(TableRowDynamic)`
  background-color: ${Colors.bg_disabled_subtle};
`;

export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const TableSecondaryHeader = styled(TableRowDynamic)`
  background-color: ${Colors.bg_tertiary};
`;
