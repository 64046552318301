import { useEffect, useState } from "react";
import AssetsHubEmptyState from "../../components/assetsHub/AssetsHubEmptyState";
import Tabs from "../../components/shared/Tabs";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../constants/styles";
import {
  AssetsHubContainer,
  AssetsHubHeader,
  AssetsHubHeaderButtons,
  AssetsHubHeaderRow,
  AssetsHubSubtitle,
  AssetsHubTitle,
} from "./styled";
import Button from "../../components/shared/Button";
import RequestIcon from "../../Images/icons/shopping-bag-02.svg";
import Plus from "../../Images/icons/plus.svg";
import AssetsList from "../../components/assetsHub/AssetsList";
import AssetsOverview from "../../components/assetsHub/AssetsOverview";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const AssetsHubScreen = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOutlet, setIsOutlet] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOutlet(
      location.pathname.includes("asset-details") ||
        location.pathname.includes("request-asset") ||
        location.pathname.includes("add-asset")
    );
  }, [location]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <AssetsOverview />;
      case 1:
        return <AssetsList />;
      default:
        return null;
    }
  };
  return (
    <AssetsHubContainer>
      {isOutlet ? (
        <Outlet />
      ) : (
        <>
          <AssetsHubHeader>
            <AssetsHubHeaderRow>
              <div>
                <AssetsHubTitle
                  size={FontSizes.SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  Assets Hub
                </AssetsHubTitle>
                <AssetsHubSubtitle
                  size={FontSizes.TX_MD}
                  weight={FontWeights.REGULAR}
                >
                  View and manage your assets.
                </AssetsHubSubtitle>
              </div>
              <AssetsHubHeaderButtons>
                <Button
                  variant={ButtonVariants.secondary_gray}
                  text="Request Asset"
                  iconLeading={<img src={RequestIcon} alt="Request Icon" />}
                  size={ButtonSizes.md}
                  onClick={() => navigate("request-asset")}
                />
                <Button
                  variant={ButtonVariants.primary}
                  text="Add Asset"
                  iconLeading={<img src={Plus} alt="plus" />}
                  size={ButtonSizes.md}
                  onClick={() => navigate("add-asset")}
                />
              </AssetsHubHeaderButtons>
            </AssetsHubHeaderRow>
            <Tabs
              tabsTitles={["Overview", "Assets List"]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </AssetsHubHeader>
          {renderTabContent()}
        </>
      )}
    </AssetsHubContainer>
  );
};

export default AssetsHubScreen;
