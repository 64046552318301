import { useEffect, useState } from "react";
import {
  ButtonsContainer,
  FormContainer,
  FormSubtitle,
  FormTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import DropdownList from "../../../shared/DropdownList";
import { FormFields } from "../../NewEmployee/styled";
import Button from "../../../shared/Button";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import InputField from "../../../shared/InputField";
import PartialDropdown from "../../../shared/PartialDropdown";
import { DropdownListOption } from "../../../../types/componentsProps";
import { useAppDispatch } from "../../../../redux/reduxHooks";
import { updateNewContractData } from "../../../../redux/slices/peopleSlice";
import { useGetEmployee } from "../../../../queries/people";
import { useParams } from "react-router-dom";
import {
  EMPLOYMENT_TERMS,
  EMPLOYMENT_TYPES,
  CURRENCIES,
} from "../../../../constants/dropDownLists";
const CompensationForm = ({
  onNext,
  onBack,
}: {
  onNext: () => void;
  onBack: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    employment_type: EMPLOYMENT_TYPES[0],
    hours_per_week: 0,
    net_salary: 0.0,
    currency: CURRENCIES[0],
    start_date: "",
    employment_term: EMPLOYMENT_TERMS[0],
  });
  const [errors, setErrors] = useState({
    hours_per_week: "",
    net_salary: "",
    start_date: "",
  });

  const setDataPoint = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    if (formData.hours_per_week === 0) {
      newErrors.hours_per_week = "Work hours per week is required";
      isValid = false;
    }
    if (formData.hours_per_week > 40) {
      newErrors.hours_per_week = "Invalid value";
      isValid = false;
    }
    if (formData.start_date === "") {
      newErrors.start_date = "Start date is required";
      isValid = false;
    }
    if (formData.net_salary == 0) {
      newErrors.net_salary = "Net salary is required";
      isValid = false;
    }    
    if (formData.net_salary < 6000 && formData.currency.label == 'EGP') {
      newErrors.net_salary = "Min net salary is 6000 EGP";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      dispatch(
        updateNewContractData({
          ...formData,
          currency: formData.currency.label,
          employment_type: formData.employment_type.label,
          employment_term: formData.employment_term.value,
        })
      );
      onNext();
    }
  };
  const { id } = useParams();

  const { data: employee } = useGetEmployee(id);

  useEffect(() => {
    if (employee) {
      if (
        employee.nationality === "Egypt" &&
        employee.work_from_country_id === 52
      ) {
        setDataPoint(
          "currency",
          CURRENCIES.filter((currency) => currency.label === "EGP")[0]
        );
      }
    }
  }, [employee]);

  return (
    <FormContainer>
      <FormTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
        Compensation
      </FormTitle>
      <FormSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
        Specify the agreed salary and any additional financial benefits.
      </FormSubtitle>
      <FormFields>
        <DropdownList
          label="Employment Type*"
          options={EMPLOYMENT_TYPES}
          selectedOption={formData.employment_type}
          setSelectedOption={(option) =>
            setDataPoint("employment_type", option)
          }
        />
        <InputField
          label="Work Hours Per Week*"
          onChange={(e) => {
            setDataPoint("hours_per_week", e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === ".") {
              e.preventDefault();
            }
          }}
          onFocus={() => {
            setErrors({ ...errors, hours_per_week: "" });
          }}
          error={errors.hours_per_week}
          type="number"
          value={formData.hours_per_week == 0 ? '' : formData.hours_per_week}
        />

        <PartialDropdown
          label="Net Salary*"
          value={formData.net_salary == 0 ? '' : formData.net_salary}
          error={errors.net_salary}
          onFocus={() => {
            setErrors({
              ...errors,
              net_salary: "",
            });
          }}
          onChange={(e) => {
            setDataPoint("net_salary", e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === ".") {
              e.preventDefault();
            }
          }}
          options={CURRENCIES}
          setSelectedOption={(option: DropdownListOption) => {
            setDataPoint("currency", option);
          }}
          selectedOption={formData.currency}
          type="number"
          trailing
        />
        <InputField
          label="Start Date*"
          type="date"
          placeholder="11-10-1990"
          value={formData.start_date}
          onChange={(e) => {
            setDataPoint("start_date", e.target.value);
          }}
          error={errors.start_date}
          onFocus={() => {
            setErrors({
              ...errors,
              start_date: "",
            });
          }}
          min={
            new Date(new Date().setMonth(new Date().getMonth() - 3))
              .toISOString()
              .split("T")[0]
          }
        />
        <DropdownList
          label="Employment Terms*"
          options={EMPLOYMENT_TERMS}
          selectedOption={formData.employment_term}
          setSelectedOption={(option) =>
            setDataPoint("employment_term", option)
          }
        />
        <ButtonsContainer>
          <Button
            text="Back"
            onClick={onBack}
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{
              width: "fit-content",
            }}
            iconLeading={<img src={ArrowBack} />}
          />
          <Button
            text="Next"
            onClick={onSubmit}
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            style={{
              width: "50%",
            }}
          />
        </ButtonsContainer>
      </FormFields>
    </FormContainer>
  );
};

export default CompensationForm;
