export const ASSETS_OVERVIEW = {
  total_assets: 110,
  total_value: 52434,
  currency: "USD",
  available_assets: 10,
  assets_groups: [
    {
      id: 1,
      name: "Computers",
      value: 30,
    },
    {
      id: 2,
      name: "Furniture",
      value: 20,
    },
    {
      id: 3,
      name: "Electronics",
      value: 10,
    },
    {
      id: 4,
      name: "Office Supplies",
      value: 10,
    },
    {
      id: 5,
      name: "Audio-Visual Equipment",
      value: 20,
    },
    {
      id: 6,
      name: "Kitchen Appliances",
      value: 20,
    },
  ],
  assets_statuses: [
    {
      id: 1,
      status: "In-use",
      value: 20,
    },
    {
      id: 2,
      status: "Under Maintenance",
      value: 40,
    },
    {
      id: 3,
      status: "Lost",
      value: 10,
    },
    {
      id: 4,
      status: "In-active",
      value: 10,
    },
    {
      id: 5,
      status: "Pending Delivery",
      value: 10,
    },
    {
      id: 6,
      status: "Decommissioned",
      value: 10,
    },
  ],
};

export const ASSETS_HUB_LIST = [
  {
    id: "1",
    name: "EliteDesk 800 G5",
    type: "Desktop",
    brand: "HP",
    condition: "80%",
    status: "In-Use",
    assignee: {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
    },
    group: {
      id: 1,
      name: "Computers",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "2",
    name: "Aeron Chair",
    type: "Furniture",
    brand: "Herman Miller",
    condition: "100%",
    status: "Pending Delivery",
    assignee: {
      name: "Phoenix Baker",
      email: "phoenix@interlock.io",
    },
    group: {
      id: 2,
      name: "Furniture",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "3",
    name: "WorkForce Pro WF-4740",
    type: "Printer",
    status: "Under Maintenance",
    brand: "Epson",
    condition: "20%",
    assignee: {
      name: "Lana Steiner",
      email: "lana@interlock.io",
    },
    group: {
      id: 3,
      name: "Electronics",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "4",
    name: "RealPresence Trio 8800",
    type: "Conference Phone",
    status: "In-Use",
    brand: "Polycom",
    condition: "90%",
    assignee: {
      name: "Demi Wilkinson",
      email: "demi@interlock.io",
    },
    group: {
      id: 5,
      name: "Audio-Visual Equipment",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "5",
    name: "MX Master 3",
    type: "Wireless Mouse",
    status: "In-Active",
    brand: "Logitech",
    condition: "60%",
    assignee: null,
    group: {
      id: 3,
      name: "Electronics",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "6",
    name: "Steelcase Gesture Desk",
    type: "Desk",
    status: "In-Use",
    brand: "Steelcase Gesture",
    condition: "100%",
    assignee: {
      name: "Natali Craig",
      email: "natali@interlock.io",
    },
    group: {
      id: 2,
      name: "Furniture",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "7",
    name: "Post-it",
    type: "Sticky Notes",
    status: "Decommissioned",
    brand: "3M",
    condition: "100%",
    assignee: {
      name: "Drew Cano",
      email: "drew@interlock.io",
    },
    group: {
      id: 4,
      name: "Office Supplies",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "11",
    name: "Microwave",
    type: " Microwave 25 Liter",
    brand: "Sharp",
    status: "In-Use",
    assignee: {
      name: "Olivia Rhye",
      position: "Web Designer",
    },
    price: {
      amount: 948.55,
      currency: "USD",
    },
    purchase_date: "2024-01-22",
    group: {
      id: 6,
      name: "Kitchen Appliances",
    },
    condition: "100%",
  },
  {
    id: "12",
    name: "Coffe Machine",
    type: "Coffe Machine 1100 Watts",
    brand: "DeLonghi",
    status: "In-Use",
    assignee: {
      name: "Candice Wu",
      position: "Software Tester",
    },
    price: {
      amount: 767.5,
      currency: "USD",
    },
    purchase_date: "2024-01-22",
    group: {
      id: 6,
      name: "Kitchen Appliances",
    },
    condition: "100%",
  },
  {
    id: "13",
    name: "Laptop",
    type: "M3 15.6 Inch",
    brand: "Apple",
    status: "In-Use",
    assignee: {
      name: "Jenny Wilson",
      position: "UI/UX Designer",
    },
    price: {
      amount: 396.84,
      currency: "USD",
    },
    purchase_date: "2024-01-25",
    group: {
      id: 1,
      name: "Computers",
    },
    condition: "100%",
  },

  {
    id: "15",
    name: "Projector",
    type: "WXCG 3600 Lumens",
    brand: "BenQ",
    status: "In-Use",
    assignee: {
      name: "Lana Steiner",
      position: "Scrum Master",
    },
    price: {
      amount: 351.02,
      currency: "USD",
    },
    purchase_date: "2024-01-02",
    group: {
      id: 5,
      name: "Audio-Visual Equipment",
    },
    condition: "100%",
  },
  {
    id: "16",
    name: "Desk",
    type: "Steelcase Gesture Desk",
    brand: "Steelcase Gesture",
    status: "In-Use",
    assignee: {
      name: "Natali Craig",
      position: "Team Leader",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2024-01-20",
    group: {
      id: 2,
      name: "Furniture",
    },
    condition: "100%",
  },
  {
    id: "17",
    name: "Chair",
    type: "Aeron Chair",
    brand: "Herman Miller",
    status: "In-Use",
    assignee: {
      name: "Phoenix Baker",
      position: "Software Developer",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-10-22",
    group: {
      id: 2,
      name: "Furniture",
    },
    condition: "100%",
  },
];

export const ASSETS_GROUPS_COLORS = [
  {
    id: 1,
    color: "#033462",
  },
  {
    id: 2,
    color: "#033B6E",
  },
  {
    id: 3,
    color: "#0457A5",
  },
  {
    id: 4,
    color: "#0675DC",
  },
  {
    id: 5,
    color: "#2191F9",
  },
  {
    id: 6,
    color: "#EAECF0",
  },
];
export const ASSETS_Statuses_COLORS = [
  {
    id: 1,
    color: "#033462",
  },
  {
    id: 2,
    color: "#033B6E",
  },
  {
    id: 3,
    color: "#0457A5",
  },
  {
    id: 4,
    color: "#0675DC",
  },
  {
    id: 5,
    color: "#2191F9",
  },
  {
    id: 6,
    color: "#EAECF0",
  },
];

export const ASSETS_DETAILS = [
  {
    id: "1",
    name: "EliteDesk 800 G5",
    type: "Desktop",
    brand: "HP",
    condition: {
      percentage: "80%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Use"],
    assignee: {
      id: "8",
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      position: "Web Designer",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Olivia Rhye",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Olivia Rhye",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "2",
    name: "Aeron Chair",
    type: "Furniture",
    brand: "Herman Miller",
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    status: ["Pending Delivery"],
    assignee: {
      id: "2",
      name: "Phoenix Baker",
      email: "phoenix@interlock.io",
      position: "Software Developer",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Phoenix Baker",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Phoenix Baker",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "3",
    name: "WorkForce Pro WF-4740",
    type: "Printer",
    brand: "Epson",
    condition: {
      percentage: "20%",
      last_reviewed: "2023-10-22",
    },
    status: ["Under Maintenance"],
    assignee: {
      id: "3",
      name: "Lana Steiner",
      email: "lana@interlock.io",
      position: "Scrum Master",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Lana Steiner",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Lana Steiner",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "4",
    name: "RealPresence Trio 8800",
    type: "Conference Phone",

    brand: "Polycom",
    condition: {
      percentage: "90%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Use", "Under Maintenance"],
    assignee: {
      id: "4",
      name: "Demi Wilkinson",
      email: "demi@interlock.io",
      position: "Ethical Hacker",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Demi Wilkinson",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Demi Wilkinson",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "5",
    name: "MX Master 3",
    type: "Wireless Mouse",
    brand: "Logitech",
    condition: {
      percentage: "60%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Active"],
    assignee: null,
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Unsigned",
          subject: "Olivia Rhye",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Olivia Rhye",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "6",
    name: "Steelcase Gesture Desk",
    type: "Desk",
    brand: "Steelcase Gesture",
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Use"],
    assignee: {
      id: "6",
      name: "Natali Craig",
      email: "natali@interlock.io",
      position: "Team Leader",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Natali Craig",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Natali Craig",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "7",
    name: "Post-it",
    type: "Sticky Notes",
    brand: "3M",
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    status: ["Decommissioned"],
    assignee: {
      id: "7",
      name: "Drew Cano",
      email: "drew@interlock.io",
      position: "Software Development",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Drew Cano",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "11",
    name: "Microwave",
    type: " Microwave 25 Liter",
    brand: "Sharp",
    status: ["In-Use"],
    assignee: {
      id: "8",
      name: "Olivia Rhye",
      position: "Web Designer",
      email: "olivia@untitledui.com",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 948.55,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 900.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Olivia Rhye",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "12",
    name: "Coffe Machine",
    type: "Coffe Machine 1100 Watts",
    brand: "DeLonghi",
    status: ["In-Use"],
    assignee: {
      id: "5",
      name: "Candice Wu",
      position: "Software Tester",
      email: "candice@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 767.5,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 700.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Candice Wu",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "13",
    name: "Laptop",
    type: "M3 15.6 Inch",
    brand: "Apple",
    status: ["In-Use"],
    assignee: {
      id: "1",
      name: "Jenny Wilson",
      position: "UI/UX Designer",
      email: "jane@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 396.84,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 300.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Jenny Wilson",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },

  {
    id: "15",
    name: "Projector",
    type: "WXCG 3600 Lumens",
    brand: "BenQ",
    status: ["In-Use"],
    assignee: {
      id: "3",
      name: "Lana Steiner",
      position: "Scrum Master",
      email: "lana@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 351.02,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 300.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Lana Steiner",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },

  {
    id: "16",
    name: "Desk",
    type: "Steelcase Gesture Desk",
    brand: "Steelcase Gesture",
    status: ["In-Use"],
    assignee: {
      id: "6",
      name: "Natali Craig",
      position: "Team Leader",
      email: "natali@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Natali Craig",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "17",
    name: "Chair",
    type: "Aeron Chair",
    brand: "Herman Miller",
    status: ["In-Use"],
    assignee: {
      id: "2",
      name: "Phoenix Baker",
      position: "Software Developer",
      email: "phoenix@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Phoenix Baker",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
];

export const ALL_ASSETS_STATUSES = [
  {
    id: 1,
    status: "In-Use",
  },
  {
    id: 2,
    status: "Under Maintenance",
  },
  {
    id: 3,
    status: "Lost",
  },
  {
    id: 4,
    status: "In-Active",
  },
  {
    id: 5,
    status: "Pending Delivery",
  },
  {
    id: 6,
    status: "Decommissioned",
  },
];

export const ALL_ASSETS_GROUPS = [
  {
    id: 1,
    name: "Computers",
  },
  {
    id: 2,
    name: "Furniture",
  },
  {
    id: 3,
    name: "Electronics",
  },
  {
    id: 4,
    name: "Office Supplies",
  },
  {
    id: 5,
    name: "Audio-Visual Equipment",
  },
  {
    id: 6,
    name: "Kitchen Appliances",
  },
];
