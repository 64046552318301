import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CardDataContainer = styled.div`
  display: flex;
  margin-top: ${Spaces.spacing_none};
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;

export const CardDataLabel = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const CardDataValue = styled(Typography)`
  color: ${Colors.text_secondary_on_brand};
`;
