import {
  PayrollProcessContainer,
  PayrollProcessContent,
  PayrollProcessHeader,
  PayrollProcessSubtitle,
  PayrollProcessTitle,
  StepTitle,
  StepTitleCard,
  StepsContainer,
} from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import ProcessStepper from "../ProcessStepper";
import { useAppSelector } from "../../../redux/reduxHooks";
import ReviewPayment from "../processStepperSteps/ReviewPayment";
import PaymentMethod from "../processStepperSteps/PaymentMethod";
import ManualTransfer from "../processStepperSteps/ManualTransfer";
import TransferDetails from "../processStepperSteps/TransferDetails";
import Confirmation from "../processStepperSteps/Confirmation";

const PayrollProcess = () => {
  const steps = useAppSelector((state) => state.payroll.processSteps);
  const currentStep = useAppSelector(
    (state) => state.payroll.activeProcessStep
  );

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <ReviewPayment />;
      case 1:
        return <PaymentMethod />;
      case 2:
        return <ManualTransfer />;
      case 3:
        return <TransferDetails />;

      case 4:
        return <Confirmation />;
    }
  };

  return (
    <PayrollProcessContainer>
      <PayrollProcessHeader>
        <PayrollProcessTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Payroll Process
        </PayrollProcessTitle>
        <PayrollProcessSubtitle
          size={FontSizes.TX_MD}
          weight={FontWeights.REGULAR}
        >
          List of payrolls that are ready to be paid
        </PayrollProcessSubtitle>
      </PayrollProcessHeader>
      <PayrollProcessContent>
        <StepsContainer>
          <StepTitleCard>
            <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Step {currentStep + 1}: {steps[currentStep].title}
            </StepTitle>
          </StepTitleCard>
          {renderStep()}
        </StepsContainer>
        <ProcessStepper />
      </PayrollProcessContent>
    </PayrollProcessContainer>
  );
};

export default PayrollProcess;
