import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../Button";
import { ArrowIcon, Page, PagesContainer, PaginationContainer } from "./styled";
import ArrowLeft from "../../../Images/icons/arrow-left.svg";
import ArrowRight from "../../../Images/icons/arrow-right.svg";
import Typography from "../Typography";

const TablePagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
}: {
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  totalPages: number;
}) => {
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const Pages = () => {
    return Array.from(Array(totalPages).keys()).map((_, index) => {
      return (
        <Page
          key={index}
          onClick={() => handlePageChange(index + 1)}
          active={currentPage === index + 1}
        >
          <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {index + 1}
          </Typography>
        </Page>
      );
    });
  };

  return (
    <PaginationContainer>
      <Button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        text="Previous"
        variant={ButtonVariants.secondary_gray}
        size={ButtonSizes.sm}
        iconLeading={
          <ArrowIcon
            disabled={currentPage === 1}
            src={ArrowLeft}
            alt="Arrow Left"
          />
        }
      />
      <PagesContainer>{Pages()}</PagesContainer>
      <Button
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        text="Next"
        variant={ButtonVariants.secondary_gray}
        size={ButtonSizes.sm}
        iconTrailing={
          <ArrowIcon
            disabled={currentPage === totalPages}
            src={ArrowRight}
            alt="Arrow Right"
          />
        }
      />
    </PaginationContainer>
  );
};

export default TablePagination;
