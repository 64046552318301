import Monitor from "../Images/icons/grey-monitor.svg";
import MonitorDone from "../Images/icons/white-monitor.svg";
import Check from "../Images/icons/check-done.svg";
import Bill from "../Images/icons/dorralBill.svg";
import BillDone from "../Images/icons/dorralBillWhite.svg";
import Truck from "../Images/icons/truck.svg";
import TruckDone from "../Images/icons/truckWhite.svg";
export let RequestAssetSteps = [
  {
    title: "Select Equipment",
    completed: false,
    icon: Monitor,
    iconCompleted: MonitorDone,
  },
  {
    title: "Review & Confirm",
    completed: false,
    icon: Check,
    iconCompleted: Check,
  },
];

export let AddAssetSteps = [
  {
    title: "Asset Information",
    completed: false,
    icon: Monitor,
    iconCompleted: MonitorDone,
  },
  {
    title: "Financial Information",
    completed: false,
    icon: Bill,
    iconCompleted: BillDone,
  },
  {
    title: "Delivery Preferences",
    completed: false,
    icon: Truck,
    iconCompleted: TruckDone,
  },
  {
    title: "Review & Confirm",
    completed: false,
    icon: Check,
    iconCompleted: Check,
  },
];

export const AddAssetEquipmentStatusOptions = [
  {
    id: "1",
    title: "Already supplied To the worker",
  },
  {
    id: "2",
    title: "To be sent via courier",
  },
  {
    id: "3",
    title: "To be picked up from a defined location",
  },
];

export const AddAssetDeliveryStatus = [
  {
    id: "1",
    title: "Shipped via courier",
  },
  {
    id: "2",
    title: "Still pending shipping",
  },
];
