import { Navigate, Outlet, Route, useParams } from "react-router-dom";

import { useEffect, useState } from "react";

const DynamicRoute = ({
  values,
  paramName,
}: {
  values: string[];
  paramName: string;
}) => {
  const params = useParams();
  const [param, setParam] = useState<string>();
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    setParam(params[paramName] || "");
  }, [params]);

  useEffect(() => {
    if (param && !values.includes(param)) {
      setValid(false);
    }
  }, [param]);

  return isValid ? <Outlet /> : <Navigate to={"/404"} />;
};
export default DynamicRoute;
