import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ActionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_xl};
  flex: 1;
  height: fit-content;
  background-color: ${Colors.bg_primary};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_xl};
  height: 161px;
  box-sizing: border-box;
  min-width: 260px;
`;

export const ActionCardTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: -5px;
`;
export const ActionCardDescription = styled(Typography)`
  color: ${Colors.text_tertiary_hover};
  width: 80%;
`;
