import { LoaderWrapper } from "./styled";
import { LoaderSizes, LoaderVariants } from "../../../constants/styles";

const Loader = ({
  variant,
  size,
}: {
  variant?: LoaderVariants;
  size?: LoaderSizes;
}) => {
  return (
    <LoaderWrapper
      variant={variant ? variant : LoaderVariants.primary}
      size={size ? size : LoaderSizes.md}
    />
  );
};

export default Loader;
