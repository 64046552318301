import React, { useCallback, useEffect, useRef } from "react";
import { ModalContainer } from "./styled";
import { ModalProps } from "../../../types/componentsProps";

export default function Modal({ open, onClose, children }: ModalProps) {
  const modalRef = useRef<HTMLDialogElement>(null);

  const onClick = useCallback(
    ({ target }: any) => {
      const { current: el } = modalRef;
      if (target === el) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const { current: el } = modalRef;
    if (!open) el?.close();
    else el?.showModal();
  }, [open]);

  return (
    <ModalContainer ref={modalRef} onClose={onClose} onClick={onClick}>
      {children}
    </ModalContainer>
  );
}
