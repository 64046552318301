import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const AssignAssetModalContentContainer = styled.div`
  padding-block: ${Spaces.spacing_3xl};
`;

export const AssignAssetModalContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${Spaces.spacing_3xl};
`;

export const AssignAssetTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const EmployeeDataContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  align-items: center;
`;

export const EmployeeName = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const EmployeeEmail = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const ModalFooter = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding-block: ${Spaces.spacing_xl};
  padding-inline: ${Spaces.spacing_3xl};
  border-top: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_lg};
  margin-inline: -${Spaces.spacing_xl};
  width: 90%;
`;
