import { DropdownListOption } from "../types/componentsProps";

export const ALL_STATUS_OPTIONS: DropdownListOption[] = [
  {
    label: "All Statuses",
    value: "All Statuses",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Not Contracted Yet",
    value: "Not Contracted Yet",
  },
  {
    label: "Pending Invite",
    value: "Pending Invite",
  },
  {
    label: "Invited",
    value: "Invited",
  },
  {
    label: "Onboarding",
    value: "Onboarding",
  },
  {
    label: "Onboarding at Risk",
    value: "Onboarding at Risk",
  },
  {
    label: "Onboarding Overdue",
    value: "Onboarding Overdue",
  },
  {
    label: "Ready to Start",
    value: "Ready to Start",
  },
  {
    label: "Expired",
    value: "Expired",
  },
  {
    label: "Pending Payment",
    value: "Pending Payment",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Pending Termination",
    value: "Pending Termination",
  },
  {
    label: "Terminated",
    value: "Terminated",
  },
  {
    label: "Resigned",
    value: "Resigned",
  },
  {
    label: "Offboarding",
    value: "Offboarding",
  },
];

export const ALL_ASSETS_STATUS_OPTIONS: DropdownListOption[] = [
  {
    label: "All Statuses",
    value: "All Statuses",
  },
  {
    label: "In-Use",
    value: "In-Use",
  },

  {
    label: "Decommissioned",
    value: "Decommissioned",
  },
  {
    label: "Lost",
    value: "Lost",
  },
  {
    label: "Under Maintenance",
    value: "Under Maintenance",
  },
  {
    label: "In-Active",
    value: "In-Active",
  },
  {
    label: "Pending Delivery",
    value: "Pending Delivery",
  },
];

export const HEALTH_INSURANCE_PROVIDERS_OPTIONS: DropdownListOption[] = [
  {
    label: "AXA",
    value: "AXA",
  },
  {
    label: "MetLife",
    value: "MetLife",
  },
  {
    label: "Allianz",
    value: "Allianz",
  },
];

export const HEALTH_INSURANCE_PLANS_OPTIONS: DropdownListOption[] = [
  {
    label: "Basic",
    value: "Basic",
  },
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Premium",
    value: "Premium",
  },
];
export const EMPLOYMENT_TYPES: DropdownListOption[] = [
  {
    label: "Full-time",
    value: "full_time",
  },
  {
    label: "Part-time",
    value: "part_time",
  },
  {
    label: "Contract",
    value: "contract",
  },
];
export const EMPLOYMENT_TERMS: DropdownListOption[] = [
  {
    label: "3 months",
    value: 3,
  },
  {
    label: "6 months",
    value: 6,
  },
  {
    label: "1 year",
    value: 12,
  },
];

export const CURRENCIES: DropdownListOption[] = [
  {
    label: "EGP",
    value: "egp",
  },
  {
    label: "USD",
    value: "usd",
  },
  {
    label: "EURO",
    value: "eur",
  },
];

export const ASSETS_CATEGORIES_OPTIONS: DropdownListOption[] = [
  {
    value: "Computers",
    label: "Computers",
  },
  {
    value: "Furniture",
    label: "Furniture",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    value: "Office Supplies",
    label: "Office Supplies",
  },
  {
    value: "Audio-Visual Equipment",
    label: "Audio-Visual Equipment",
  },
  {
    value: "Kitchen Appliances",
    label: "Kitchen Appliances",
  },
];

export const COUNTRIES_TO_WORK_FROM: DropdownListOption[] = [
  { label: "Saudi Arabia", value: 151, comingSoon: true },
  { label: "United Arab Emirates", value: 184, comingSoon: true },
  { label: "Egypt", value: 52 },
];

export const CORPORATION_TYPE: DropdownListOption[] = [
  {
    label: "Incorporation",
    value: "Incorporation",
  },
  {
    label: "General Partnership",
    value: "General Partnership",
  },
  {
    label: "Joint Stock Companies",
    value: "Joint Stock Companies",
  },
  {
    label: "Limited Company - LTD",
    value: "Limited Company - LTD",
  },
  {
    label: "Limited Liability Company - LLC",
    value: "Limited Liability Company - LLC",
  },
  {
    label: "Non Profit Corporation",
    value: "Non Profit Corporation",
  },
  {
    label: "Private Company Limited by Shares",
    value: "Private Company Limited by Shares",
  },
];
export const PAYROLL_STATUS_OPTIONS: DropdownListOption[] = [
  {
    label: "All Statuses",
    value: "All Statuses",
  },
  {
    label: "Pending Review",
    value: "Pending Review",
  },
  {
    label: "Pending Payment",
    value: "Pending Payment",
  },

  {
    label: "Pending Processing",
    value: "Pending Processing",
  },
  {
    label: "Processing",
    value: "Processing",
  },
  {
    label: "Processed",
    value: "Processed",
  },
];

export const PAYROLL_EMPLOYEES_STATUS_OPTIONS: DropdownListOption[] = [
  {
    label: "Pending Payment",
    value: "Pending Payment",
  },
  {
    label: "Pending Invite",
    value: "Pending Invite",
  },
  {
    label: "Invited",
    value: "Invited",
  },
  {
    label: "Onboarding",
    value: "Onboarding",
  },
  {
    label: "Onboarding at Risk",
    value: "Onboarding at Risk",
  },
  {
    label: "Onboarding Overdue",
    value: "Onboarding Overdue",
  },
  {
    label: "Ready to Start",
    value: "Ready to Start",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Pending Termination",
    value: "Pending Termination",
  },
  {
    label: "Terminated",
    value: "Terminated",
  },
  {
    label: "Resigned",
    value: "Resigned",
  },
  {
    label: "Offboarding",
    value: "Offboarding",
  },
  {
    label: "Expired",
    value: "Expired",
  },
];

export const COMPANIES_STATUS_OPTIONS: DropdownListOption[] = [
  { label: "All Statuses", value: "All Statuses" },
  { label: "New", value: "New" },
  { label: "Pending", value: "Pending" },
  { label: "Verified", value: "Verified" },
  { label: "Rejected", value: "Rejected" },
  { label: "On Hold", value: "On Hold" },
  { label: "Terminated", value: "Terminated" },
];