import PeopleEmptyState from "../../PeopleEmptyState";
import { useNavigate } from "react-router-dom";
import { useGetContractsToOffboard } from "../../../../queries/people";
import { useEffect } from "react";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import {
  Container,
  InvitedBadge,
  PeopleDataContainer,
  PeopleDataHeader,
  PeopleDataHeaderTitle,
  PeopleDataList,
  PeopleDataListItem,
  PeopleDataListItemFirstRow,
  PeopleDataListItemImg,
  PeopleDataListItemInfo,
  PeopleDataListItemTitle,
  PeopleNumberContainer,
  Dot,
  PeopleDataListItemExtraInfo,
} from "./styled";
import {
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import moment from "moment";

const OffBoarding = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {data: peopleToOffboard, error} = useGetContractsToOffboard();

  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        title: "Error fetching data" + error,
        description: "",
        open: true,
      });
    }
  }, [error]);

  const renderPeopleDataList = () => {
    return peopleToOffboard?.map((person, index) => {
      return (
        <PeopleDataListItem key={person.id}>
          <PeopleDataListItemImg>
            <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.employee.user.first_name[0] +
                person.employee.user.last_name[0]}
            </Typography>
          </PeopleDataListItemImg>
          <PeopleDataListItemInfo>
            <PeopleDataListItemFirstRow>
              <PeopleDataListItemTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {person.employee.user.first_name}{" "}
                {person.employee.user.last_name}
              </PeopleDataListItemTitle>
              <InvitedBadge>
                <Dot />
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  {person.status}
                </Typography>
              </InvitedBadge>
            </PeopleDataListItemFirstRow>
            <PeopleDataListItemExtraInfo
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              {person.type} Employee . Starts on{" "}
              {moment(new Date(person.start_date)).format("D MMM, YYYY")}
            </PeopleDataListItemExtraInfo>
          </PeopleDataListItemInfo>
          {/* <Arrow
            src={ArrowRight}
            alt="Arrow Right"
            onClick={() =>
              setExtendedItem(extendedItem === index ? null : index)
            }
            active={extendedItem === index}
          /> */}
        </PeopleDataListItem>
      );
    });
  };
  
  return !peopleToOffboard ? null : peopleToOffboard?.length !== 0 ? (
    <Container>
      <PeopleDataContainer>
        <PeopleDataHeader>
          <PeopleDataHeaderTitle
            size={FontSizes.TX_MD}
            weight={FontWeights.SEMIBOLD}
          >
            People
          </PeopleDataHeaderTitle>
          <PeopleNumberContainer>
            <Typography size={FontSizes.TX_XS} weight={FontWeights.SEMIBOLD}>
              {peopleToOffboard?.length}
            </Typography>
          </PeopleNumberContainer>
        </PeopleDataHeader>
        <PeopleDataList>{renderPeopleDataList()}</PeopleDataList>
      </PeopleDataContainer>
      {/* {renderExtendedItem()} */}
    </Container>
  ) : (
    <PeopleEmptyState
      title="You don’t have any offboarding contracts"
      description="Off-boarded employees have completed all their off-boarding tasks. You can view contacts from employees profile."
      btnText="View People"
      onBtnClick={() => navigate("/people/manage")}
    />
  );
};

export default OffBoarding;
