import styled from "styled-components";
import Typography from "../../../../components/shared/Typography";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";

export const ManageHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;

export const PageTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const PageSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const ManageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: ${Spaces.spacing_xl};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_2xl};
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_lg} ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
`;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Spaces.spacing_2xl};
  width: 35%;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;
