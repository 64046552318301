import React, { useEffect, useRef, useState } from "react";
import Dots from "../../../Images/icons/dotsVertical.svg";
import {
  ActionItem,
  ActionItemLink,
  ActionText,
  ActionsButton,
  ActionsDropdown,
} from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";

interface ActionsDropDownProps {
  actions: {
    text: string;
    onSelect: () => void;
    url?: string;
  }[];
}

const ActionsDropDown: React.FC<ActionsDropDownProps> = ({ actions }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const btn = useRef<HTMLImageElement>(null);
  const list = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        btn.current &&
        !btn.current.contains(event.target as Node) &&
        list.current &&
        !list.current.contains(event.target as Node) &&
        event.target !== btn.current
      ) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <ActionsButton
        src={Dots}
        alt="dots"
        width={20}
        height={20}
        onClick={() => {
          setOpenDropdown(!openDropdown);
        }}
        ref={btn}
      />
      <ActionsDropdown show={openDropdown} ref={list}>
        {actions.map((action, index) =>
          action.text === "Download" && action.url ? (
            <ActionItemLink download href={action.url} target="_blank">
              <ActionText size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                {action.text}
              </ActionText>
            </ActionItemLink>
          ) : (
            <ActionItem key={index} onClick={() => action.onSelect()}>
              <ActionText size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                {action.text}
              </ActionText>
            </ActionItem>
          )
        )}
      </ActionsDropdown>
    </>
  );
};

export default ActionsDropDown;
