import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownListOption } from "../../types/componentsProps";
import { Country, DashboardBanner } from "../../types";
import { DashboardBanners } from "../../constants/banners";
import { AppDispatch } from "../store";

interface AppState {
  extraBreadcrumbItems: string[];
  countriesOptions: DropdownListOption[];
  dashboardBanners: DashboardBanner[];
}

const initialState: AppState = {
  extraBreadcrumbItems: [],
  countriesOptions: [],
  dashboardBanners: DashboardBanners,
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setExtraBreadcrumbItems: (state, action: PayloadAction<string[]>) => {
      state.extraBreadcrumbItems = action.payload;
    },
    setUserCountriesOptions: (
      state,
      action: PayloadAction<DropdownListOption[]>
    ) => {
      state.countriesOptions = action.payload;
    },
    createCountriesDropdownOptions: (
      state,
      action: PayloadAction<Country[]>
    ) => {
      state.countriesOptions = action.payload.map((country) => ({
        label: country.name,
        value: country.id,
      }));
    },
    setDashboardBanners: (state, action: PayloadAction<DashboardBanner[]>) => {
      state.dashboardBanners = action.payload;
    },
  },
});

export const storeDashboardBanners =
  (banners: DashboardBanner[]) => (dispatch: AppDispatch) => {
    const stringifiedBanners = JSON.stringify(
      banners.map((banner) => banner.id)
    );
    localStorage.setItem("banners", stringifiedBanners);
    dispatch(setDashboardBanners(banners));
  };

export const removeDashboardBanners = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("banners");
  dispatch(setDashboardBanners([]));
};

export const retrieveDashboardBanners = () => (dispatch: AppDispatch) => {
  const bannersIds = localStorage.getItem("banners")
    ? JSON.parse(localStorage.getItem("banners") as string)
    : ["1", "2", "3", "4"];
  const banners = DashboardBanners.filter((banner) =>
    bannersIds.includes(banner.id)
  );
  dispatch(setDashboardBanners(banners));
};

export const {
  setExtraBreadcrumbItems,
  setUserCountriesOptions,
  createCountriesDropdownOptions,
  setDashboardBanners,
} = appStateSlice.actions;

export default appStateSlice.reducer;
