import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const CompaniesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-inline: ${Spaces.spacing_3xl};
  padding-block: ${Spaces.spacing_lg};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary};
  border: 1px solid ${Colors.border_secondary};
  grid-area: 4 / 1 / span 1 / span 1;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    grid-area: companies;
  }
`;

export const CompaniesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;

export const CompaniesTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const CompaniesList = styled.div`
  display: flex;
  gap: ${Spaces.spacing_sm};
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none;
  margin-top: ${Spaces.spacing_2xl};
`;

export const CompanyCard = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${Spaces.spacing_md};
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary_hover};
  padding-block: ${Spaces.spacing_md};
  padding-inline: ${Spaces.spacing_xl};
  min-width: 25%;
`;

export const CompanyCardTitle = styled(Typography)`
  color: ${Colors.text_quarterary_on_brand};
`;
