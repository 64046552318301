import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { useAppDispatch } from "../../../../redux/reduxHooks";
import Button from "../../../shared/Button";
import {
  ButtonsContainer,
  PaymentMethodCard,
  IconContainer,
  OptionContainer,
  Subtext,
  TextContainer,
  Title,
  TitleContainer,
} from "./styled";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import {
  onNextPayrollStep,
  onPreviousPayrollStep,
} from "../../../../redux/slices/payrollSlice";
import { ReactNode } from "react";
import Bank from "../../../../Images/icons/bank.svg";
import CreditCard from "../../../../Images/icons/credit-card-02.svg";
import Crypto from "../../../../Images/icons/crypto.svg";
import Checkbox from "../../../shared/Checkbox";
import Badge from "../../../shared/Badge";
import { BadgeTypes } from "../../../../types/componentsProps";
const PaymentMethod = () => {
  const dispatch = useAppDispatch();

  const Option = ({
    title,
    subtext,
    icon,
    isActive,
    isDisabled,
  }: {
    title: string;
    subtext: string;
    icon: ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
  }) => {
    return (
      <OptionContainer isActive={isActive} isDisabled={isDisabled}>
        <IconContainer>{icon}</IconContainer>
        <TextContainer>
          <TitleContainer>
            <Title size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {title}
            </Title>
            {isDisabled ? (
              <Badge text="Coming Soon" color={BadgeTypes.blueLight} />
            ) : null}
          </TitleContainer>

          <Subtext size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
            {subtext}
          </Subtext>
        </TextContainer>
        <Checkbox
          checked={isActive}
          onChange={() => {}}
          disabled={isDisabled}
          label={""}
          subtext={""}
          containerStyle={{ marginLeft: "auto" }}
        />
      </OptionContainer>
    );
  };
  return (
    <>
      <PaymentMethodCard>
        <Option
          title="Send a manual transfer"
          subtext="Send a wire transfer from your bank"
          icon={<img src={Bank} alt="bank" />}
          isActive
        />
        <Option
          title="Link a payment method"
          subtext="Supports credit and debit cards"
          icon={<img src={CreditCard} alt="credit-card" />}
          isDisabled
        />
        <Option
          title="Send a crypto-currency transfer"
          subtext="Supports USDC through your Coinbase account"
          icon={<img src={Crypto} alt="crypto" />}
          isDisabled
        />
      </PaymentMethodCard>
      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            dispatch(onPreviousPayrollStep());
          }}
        />
        <Button
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            dispatch(onNextPayrollStep());
          }}
        />
      </ButtonsContainer>
    </>
  );
};

export default PaymentMethod;
