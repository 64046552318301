import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";

export const InputContainer = styled.div`
  display: flex;
  padding: 10px 14px;
  gap: ${Spaces.spacing_md};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
`;

export const Input = styled.input`
  border: none;
  width: 100%;
  background-color: transparent;
  color: ${Colors.text_primary};
  font-size: ${FontSizes.TX_MD};
  line-height: 24px;
  font-weight: ${FontWeights.REGULAR};
  outline: none;
  ::placeholder {
    color: ${Colors.text_placeholder};
  }
`;
