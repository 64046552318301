import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import { ButtonsContainer, TransferDetailsCard } from "./styled";
import { CardTitle } from "../../../shared/Card";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import {
  onNextPayrollStep,
  onPreviousPayrollStep,
  updatePayrollProcessData,
} from "../../../../redux/slices/payrollSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import DropdownList from "../../../shared/DropdownList";
import { organizationLocationOptionsWithoutIcon } from "../../../../constants/registration";
import { DropdownListOption } from "../../../../types/componentsProps";
import { CURRENCIES } from "../../../../constants/dropDownLists";

const TransferDetails = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.payroll.payrollProcessData);

  return (
    <>
      <TransferDetailsCard>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Bank Location
        </CardTitle>
        <DropdownList
          label="Bank Location"
          options={organizationLocationOptionsWithoutIcon}
          selectedOption={data.bankDetails.location}
          setSelectedOption={(value: DropdownListOption) => {
            dispatch(
              updatePayrollProcessData({
                bankDetails: {
                  ...data.bankDetails,
                  location: value,
                },
              })
            );
          }}
          containerStyle={{
            marginBlockEnd: "0.25rem",
          }}
        />

        <DropdownList
          label="Currency"
          options={CURRENCIES}
          selectedOption={data.bankDetails.currency}
          setSelectedOption={(value: DropdownListOption) => {
            dispatch(
              updatePayrollProcessData({
                bankDetails: {
                  ...data.bankDetails,
                  currency: value,
                },
              })
            );
          }}
        />
      </TransferDetailsCard>

      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            dispatch(onPreviousPayrollStep());
          }}
        />
        <Button
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            dispatch(onNextPayrollStep());
          }}
        />
      </ButtonsContainer>
    </>
  );
};

export default TransferDetails;
