import styled from "styled-components";
import { Spaces } from "../../../../constants/styles";
import { Card } from "../../../shared/Card";

export const TransferDetailsCard = styled(Card)`
  margin-block: ${Spaces.spacing_md};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
