import React from "react";
import { CheckboxProps } from "../../../types/componentsProps";
import {
  CheckboxContainer,
  Subtext,
  CheckboxText,
  CheckboxInput,
  CheckIcon,
  RadioInnerDev,
} from "./styled";
import Label from "../Label";
import { FontSizes, FontWeights } from "../../../constants/styles";
import Check from "../../../Images/icons/check.svg";

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  subtext,
  radio,
  containerStyle,
  ...props
}) => {
  const renderChecked = () => {
    if (props.checked) {
      return radio ? <RadioInnerDev /> : <CheckIcon src={Check} alt="check" />;
    }
  };

  return (
    <CheckboxContainer subtext={subtext} style={containerStyle}>
      <CheckboxInput
        type="checkbox"
        checked={props.checked}
        radio={radio}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.currentTarget.click();
          }
        }}
        {...props}
      />

      {renderChecked()}

      <CheckboxText>
        <Label text={label} />
        {subtext && (
          <Subtext size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {subtext}
          </Subtext>
        )}
      </CheckboxText>
    </CheckboxContainer>
  );
};

export default Checkbox;
