import {
  ASSETS,
  EMPLOYEE_BENEFITS,
  EMPLOYEE_PAYSLIPS_OVERVIEW,
  EMPLOYEE_REQUESTS_Details,
  EMPLOYEE_REQUESTS_LIST,
  ONBOARDING_OFFBOARDING_STATUS_DASHBOARD,
  PAYSLIPS,
} from "../mockServer/MockData";
import { BadgeTypes } from "./componentsProps";

export type SalaryCalculations = {
  salary_total: number;
  salary_gross: number;
  currency: string;
  eor_fees: {
    total: number;
    amount: number;
    period: string;
  };
};

export interface Employee {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  contract_id: string | null;
  contract_status: string | null;
  currency: string | null;
  title: string;
  end_date: string;
  created_at: string;
  employment_term: number;
  company: {
    id: number;
    name: string;
  };
}
export interface EmployeeDetails {
  id: number;
  type_of_worker: string;
  work_from_country_id: number;
  nationality_id: number;
  company: {
    id: number;
    name: string;
  };
  nationality: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    verified: boolean;
    type: string;
  };
  contract: {
    id: number;
    department: string;
    job_title: string;
    employment_term: number;
    hours_per_week: number;
    start_date: string;
    end_date: string;
    employment_type: string;
    job_description: string;
    salary_net: number;
    salary_type: string;
    status: string;
    salary_gross: number;
    probation_period: number;
    probation_last_day: string;
    created_at: string;
    currency: string;
    auto_renew: boolean;
  } | null;
}

export type Asset = (typeof ASSETS)[0];

export type RequestOverview = (typeof EMPLOYEE_REQUESTS_LIST)[0];
export type EmployeeRequest = (typeof EMPLOYEE_REQUESTS_Details)[0];
export type Benefit = (typeof EMPLOYEE_BENEFITS)[0];
export enum PEOPLE_STATUS {
  not_contracted = "Not Contracted Yet",
  pending_payment = "Pending Payment",
  cancelled = "Cancelled",
  pending_termination = "Pending Termination",
  resigned = "Resigned",
  Offboarding = "Offboarding",
  pending_invite = "Pending Invite",
  invited = "Invited",
  onboarding = "Onboarding",
  onboarding_at_risk = "Onboarding at Risk",
  active = "Active",
  onboarding_overdue = "Onboarding Overdue",
  ready_to_start = "Ready to Start",
  expired = "Expired",
}

export type PayslipOverView = {
  id: string;
  pay_period_start: string;
  pay_period_end: string;
  payment_date: string;
  salary_gross: string;
  salary_net: string;
  currency: string;
};
export type Payslip = {
  id: string;
  pay_period_start: string;
  pay_period_end: string;
  payment_date: string;
  salary_gross: number;
  salary_net: number;
  currency: string;
  first_name: string;
  last_name: string;
  email: string;
  job_title: string;
  total_deductions: number;
  contract_salary_gross: number;
  total_bonuses: number;
  taxes: number;
  insurance: number;
};
export const PEOPLE_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [PEOPLE_STATUS.not_contracted]: BadgeTypes.gray,
  [PEOPLE_STATUS.pending_invite]: BadgeTypes.error,
  [PEOPLE_STATUS.invited]: BadgeTypes.blueLight,
  [PEOPLE_STATUS.onboarding]: BadgeTypes.blue,
  [PEOPLE_STATUS.onboarding_at_risk]: BadgeTypes.error,
  [PEOPLE_STATUS.active]: BadgeTypes.success,
  [PEOPLE_STATUS.onboarding_overdue]: BadgeTypes.error,
  [PEOPLE_STATUS.ready_to_start]: BadgeTypes.success,
  [PEOPLE_STATUS.expired]: BadgeTypes.error,
  [PEOPLE_STATUS.pending_payment]: BadgeTypes.warning,
  [PEOPLE_STATUS.cancelled]: BadgeTypes.error,
  [PEOPLE_STATUS.pending_termination]: BadgeTypes.warning,
  [PEOPLE_STATUS.resigned]: BadgeTypes.error,
  [PEOPLE_STATUS.Offboarding]: BadgeTypes.error,
};

export type OnboardingAndOffboardingStatusDashboard = typeof ONBOARDING_OFFBOARDING_STATUS_DASHBOARD
