import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const BannerContainer = styled.div<{ color: string }>`
  display: flex;
  padding-inline: ${Spaces.spacing_3xl};
  padding-block: ${Spaces.spacing_lg} ${Spaces.spacing_2xl};
  border-radius: ${Radiuses.radius_md};
  background-color: ${(props) => props.color};
  border: 1px solid ${Colors.border_secondary};
  justify-content: space-between;
  align-items: flex-start;
`;

export const BannerTitle = styled(Typography)`
  color: ${Colors.fg_brand_secondary_500};
`;
export const BannerDescription = styled(Typography)`
  color: ${Colors.fg_brand_secondary_500};
  width: 95%;
  white-space: pre;
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_sm};
  align-items: flex-start;
`;

export const ImagesContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_sm};
  align-items: center;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  max-height: 143px;
`;

export const BannerCloseIcon = styled.img`
  position: absolute;
  top: 0;
  right: -10px;
  cursor: pointer;
`;
