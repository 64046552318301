import styled from "styled-components";
import { Breakpoints, Colors, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import SocialButton from "../../../shared/SocialButton";
import Button from "../../../shared/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: scroll;
  scrollbar-width: none;
  background-color: ${Colors.bg_secondary_hover};
  max-height: 95%;
  padding-block-start: ${Spaces.spacing_4xl};

  @media screen and (max-width: ${Breakpoints.tablet}) {
    justify-content: flex-start;
    padding-inline: ${Spaces.spacing_4xl};
    padding-block: ${Spaces.spacing_4xl};
    width: 100%;
  }
  @media screen and (max-height: ${Breakpoints.tablet}) and (max-width: ${Breakpoints.tablet}) {
    justify-content: flex-start;
    padding-block-start: ${Spaces.spacing_4xl};
    padding-block-end: ${Spaces.spacing_11xl};
    height: 100vh;
  }
`;

export const TitlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_none};
  margin-bottom: ${Spaces.spacing_xl};
`;

export const Title = styled(Typography)`
  color: ${Colors.text_brand_primary};
  margin-block: ${Spaces.spacing_none};
`;

export const Subtitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-block: ${Spaces.spacing_none};
`;

export const GoogleButton = styled(SocialButton)`
  width: 100%;
`;

export const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  margin-block: ${Spaces.spacing_md};
  gap: 8px;
  align-items: center;
  justify-content: space-evenly;
`;

export const Divider = styled.div`
  width: 44%;
  height: 2px;
  background-color: ${Colors.border_primary};
`;

export const DividerText = styled(Typography)`
  color: ${Colors.fg_senary};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  @media screen and (max-height: ${Breakpoints.tablet}) and (max-width: ${Breakpoints.tablet}) {
    overflow-y: unset;
  }
`;

export const NameInputs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const NextButton = styled(Button)`
  width: 48%;
`;
