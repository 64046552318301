import Label from "../Label";
import { PartialDropdownProps } from "../../../types/componentsProps";
import DropdownList from "../DropdownList";
import {
  ExtraMessage,
  InputsContainer,
  PartialDropdownContainer,
} from "./styled";
import InputField from "../InputField";

const PartialDropdown = ({
  label,
  options,
  setSelectedOption,
  selectedOption,
  trailing = false,
  error,
  ...props
}: PartialDropdownProps) => {
  return (
    <PartialDropdownContainer>
      {label && <Label text={label} />}
      <InputsContainer error={!!error}>
        {trailing ? (
          <>
            <>
              <InputField
                {...props}
                containerStyle={{ flex: 1 }}
                style={{ border: "none", width: "100%", boxShadow: "none" }}
              />
              <DropdownList
                options={options}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                label=""
                style={{ border: "none" }}
                containerStyle={{ width: "20%", border: "none" }}
              />
            </>
          </>
        ) : (
          <>
            <DropdownList
              options={options}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              label=""
              style={{ border: "none" }}
              containerStyle={{ width: "20%", border: "none" }}
            />
            <InputField
              {...props}
              containerStyle={{ flex: 1 }}
              style={{ border: "none", width: "100%", boxShadow: "none" }}
              error=""
              hint=""
            />
          </>
        )}
      </InputsContainer>
      <ExtraMessage error={!!error}>{error}</ExtraMessage>
    </PartialDropdownContainer>
  );
};

export default PartialDropdown;
