import {
  COMPANIES_PAYROLLS,
  PAYROLLS_REVIEWS_EMPLOYEES,
  PAYROLLS_DETAILS,
  USER_PAYMENT_PROCESS,
  PAYROLL_CYCLE_CURRENT_UPCOMING,
} from "../mockServer/MockData";
import { BadgeTypes } from "./componentsProps";

export type CompanyPayroll = (typeof COMPANIES_PAYROLLS)[0];
export type PayrollReviewEmployee = (typeof PAYROLLS_REVIEWS_EMPLOYEES)[0];
export type PayrollDetails = (typeof PAYROLLS_DETAILS)[0];
export type UserPaymentProcessReview = typeof USER_PAYMENT_PROCESS;
export type PayrollCycleCurrentUpcoming = typeof PAYROLL_CYCLE_CURRENT_UPCOMING;
export enum PayrollStatus {
  PENDING_REVIEW = "Pending Review",
  PENDING_PAYMENT = "Pending Payment",
  PENDING_PROCESSING = "Pending Processing",
  PROCESSING = "Processing",
  PROCESSED = "Processed",
}
export const PAYROLL_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [PayrollStatus.PENDING_REVIEW]: BadgeTypes.warning,
  [PayrollStatus.PENDING_PAYMENT]: BadgeTypes.orange,
  [PayrollStatus.PENDING_PROCESSING]: BadgeTypes.warning,
  [PayrollStatus.PROCESSING]: BadgeTypes.indigo,
  [PayrollStatus.PROCESSED]: BadgeTypes.success,
};

export enum PAYROLL_EMPLOYEES_STATUS {
  PENDING_PAYMENT = "Pending Payment",
  PENDING_INVITE = "Pending Invite",
  INVITED = "Invited",
  ONBOARDING = "Onboarding",
  ONBOARDING_AT_RISK = "Onboarding at Risk",
  ONBOARDING_OVERDUE = "Onboarding Overdue",
  READY_TO_START = "Ready to Start",
  ACTIVE = "Active",
  CANCELLED = "Cancelled",
  PENDING_TERMINATION = "Pending Termination",
  TERMINATED = "Terminated",
  RESIGNED = "Resigned",
  OFFBOARDING = "Offboarding",
  EXPIRED = "Expired",
}
