import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const DetailsTabContainer = styled.div`
  width: 100%;
  padding-top: ${Spaces.spacing_xl};
  display: flex;
  gap: 10px;
`;

export const CardsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaymentTrackerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spaces.spacing_xl};
  align-items: flex-start;
  width: 100%;
`;
export const StepTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;
export const StepSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const StepIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Spaces.spacing_xs};
`;

export const StepIcon = styled.img<{
  isCurrent: boolean;
}>`
  border-radius: ${Radiuses.radius_full};
  width: 24px;
  height: 24px;

  ${(props) =>
    props.isCurrent &&
    `box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
    
    `}
`;

export const StepperConnector = styled.div<{
  isComplete: boolean;
  isLast?: boolean;
}>`
  width: 2px;
  height: 34px;
  background-color: ${Colors.border_secondary};
  border-radius: ${Radiuses.radius_xxs};
  background-color: ${Colors.border_secondary};
  display: block;
  /* margin-bottom: ${Spaces.spacing_xs}; */

  ${(props) =>
    props.isComplete &&
    `background-color: ${Colors.bg_brand_solid};
        `}

  ${(props) =>
    props.isLast &&
    `display: none;
        `}
`;
