import { Divider, InvoiceDetailsCards, InvoiceDetailsContainer, InvoiceDetailsHeader, InvoiceDetailsSubtitle, InvoiceDetailsTitle, ItemsTableContainer, TableTextBold, TableTextFaded, TotalText, TotalTextContainer } from './styled';
import { FontSizes, FontWeights } from '../../../../constants/styles';
import { Card, CardRow, CardRowTitle, CardRowValue, CardTitle } from '../../../../components/shared/Card';
import moment from 'moment';
import { Table, TableCell, TableHeader, TableRow } from '../../../../components/shared/Table/styled';
import { currencyFormatter } from '../../../../utilities/currencyFormatter';
import { useParams } from 'react-router-dom';
import { useGetInvoiceDetailsAdmin } from '../../../queries/payment';

const InvoiceDetailsAdmin = () => {
  const {invoice_id} = useParams();
  const {data: invoiceDetails, error} = useGetInvoiceDetailsAdmin(invoice_id);
  
   const renderRows = () => {
      return invoiceDetails?.line_items?.map((item, index) => {
         return (
            <>
            <TableRow>
               <TableCell
                  style={{
                  width: "70%",
                  }}
               >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                  <TableTextBold
                     size={FontSizes.TX_SM}
                     weight={FontWeights.MEDIUM}
                  >
                     {item.description}
                  </TableTextBold>
                  </div>
               </TableCell>
               <TableCell>
                  <TableTextFaded
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                  >
                  {item.quantity}
                  </TableTextFaded>
               </TableCell>
               <TableCell>
                  <TableTextFaded
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                  >
                  {currencyFormatter(item.unit_price, invoiceDetails.currency)}
                  </TableTextFaded>
               </TableCell>
               <TableCell>
                  <TableTextFaded
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                  >
                  {currencyFormatter(
                     item.quantity * item.unit_price,
                     invoiceDetails.currency
                  )}
                  </TableTextFaded>
               </TableCell>
            </TableRow>
          </>
         );
      });
   };
  
    return invoiceDetails ? (
      <InvoiceDetailsContainer>
        <InvoiceDetailsHeader>
          <div>
            <InvoiceDetailsTitle
              size={FontSizes.SM}
              weight={FontWeights.SEMIBOLD}
            >
              {invoiceDetails.public_id}
            </InvoiceDetailsTitle>
            <InvoiceDetailsSubtitle
              size={FontSizes.TX_MD}
              weight={FontWeights.REGULAR}
            >
              {invoiceDetails.description}
            </InvoiceDetailsSubtitle>
          </div>
        </InvoiceDetailsHeader>
        <InvoiceDetailsCards>
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Bill To
            </CardTitle>
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Client Name
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {invoiceDetails?.company.name}
              </CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Address
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {invoiceDetails.company.billing_address ?? "-"}
              </CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Registration Number
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {invoiceDetails.company.registration_number}
              </CardRowValue>
            </CardRow>
          </Card>
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Invoice Details
            </CardTitle>
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Issue Date
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {moment(invoiceDetails.created_at).format("DD MMM, YYYY")}
              </CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Due Date
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {moment(invoiceDetails.due_date).format("DD MMM, YYYY")}
              </CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Status
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
               {invoiceDetails.status}
              </CardRowValue>
            </CardRow>
          </Card>
        </InvoiceDetailsCards>
        <ItemsTableContainer>
          <Table>
            <TableHeader>
              <TableCell
                style={{
                  width: "70%",
                }}
              >
                <TableTextFaded
                  size={FontSizes.TX_XS}
                  weight={FontWeights.MEDIUM}
                >
                  Item
                </TableTextFaded>
              </TableCell>
  
              <TableCell>
                {" "}
                <TableTextFaded
                  size={FontSizes.TX_XS}
                  weight={FontWeights.MEDIUM}
                >
                  QTY
                </TableTextFaded>
              </TableCell>
              <TableCell>
                {" "}
                <TableTextFaded
                  size={FontSizes.TX_XS}
                  weight={FontWeights.MEDIUM}
                >
                  Unit Price
                </TableTextFaded>
              </TableCell>
              <TableCell>
                {" "}
                <TableTextFaded
                  size={FontSizes.TX_XS}
                  weight={FontWeights.MEDIUM}
                >
                  Amount
                </TableTextFaded>
              </TableCell>
            </TableHeader>
            <Divider />
            {renderRows()}
          </Table>
        </ItemsTableContainer>
        <TotalTextContainer>
          <TotalText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Total
          </TotalText>
          <TotalText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {currencyFormatter(invoiceDetails.total, invoiceDetails.currency)}
          </TotalText>
        </TotalTextContainer>
      </InvoiceDetailsContainer>
    ) : null;
}

export default InvoiceDetailsAdmin