import styled from "styled-components";
import Typography from "../../../shared/Typography";
import { Colors, Spaces } from "../../../../constants/styles";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled(Typography)`
  color: ${Colors.text_brand_primary};
`;
export const FormSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre-wrap;
  margin-bottom: ${Spaces.spacing_xl};
  margin-top: ${Spaces.spacing_xs};
`;
export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
