import {
  ContentContainer,
  IconContainer,
  ModalDescription,
  ModalFooter,
  ModalTitle,
} from "./styled";

import { ModalProps, SnackbarTypes } from "../../../types/componentsProps";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import ModalIcon from "../../../Images/icons/bank-brand.svg";
import InputField from "../../shared/InputField";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import {
  PAYROLL_PROCESS_STEPS,
  setSelectedInvoices,
  updatePayInvoicesVariables,
} from "../../../redux/slices/paymentSlice";
import {
  useCreatePayment,
  useGetCurrentBalance,
} from "../../../queries/payment";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { setPayrollProcessSteps } from "../../../redux/slices/payrollSlice";

const TransactionNumberModal = ({
  open,
  onClose,
  handlePayment
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  handlePayment: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { pay_invoices_variables } = useAppSelector((state) => state.payment);

  return (
    <Modal onClose={onClose} open={open}>
      <ContentContainer>
        <IconContainer>
          <img src={ModalIcon} alt="alert-icon" />
        </IconContainer>
        <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Please enter transaction reference{"\n"} Number
        </ModalTitle>
        <ModalDescription size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          After you make the transaction from your bank portal{"\n"}or app,
          please copy and paste the transaction{"\n"} reference number here.
        </ModalDescription>
        <InputField
          label="Transaction Reference Number"
          placeholder="xxxxxxxxxxxx"
          containerStyle={{ width: "100%" }}
          value={pay_invoices_variables.transactionReferenceNumber}
          onChange={(e) => {
            dispatch(
              updatePayInvoicesVariables({
                transactionReferenceNumber: e.target.value,
              })
            );
          }}
        />
        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={onClose}
          />

          <Button
            text="Confirm"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            disabled={!pay_invoices_variables.transactionReferenceNumber}
            onClick={() => {
              handlePayment();
              onClose();
            }}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};

export default TransactionNumberModal;
