import {
  StepContainer,
  StepIcon,
  StepTitle,
  StepperConnector,
  StepperWrapper,
  StepIconWrapper,
} from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import ActiveIcon from "../../../Images/icons/activeStepIcon.svg";
import InactiveIcon from "../../../Images/icons/InactiveStepperStepGrey.svg";
import CompletedIcon from "../../../Images/icons/completedStepIcon.svg";
import { useAppSelector } from "../../../redux/reduxHooks";

const PayInvoicesStepper = () => {
  const steps = useAppSelector((state) => state.payment.pay_invoices_steps);
  const currentStep = useAppSelector(
    (state) => state.payment.current_pay_invoices_step
  );

  const renderSteps = () => {
    return steps.map((step, index) => {
      const isCurrent = currentStep === index;

      return (
        <StepContainer key={index}>
          <StepIconWrapper>
            <StepIcon
              src={
                isCurrent
                  ? ActiveIcon
                  : step.completed
                  ? CompletedIcon
                  : InactiveIcon
              }
              width={32}
              height={32}
              alt="step-icon"
              isCurrent={isCurrent}
            />
            <StepperConnector
              isComplete={step.completed}
              isLast={index === steps.length - 1}
            />
          </StepIconWrapper>

          <div>
            <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {step.title}
            </StepTitle>
          </div>
        </StepContainer>
      );
    });
  };

  return <StepperWrapper>{renderSteps()}</StepperWrapper>;
};

export default PayInvoicesStepper;
