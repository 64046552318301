import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
  justify-content: center;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;
`;
export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
  text-align: center;
  width: 90%;
  margin-top: ${Spaces.spacing_xl};
  white-space: pre-wrap;
`;
export const ModalDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  text-align: center;
  width: 80%;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  width: 100%;
  justify-content: space-between;
  margin-top: ${Spaces.spacing_4xl};
`;
