import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import BG from "../../../Images/icons/BackgroundDatternDecorative.svg";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-block-end: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_xs};
`;

export const PageTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PageSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const PageContent = styled.div`
  display: flex;
  padding-top: ${Spaces.spacing_4xl};
  justify-content: space-between;
  gap: ${Spaces.spacing_xl};
`;

export const Title = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const Subtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const ContentContainer = styled.div`
  width: 100%;
  padding-block: ${Spaces.spacing_3xl};
  box-sizing: border-box;
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Spaces.spacing_xs};
`;
export const ModalFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_3xl};

  margin-top: ${Spaces.spacing_3xl};
`;

export const ModalFooter = styled.div`
  position: absolute;
  bottom: ${Spaces.spacing_xl};
  display: flex;
  justify-content: end;
  padding-block: ${Spaces.spacing_xl};
  padding-inline: ${Spaces.spacing_3xl};
  width: 90%;
  border-top: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_lg};
  margin-inline: -${Spaces.spacing_xl};
`;

export const EmptyStateAssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 50%;
`;

export const EmptyStateAssetsCenterImg = styled.img`
  margin-top: 140px;
`;

export const EmptyStateAssetsTitle = styled(Typography)`
  margin-top: ${Spaces.spacing_xl};
  color: ${Colors.text_primary};
`;
export const EmptyStateAssetsDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre;
  text-align: center;
`;

export const EmptyStateAssetsButtons = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  margin-top: ${Spaces.spacing_3xl};
`;

export const AssetTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const AssetType = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const UnAssignAssetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_lg};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_error};
  width: fit-content;
  cursor: pointer;
  color: ${Colors.button_tertiary_error_fg};
`;
