import styled from "styled-components";
import { Breakpoints, Spaces } from "../../../constants/styles";
import BG from "../../../Images/registerBG.png";

export const SidePanelContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: ${Spaces.spacing_4xl};
  width: 28.34%;
  min-width: 300px;
  max-width: 440px;
  height: 100%;
  left: 0px;
  background: linear-gradient(0deg, rgba(2, 41, 77, 0.9), rgba(2, 41, 77, 0.9)),
    url(${BG});
  background-size: cover;
  background-position: center;

  @media (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin-top: ${Spaces.spacing_4xl};
`;
