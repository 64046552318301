import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { Table, TableCell, TableRow } from "../../../shared/Table/styled";
import {
  InvoicesTableContainer,
  TableBoldText,
  TableCustomHeader,
  TableFadedText,
} from "./styled";
import Badge from "../../../shared/Badge";
import { INVOICE_STATUS_BADGE_COLOR } from "../../../../types/payments";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import moment from "moment";
import Button from "../../../shared/Button";
import { useGetPaymentDetails } from "../../../../queries/payment";

const InvoicesTab = () => {
  const { payment_id } = useParams();
  const navigate = useNavigate();
  const { data: payment } = useGetPaymentDetails(payment_id);

  const renderRows = () => {
    return payment?.invoices.map((invoice) => {
      return (
        <TableRow>
          <TableCell>
            <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {invoice.public_id}
            </TableBoldText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.description}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Badge
              dot
              text={invoice.status}
              color={INVOICE_STATUS_BADGE_COLOR[invoice.status]}
            />
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(invoice.total, payment?.currency)}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.created_at).format("DD MMM, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.due_date).format("DD MMM, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Button
              variant={ButtonVariants.secondary_color}
              size={ButtonSizes.sm}
              text="View Invoice"
              onClick={() => {
                navigate(`/payment/invoice-details/${invoice.id}`);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <InvoicesTableContainer>
      <Table>
        <TableCustomHeader height="20px">
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Invoice Number
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Description
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Status
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Value
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Issue Date
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Due Date
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TableFadedText>
          </TableCell>
        </TableCustomHeader>
        {renderRows()}
      </Table>
    </InvoicesTableContainer>
  );
};

export default InvoicesTab;
