import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const PayrollProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PayrollProcessHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  width: 100%;
  align-items: start;
  gap: ${Spaces.spacing_xs};
`;

export const PayrollProcessTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PayrollProcessSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const PayrollProcessContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Spaces.spacing_4xl};
`;

export const StepsContainer = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const StepTitleCard = styled.div`
  display: flex;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_3xl};
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
`;

export const StepTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
