import { PEOPLE_STATUS } from "../types/people";
interface contractDetails {
  status: string | null;
  employment_term: number;
  end_date: string;
}
export const contractExpiringDetails = (contract: contractDetails) => {
  if (contract.status !== PEOPLE_STATUS.active) {
    return;
  }
  
  const now = Date.now();
  const twoMonths = (2 * 30 + 10) * 24 * 60 * 60 * 1000;
  const oneMonth = (1 * 30 + 10) * 24 * 60 * 60 * 1000;
  
  if(contract.employment_term === 12 && (new Date(contract.end_date) <= new Date(now + twoMonths))) {
    return "2 Months";
  }
  else if(contract.employment_term === 6 && (new Date(contract.end_date) <= new Date(now + oneMonth))) {
    return "1 Month";
  }
  else if(contract.employment_term === 3) {
    return "3 Months"
  }
};