import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/reduxHooks";
import { selectLoggedIn } from "../redux/slices/authSlice";
import { HOME_SCREEN } from "../constants/routesNames";

const AuthRoutes = () => {
  const loggedIn = useAppSelector(selectLoggedIn);

  return !loggedIn ? <Outlet /> : <Navigate to={HOME_SCREEN} />;
};
export default AuthRoutes;
