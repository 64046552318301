import styled from "styled-components";
import { Spaces } from "../../../../constants/styles";
import Button from "../../../shared/Button";

export const AssetInfoStepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const AssetInfoStepButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const NextButton = styled(Button)`
  width: 48%;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;
