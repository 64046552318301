import styled from "styled-components";
import { Spaces, Radiuses, Colors } from "../../../constants/styles";

export const Button = styled.button<{
  disabled?: boolean;
}>`
  height: 44px;
  display: flex;
  justify-content: center;
  gap: ${Spaces.spacing_lg};
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
  background-color: ${Colors.bg_primary};
  color: ${Colors.text_secondary_700};

  &:hover {
    background-color: ${Colors.bg_primary_hover};
  }

  &:active {
    ${(props) => {
      return props.disabled
        ? ""
        : `box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14);`;
    }}
  }
`;
