import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Table } from "../../shared/Table/styled";

export const PaymentsTable = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  border-collapse: collapse;
  position: relative;
  scrollbar-width: none;
  overflow: hidden;
`;

export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;

