import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownListOption } from "../../types/componentsProps";
import { Company, CompanyInterface } from "../../types/companies";
import { sortDirection } from "../../types/apisTypes";
import { COMPANIES_STATUS_OPTIONS } from "../../constants/dropDownLists";

interface CompaniesState {
  createdCompanyId: string | null;
  userCompaniesOptions: DropdownListOption[];
  activeCompany: Company | null;
  currentInternalBankId: number;
  allCompanies: Company[];
  companies_table_variables: {
    search: string;
    selectedStatus: DropdownListOption;
    currentPage: number;
    totalRecords: number;
    sortKey: keyof CompanyInterface | "organization_name" | "user_name";
    sortDirection: sortDirection;
    filteredCompanies: CompanyInterface[] | null;
    itemsPerPage: number;
    totalPages: number;
  };
}

const initialState: CompaniesState = {
  createdCompanyId: null,
  userCompaniesOptions: [],
  activeCompany: null,
  currentInternalBankId: 1,
  allCompanies: [],
  companies_table_variables: {
    filteredCompanies: null,
    sortKey: "name",
    sortDirection: "ASC" as sortDirection,
    search: "",
    selectedStatus: COMPANIES_STATUS_OPTIONS[0],
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    itemsPerPage: 7
  }
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompaniesTableVariables(
      state,
      action: PayloadAction<Partial<CompaniesState["companies_table_variables"]>>
    ) {
      state.companies_table_variables = {
        ...state.companies_table_variables,
        ...action.payload,
      };
    },
    setCreatedCompanyId: (state, action: PayloadAction<string | null>) => {
      state.createdCompanyId = action.payload;
    },
    setUserCompaniesOptions: (
      state,
      action: PayloadAction<Company[]>
    ) => {
      const options = action.payload.map((company) => ({
        label: company.name,
        value: company.name,
      }));
      state.userCompaniesOptions = [{value: "All Companies", label: "All Companies"}, ...options];
      state.allCompanies = action.payload;
    },
    createCompaniesDropdownOptions: (
      state,
      action: PayloadAction<Company[]>
    ) => {

      const options=action.payload.map((company) => ({
        label: company.name,
        value: company.name,
      }));
      state.allCompanies = action.payload;
      state.userCompaniesOptions = options
      state.activeCompany = action.payload[0];
    },
    setActiveCompany: (state, action: PayloadAction<Company | null>) => {
      state.activeCompany = action.payload;
    },
    setInternalBankId: (state, action: PayloadAction<number>) => {
      state.currentInternalBankId = action.payload;
    },
  },
});

export const {
  setCreatedCompanyId,
  setUserCompaniesOptions,
  createCompaniesDropdownOptions,
  setInternalBankId,
  setActiveCompany,
  setCompaniesTableVariables
} = companiesSlice.actions;

export default companiesSlice.reducer;
