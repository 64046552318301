import styled from "styled-components";
import { Colors, Radiuses } from "../../../../constants/styles";
import { TableRowDynamic } from "../../../shared/Table/styled";
import Typography from "../../../shared/Typography";

export const ReviewInvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 13px;
`;
export const TableContainer = styled.div`
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  position: relative;
  scrollbar-width: none;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  flex: 1;
`;

export const TableCustomHeader = styled(TableRowDynamic)`
  background-color: ${Colors.bg_disabled_subtle};
`;
export const SecondaryHeader = styled(TableRowDynamic)`
  background-color: ${Colors.bg_disabled};
`;
export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
