import { FontSizes, FontWeights } from "../../../../constants/styles";
import {
  Container,
  Title,
  Subtitle,
  TitlesContainer,
  GoogleButton,
  DividerContainer,
  Divider,
  DividerText,
} from "./styled";

import UserRegistrationForm from "./UserRegistrationForm";

const UserRegistration = () => {
  return (
    <Container>
      <TitlesContainer>
        <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
          Sign up as an organization{" "}
        </Title>
        <Subtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Sign up with your Google business account or enter your details below.
        </Subtitle>
      </TitlesContainer>

      <GoogleButton social="google" text="Sign in with Google" />
      <DividerContainer>
        <Divider />
        <DividerText size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          or
        </DividerText>
        <Divider />
      </DividerContainer>
      <UserRegistrationForm />
    </Container>
  );
};

export default UserRegistration;
