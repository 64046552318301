import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/reduxHooks";
import { selectLoggedIn } from "../redux/slices/authSlice";
import { LOGIN_SCREEN } from "../constants/routesNames";

const PrivateRoutes = () => {
  const loggedIn = useAppSelector(selectLoggedIn);

  return loggedIn ? <Outlet /> : <Navigate to={LOGIN_SCREEN} />;
};
export default PrivateRoutes;
