import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo, SidePanelContainer } from "./styled";
import { CopyRightText } from "../../shared/CopyRight";
import { Colors } from "../../../constants/styles";
import EmailButton from "../../shared/EmailButton";
import OvarcLogoWhite from "../../../Images/logos/LogoWhite.svg";
import {
  RegistrationSteps,
  registrationSteps,
} from "../../../constants/registration";
import AccountTypeContent from "./AccountTypeContent";
import RegistrationStepper from "../RegistrationStepper";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { removeUser } from "../../../redux/slices/authSlice";
import { useLogout } from "../../../queries/auth";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { LOGIN_SCREEN } from "../../../constants/routesNames";
import { SnackbarTypes } from "../../../types/componentsProps";

const SidePanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { token } = useAppSelector((state) => state.auth);

  const { mutateAsync: logout } = useLogout();

  const handleLogout = async () => {
    try {
      if(token) {
        await logout();
      }
      queryClient.clear();
      dispatch(removeUser());
      navigate(LOGIN_SCREEN);
    } catch (error: any) {
      showSnackbar({
        open: true,
        title: "Logout error",
        description: error.response.message,
        type: SnackbarTypes.ERROR,
      });
    }
  }

  return (
    <SidePanelContainer>
      <Link to="/" style={{ width: "fit-content", height: "fit-content" }} onClick={() => handleLogout()}>
        <Logo src={OvarcLogoWhite} alt="ovarc-logo" />
      </Link>
      <AccountTypeContent />
      <CopyRightText style={{ color: Colors.text_white }} />
      <EmailButton />
    </SidePanelContainer>
  );
};

export default SidePanel;
