import styled from "styled-components";
import Typography from "../../components/shared/Typography";
import LoginScreenImg from "../../Images/loginImg.jpg";
import { Breakpoints, Colors, Spaces } from "../../constants/styles";

export const LoginContainer = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
`;

export const LoginInnerContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 162px;
  position: relative;
  @media (max-width: ${Breakpoints.tablet}) {
    width: 100%;
  }
  @media (max-height: ${Breakpoints.tablet}) {
    padding-top: 40px;
  }
`;

export const BGPattern = styled.img`
  position: fixed;
  top: 0;
  width: 768px;
  opacity: 0.3;
  z-index: -1;
  @media (max-height: ${Breakpoints.tablet}) {
    top: -120px;
  }
`;

export const DoNotHaveAccountTxt = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  color: ${Colors.text_secondary_700};
`;

export const LoginImg = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0px;
  background-image: url(${LoginScreenImg});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

export const ScreenTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_lg};
`;

export const ScreenSubTitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  margin-block: ${Spaces.spacing_none};
`;

export const SignUpBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spaces.spacing_xs};
  margin-block-start: ${Spaces.spacing_none};
`;
