import styled from "styled-components";
import { Colors, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
`;

export const ModalContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spaces.spacing_3xl};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_3xl};
  width: 100%;
`;

export const ModalFooter = styled.div`
  margin-top: ${Spaces.spacing_4xl};
  display: flex;
  bottom: ${Spaces.spacing_xl};
  justify-content: end;
  padding-block: ${Spaces.spacing_xl};
  border-top: 1px solid ${Colors.border_secondary};
  gap: ${Spaces.spacing_lg};
  background-color: ${Colors.bg_primary};
`;

export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ModalSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const ContentTitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  border-bottom: 1px solid ${Colors.border_secondary};
  margin-top: ${Spaces.spacing_2xl};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_2xl};
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: ${Spaces.spacing_4xl};
  width: 100%;
  justify-content: flex-start;
`;
