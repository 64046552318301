import { useState } from 'react'
import { Employee, PEOPLE_STATUS, PEOPLE_STATUS_BADGE_COLOR } from '../../../../../types/people'
import { ActionsCell, BadgesContainer, Divider, EmployeeTableContainer, NameCell, TextBold, TextFaded } from './styled';
import { TableCell, TableHeadWithIcon, TableRowDynamic } from '../../../../../components/shared/Table/styled';
import { FontSizes, FontWeights } from '../../../../../constants/styles';
import { Sort } from '../../../../../components/shared/SortIcon';
import InitialsProfilePic from '../../../../../components/shared/InitialsProfilePic';
import { contractExpiringDetails } from '../../../../../utilities/contractUtils';
import Badge from '../../../../../components/shared/Badge';
import { BadgeTypes, SnackbarTypes } from '../../../../../types/componentsProps';
import { useAppDispatch, useAppSelector } from '../../../../../redux/reduxHooks';
import ActionsDropDown from '../../../../../components/shared/ActionsDropDown';
import { setEmployeesTableVariables } from '../../../../../redux/slices/peopleSlice';
import TablePagination from '../../../../../components/shared/TablePagination';
import EditEmployeeModal from '../EditEmployeeModal';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const EmployeeTable = () => {
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const {
    sortKey,
    sortDirection,
    currentPage,
    filteredEmployees,
    totalPages
  } = useAppSelector((state) => state.people.employees_table_variables);

  const [ openModal, setOpenModal ] = useState(false);
  const [ selectedEmployee, setSelectedEmployee ] = useState<Employee | null>(null);

  const handleModal = (employee: Employee) => {
    if(employee.contract_status !== PEOPLE_STATUS.active) {
      setSelectedEmployee(employee);
      setOpenModal(true);
    } else {
      showSnackbar({
        open: true,
        type: SnackbarTypes.INFO,
        title: "Already Active",
        description: "Employee has already entered his data.",
      });
    }
  }

  const renderTableRows = () => {
    return filteredEmployees?.map((employee, index) => {
      const expiring = contractExpiringDetails({employment_term: employee.employment_term, status: employee.contract_status, end_date: employee.end_date});
      return (
        <>
          <TableRowDynamic height="40px">
            <TableCell>
              <NameCell>
                <InitialsProfilePic
                  fullName={`${employee.first_name} ${employee.last_name}`}
                />
                <div>
                  <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                    {employee.first_name} {employee.last_name}
                  </TextBold>
                  <TextFaded
                    size={FontSizes.TX_SM}
                    weight={FontWeights.REGULAR}
                  >
                    {employee.email}
                  </TextFaded>
                </div>
              </NameCell>
            </TableCell>

            <TableCell>
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {employee.company.name}
              </TextFaded>
            </TableCell>

            <TableCell>
              <BadgesContainer>
                {employee.contract_status === null ? (
                  <Badge
                    text={PEOPLE_STATUS.not_contracted}
                    color={
                      PEOPLE_STATUS_BADGE_COLOR[PEOPLE_STATUS.not_contracted]
                    }
                  />
                ) : (
                  <Badge
                    text={employee.contract_status}
                    color={PEOPLE_STATUS_BADGE_COLOR[employee.contract_status]}
                  />
                )}
                {expiring ? (
                  <Badge
                    text={`Contract expiring in ${expiring}`}
                    color={BadgeTypes.warning}
                  />
                ) : null}
              </BadgesContainer>
            </TableCell>

            <TableCell>
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {employee.contract_id ? employee.title : "--"}
              </TextFaded>
            </TableCell>            

            <TableCell>
              <ActionsCell>
                <ActionsDropDown
                  actions={[
                    {
                      text: "Change/Update Status",
                      onSelect: () => handleModal(employee),
                    },
                  ]}
                />
              </ActionsCell>
            </TableCell>
          </TableRowDynamic>
          {index !== filteredEmployees.length - 1 && <Divider />}
        </>
      );
    });
  };

  return (
    <EmployeeTableContainer>
      <TableRowDynamic height="20px">
        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortDirection: sortKey !== "name" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "name",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Name
            </TextFaded>
            <Sort
              sortKey={"name"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={() => {}}
            />
          </TableHeadWithIcon>
        </TableCell>

        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortDirection: sortKey !== "company" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "company",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Company
            </TextFaded>
            <Sort
              sortKey={"company"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={() => {}}
            />
          </TableHeadWithIcon>
        </TableCell>

        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortDirection: sortKey !== "contract_status" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "contract_status",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Status
            </TextFaded>
            <Sort
              sortKey={"contract_status"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={() => {}}
            />
          </TableHeadWithIcon>
        </TableCell>
        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortDirection: sortKey !== "title" ? "ASC" : ( sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "title",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Title
            </TextFaded>
            <Sort
              sortKey={"title"}
              currentSortKey={sortKey}
              sortDirection={sortDirection}
              onClick={() => {}}
            />
          </TableHeadWithIcon>
        </TableCell>
        
        <TableCell></TableCell>
      </TableRowDynamic>
      <Divider />
      {renderTableRows()}
      {totalPages > 1 && (
        <TableRowDynamic height="68px">
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={(page: number) =>
              dispatch(setEmployeesTableVariables({ currentPage: page }))
            }
            totalPages={totalPages}
          />
        </TableRowDynamic>
      )}
      <EditEmployeeModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        employee={selectedEmployee}
      />
    </EmployeeTableContainer>
  )
}

export default EmployeeTable