import { useNavigate, useParams } from "react-router-dom";
import {
  AssetDetailsCard,
  AssetDetailsCardHeader,
  AssetDetailsCardTitle,
  AssetDetailsCardsContainer,
  AssetDetailsHeader,
  AssetDetailsHeaderButtons,
  AssetDetailsHeaderRow,
  AssetDetailsSubtitle,
  AssetDetailsTitle,
  Arrow,
  AssigneeContainer,
  StatusesContainer,
  CardsContainer,
  CardsColumn,
  LogContainer,
  LogIconWrapper,
  LogsConnector,
  LogHeaderContainer,
  LogEmployeeName,
  LogTime,
  LogAction,
  LogData,
  LogSubject,
  LogRequestContainer,
  LogRequest,
  LogFileContainer,
  LogFileData,
  LogFileName,
  LogFileSize,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import ArrowRight from "../../../Images/icons/arrow-right-light.svg";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import { ProgressBar } from "../../shared/ProgressBar";
import moment from "moment";
import Badge from "../../shared/Badge";
import { ASSETS_LIST_STATUS_BADGE_COLOR } from "../../../types/assetsHub";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../shared/Card";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import PDF from "../../../Images/icons/pdf.svg";
import {
  useDeassignAsset,
  useGetAssetDetails,
  useAssignAsset,
} from "../../../queries/assetsHub";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useQueryClient } from "@tanstack/react-query";
import AssignAssetModal from "../AssignAssetModal";

const AssetDetails = () => {
  const { asset_id } = useParams();
  const [openAssignAssetModal, setOpenAssignAssetModal] = useState(false);
  const { data: assetDetails, error } = useGetAssetDetails(asset_id);
  const { mutateAsync: assignAsset } = useAssignAsset();
  const { mutateAsync: deassignAsset } = useDeassignAsset(asset_id);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching asset details.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  const deassign = async () => {
    try {
      await deassignAsset();
      queryClient.invalidateQueries({
        queryKey: ["asset-details", asset_id],
      });
      queryClient.invalidateQueries({
        queryKey: ["asset-hub-list"],
      });
      queryClient.invalidateQueries({
        queryKey: ["recently-added-assets"],
      });
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Asset Deassigned successfully",
        description: "The asset has been deassigned successfully.",
      });
    } catch (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong",
        description: "Please try again later.",
      });
    }
  };

  const assign = async (id: string) => {
    try {
      await assignAsset({
        asset_id,
        user_id: id,
      });
      queryClient.invalidateQueries({
        queryKey: ["asset-details", asset_id],
      });
      queryClient.invalidateQueries({
        queryKey: ["asset-hub-list"],
      });
      queryClient.invalidateQueries({
        queryKey: ["recently-added-assets"],
      });
      setOpenAssignAssetModal(false);
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Asset Assigned successfully",
        description: "The asset has been assigned successfully.",
      });
    } catch (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong",
        description: "Please try again later.",
      });
    }
  };
  const renderCardsRow = () => {
    return (
      <AssetDetailsCardsContainer>
        <AssetDetailsCard>
          <AssetDetailsCardHeader>
            <AssetDetailsCardTitle
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Assigned to
            </AssetDetailsCardTitle>
            {assetDetails?.data?.assignee && (
              <Arrow
                src={ArrowRight}
                alt="arrow right"
                onClick={() => {
                  navigate(
                    `/people/profile/${assetDetails.data?.assignee?.id}`
                  );
                }}
              />
            )}
          </AssetDetailsCardHeader>
          {assetDetails?.data?.assignee ? (
            <AssigneeContainer>
              <InitialsProfilePic fullName={assetDetails.data?.assignee.name} />
              <div>
                <AssetDetailsTitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  {assetDetails.data?.assignee.name}
                </AssetDetailsTitle>
                <AssetDetailsSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  {assetDetails.data?.assignee.position}
                </AssetDetailsSubtitle>
              </div>
            </AssigneeContainer>
          ) : (
            <Button
              variant={ButtonVariants.primary}
              text="Assign"
              size={ButtonSizes.md}
              style={{ marginTop: "0.5rem" }}
              onClick={() => setOpenAssignAssetModal(true)}
            />
          )}
        </AssetDetailsCard>
        <AssetDetailsCard>
          <AssetDetailsCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.MEDIUM}
            style={{ marginBottom: "0.5rem" }}
          >
            Condition
          </AssetDetailsCardTitle>
          <ProgressBar
            condition={assetDetails?.data?.condition.percentage || ""}
            subtext={`Last Reviewed ${moment(
              assetDetails?.data?.condition.last_reviewed
            ).format("DD-MM-YYYY")}`}
          />
        </AssetDetailsCard>
        <AssetDetailsCard>
          <AssetDetailsCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.MEDIUM}
          >
            Status
          </AssetDetailsCardTitle>
          <StatusesContainer>
            {assetDetails?.data?.status.map((s, index) => {
              return (
                <Badge
                  dot
                  key={index}
                  text={s}
                  color={ASSETS_LIST_STATUS_BADGE_COLOR[s]}
                />
              );
            })}
          </StatusesContainer>
        </AssetDetailsCard>
      </AssetDetailsCardsContainer>
    );
  };
  const renderWarrantyCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Warranty and Supplier Information
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Warranty Period
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.warranty_and_supplier.warranty_period}
          </CardRowValue>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Supplier Name
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.warranty_and_supplier.supplier}
          </CardRowValue>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Supplier Contact Information
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.warranty_and_supplier.supplier_contact}
          </CardRowValue>
        </CardRow>
      </Card>
    );
  };
  const renderMaintenanceCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Maintenance and Service Records
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Maintenance Schedule
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.maintenance_and_service.maintenance_schedule}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Last Service on
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.maintenance_and_service.last_service}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Service Provider
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.maintenance_and_service.service_provider}
          </CardRowValue>
        </CardRow>
      </Card>
    );
  };

  const renderDisposalCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Disposal Information
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Disposal Date
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(
              assetDetails?.data?.disposal_information.disposal_date
            ).format("DD-MM-YYYY")}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Method of Disposal
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.disposal_information.method_of_disposal}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Reason of Disposal
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.disposal_information.disposal_reason}
          </CardRowValue>
        </CardRow>
      </Card>
    );
  };

  const renderFinancialCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Financial Information
        </CardTitle>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Purchase Date
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(assetDetails?.data?.financial.purchase_date).format(
              "DD-MM-YYYY"
            )}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Purchase Price
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {currencyFormatter(
              assetDetails?.data?.financial.purchase_price,
              assetDetails?.data?.financial.currency
            )}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Depreciation Method
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {assetDetails?.data?.financial.depreciation_method}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Current Value
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {currencyFormatter(
              assetDetails?.data?.financial.current_value,
              assetDetails?.data?.financial.currency
            )}
          </CardRowValue>
        </CardRow>
      </Card>
    );
  };

  const renderLogCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Assets Log
        </CardTitle>
        {assetDetails?.data?.asset_log.map((log, index) => {
          return (
            <LogContainer>
              <LogIconWrapper>
                <InitialsProfilePic fullName={log.employee_name} />
                <LogsConnector
                  isLast={index === assetDetails.data?.asset_log.length - 1}
                />
              </LogIconWrapper>
              <LogData>
                <LogHeaderContainer>
                  <LogEmployeeName
                    size={FontSizes.TX_SM}
                    weight={FontWeights.MEDIUM}
                  >
                    {log.employee_name}
                  </LogEmployeeName>
                  <LogTime size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                    {moment(log.date).format("DD MMM , YYYY")}
                  </LogTime>
                </LogHeaderContainer>
                <LogAction size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                  {log.action.type}{" "}
                  {log.action.type === "Assigned"
                    ? assetDetails.data?.name + " To "
                    : ""}{" "}
                  <LogSubject>
                    {log.action.type === "Purchased"
                      ? assetDetails.data?.name
                      : log.action.subject}
                  </LogSubject>
                </LogAction>
                {
                  //@ts-ignore
                  log.action.message && (
                    <LogRequestContainer>
                      <LogRequest
                        size={FontSizes.TX_SM}
                        weight={FontWeights.REGULAR}
                      >
                        {/* @ts-ignore */}
                        {log.action.message}”
                      </LogRequest>
                    </LogRequestContainer>
                  )
                }
                {log.action.file && (
                  <LogFileContainer>
                    <a href={log.action.file.url} download target="_blank">
                      <img src={PDF} alt="pdf" width={32} height={40} />
                    </a>
                    <LogFileData>
                      <LogFileName
                        size={FontSizes.TX_SM}
                        weight={FontWeights.MEDIUM}
                      >
                        {log.action.file.name}
                      </LogFileName>
                      <LogFileSize
                        size={FontSizes.TX_SM}
                        weight={FontWeights.REGULAR}
                      >
                        {log.action.file.size}
                      </LogFileSize>
                    </LogFileData>
                  </LogFileContainer>
                )}
              </LogData>
            </LogContainer>
          );
        })}
      </Card>
    );
  };
  return assetDetails ? (
    <>
      <AssetDetailsHeader>
        <AssetDetailsHeaderRow>
          <div>
            <AssetDetailsTitle
              size={FontSizes.SM}
              weight={FontWeights.SEMIBOLD}
            >
              {assetDetails.data?.name}
            </AssetDetailsTitle>
            <AssetDetailsSubtitle
              size={FontSizes.TX_MD}
              weight={FontWeights.REGULAR}
            >
              {assetDetails.data?.type} - {assetDetails.data?.brand}
            </AssetDetailsSubtitle>
          </div>
          <AssetDetailsHeaderButtons>
            <Button
              variant={ButtonVariants.secondary_gray}
              text="Request Maintenance"
              size={ButtonSizes.md}
              disabled
            />
            <Button
              variant={
                assetDetails.data?.assignee
                  ? ButtonVariants.destructive_primary
                  : ButtonVariants.primary
              }
              text={assetDetails.data?.assignee ? "Deassign" : "Assign"}
              size={ButtonSizes.md}
              onClick={() => {
                assetDetails.data?.assignee
                  ? deassign()
                  : setOpenAssignAssetModal(true);
              }}
            />
          </AssetDetailsHeaderButtons>
        </AssetDetailsHeaderRow>
      </AssetDetailsHeader>
      {renderCardsRow()}
      <CardsContainer>
        <CardsColumn>
          {renderWarrantyCard()} {renderMaintenanceCard()}{" "}
          {renderDisposalCard()}
        </CardsColumn>
        <CardsColumn>
          {renderFinancialCard()} {renderLogCard()}
        </CardsColumn>
        <AssignAssetModal
          open={openAssignAssetModal}
          onClose={() => setOpenAssignAssetModal(false)}
          onSubmit={assign}
        />
      </CardsContainer>
    </>
  ) : null;
};

export default AssetDetails;
