import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import { Link } from "react-router-dom";

export const BreadcrumbsContainer = styled.div`
  background-color: ${Colors.bg_active};
  padding-block: ${Spaces.spacing_4xl};
  padding-inline: ${Spaces.spacing_xl};
  display: flex;
  margin-inline-end: ${Spaces.spacing_md};
`;
export const BreadcrumbItem = styled(Link)<{ isLast: boolean }>`
  color: ${(props) =>
    props.isLast ? Colors.breadcrumb_fg_hover : Colors.breadcrumb_fg};
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: ${Spaces.spacing_md};
`;

export const BreadcrumbText = styled.div<{ isLast: boolean }>`
  color: ${(props) =>
    props.isLast ? Colors.breadcrumb_fg_hover : Colors.breadcrumb_fg};
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: ${Spaces.spacing_md};
`;

export const InactiveBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: ${Spaces.spacing_md};
  color: ${Colors.breadcrumb_fg};
`;
export const Chevron = styled.img`
  margin-inline-end: ${Spaces.spacing_md};
`;
