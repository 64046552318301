import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import qs from "qs";
import {
  AddAssetParams,
  AddAssetResponse,
  GetAssetDetails,
  GetAssetsListParams,
  GetAssetsListResponse,
  GetAssetsOverview,
  GetRecentlyAddedAssetsListParams,
  GetRecentlyAddedAssetsListResponse,
  RequestAssetsParams,
} from "../types/apisTypes";

export const useGetAssetHubList = (params: GetAssetsListParams) => {
  return useQuery<GetAssetsListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/assets?${qs.stringify(params)}`);
      return response.data;
    },
    retry: false,

    queryKey: ["asset-hub-list", params],
  });
};

export const useGetRecentlyAddedAssetsList = (
  params: GetRecentlyAddedAssetsListParams
) => {
  return useQuery<GetRecentlyAddedAssetsListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/assets/recently-added?${qs.stringify(params)}`
      );
      return response.data;
    },
    retry: false,

    queryKey: ["recently-added-assets", params],
  });
};

export const useGetAssetsOverview = () => {
  return useQuery<GetAssetsOverview, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/assets/overview`);
      return response.data;
    },
    retry: false,

    queryKey: ["assets-overview"],
  });
};

export const useGetAssetDetails = (assetId: string | undefined) => {
  return useQuery<GetAssetDetails, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/assets/${assetId}`);
      return response.data;
    },
    retry: false,
    enabled: !!assetId,

    queryKey: ["asset-details", assetId],
  });
};

export const useRequestAssets = (params: RequestAssetsParams) => {
  return useMutation<void, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/assets", params);
      return response.data;
    },
  });
};

export const useDeassignAsset = (asset_id: string | undefined) => {
  return useMutation<void, Error>({
    mutationFn: async () => {
      const response = await apiClient.delete(`/assets/${asset_id}/deassign`);
      return response.data;
    },
  });
};

export const useAssignAsset = () => {
  return useMutation<
    void,
    Error,
    {
      asset_id: string | undefined;
      user_id: string | undefined;
    }
  >({
    mutationFn: async ({ asset_id, user_id }) => {
      const response = await apiClient.post(`/assets/${asset_id}/assign`, {
        user_id,
      });
      return response.data;
    },
  });
};

export const useAddAsset = (params: AddAssetParams) => {
  return useMutation<AddAssetResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/assets/new", params);
      return response.data;
    },
  });
};
