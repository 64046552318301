import React from "react";
import { InputContainer, InputLabel, Input, ExtraMessage } from "./styled";
import { InputFieldProps } from "../../../types/componentsProps";

const InputField: React.FC<InputFieldProps> = ({
  label,
  hint,
  error,
  containerStyle,
  extraMessageStyle,
  ...props
}) => {
  return (
    <InputContainer style={containerStyle}>
      {label && <InputLabel text={label} />}
      <Input {...props} error={error} hint={hint} />
      <ExtraMessage style={extraMessageStyle}>
        {" "}
        {error?.length ? error : hint}
      </ExtraMessage>
    </InputContainer>
  );
};

export default InputField;
