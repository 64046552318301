import styled from "styled-components";
import Typography from "../../shared/Typography";
import {
  Breakpoints,
  Colors,
  Radiuses,
  Spaces,
} from "../../../constants/styles";
import Button from "../../shared/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  max-width: 30%;
  
  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding-inline: ${Spaces.spacing_4xl};
    width: 100%;
  }
`;

export const TitlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block-end: ${Spaces.spacing_xl};
  gap: ${Spaces.spacing_xs};
`;

export const Title = styled(Typography)`
  color: ${Colors.text_brand_primary};
  margin-block: ${Spaces.spacing_none};
`;

export const Subtitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-block: ${Spaces.spacing_none};
`;

export const ResendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_xs};
  margin-block-start: ${Spaces.spacing_none};
`;

export const PasswordInformation = styled.div`
  margin-block: ${Spaces.spacing_sm};
  width: 100%;
`;

export const RequirementItem = styled(Typography)`
  display: flex;
  gap: ${Spaces.spacing_sm};
  margin-block-end: ${Spaces.spacing_sm};
  color: ${Colors.text_tertiary_600};
  align-items: center;
`;