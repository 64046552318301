import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Table } from "../../shared/Table/styled";

export const Container = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
  background-color: ${Colors.bg_primary};
  margin-bottom: 13px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
`;
export const TableHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_md};
  padding-block: ${Spaces.spacing_3xl};
  padding-inline: ${Spaces.spacing_2xl};
`;

export const TableHeaderTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PayrollTableContainer = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  position: relative;
`;

export const TextFaded = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const Text = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const NameCellContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  align-items: center;
`;
