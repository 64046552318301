import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../queries/apiClient";
import qs from "qs";
import { ConfirmPaymentParams, GetPaymentListParams, GetPaymentListResponse } from "../../types/apisTypes";
import { InvoiceInterface, PaymentInterface } from "../../types/payments";

export const useConfirmPaymentAdmin = () => {
   return useMutation<void, Error, ConfirmPaymentParams>({
      mutationFn: async (params) => {
         const response = await apiClient.post(`/admin/confirm_payment/${params.id}`, params);
         return response.data;
      },
   });
}

export const useGetInvoiceDetailsAdmin = (invoiceId: string | undefined) => {
   return useQuery<InvoiceInterface, Error>({
      queryFn: async () => {
         const response = await apiClient.get(`/admin/invoice_details/${invoiceId}`);
         return response.data.invoice;
      },
      retry: false,
      enabled: !!invoiceId,
      queryKey: ["invoices", invoiceId],
   });
};

export const useGetPaymentListAdmin = (params: GetPaymentListParams) => {
   return useQuery<GetPaymentListResponse, Error>({
      queryFn: async () => {
         const response = await apiClient.get(
         `/admin/payments?${qs.stringify({
            ...params,
         })}`
         );
         return response.data;
      },
      queryKey: ["paymentsList", params],
   });
};
 
export const useGetPaymentAdmin = (paymentId: string | undefined) => {
   return useQuery<PaymentInterface, Error>({
      queryFn: async () => {
         const response = await apiClient.get(
         `/admin/payment/${paymentId}`
         );
         return response.data.payment;
      },
      enabled: !!paymentId,
      queryKey: ["paymentsItemAdmin"],
   });
};
 