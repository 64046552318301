import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { PeopleContainer } from "./styled";
import { MANAGE_SCREEN } from "../../constants/routesNames";

const PeopleScreen: React.FC = () => {
  const location = useLocation();

  return (
    <PeopleContainer>
      {location.pathname.split("/").pop() === "people" ? (
        <Navigate to={MANAGE_SCREEN} />
      ) : (
        <Outlet />
      )}
    </PeopleContainer>
  );
};

export default PeopleScreen;
