import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { Card } from "../../../shared/Card";

export const PaymentMethodStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
export const StepTitleCard = styled.div`
  display: flex;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_3xl};
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
`;

export const StepTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PaymentMethodCard = styled(Card)`
  margin-block: ${Spaces.spacing_md};
  gap: ${Spaces.spacing_2xl};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OptionContainer = styled.div<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_lg};
  border-radius: ${Radiuses.radius_xl};
  border: ${(props) =>
    !props.isDisabled
      ? `2px solid ${Colors.bg_brand_solid}`
      : `1px solid ${Colors.border_disabled}`};
  background-color: ${(props) =>
    !props.isDisabled ? Colors.bg_primary : Colors.bg_disabled_subtle};
  padding: ${Spaces.spacing_xl};
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_tertiary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Title = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;
export const TitleContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-wrap: wrap;
  gap: 2px;
`;

export const Subtext = styled(Typography)`
  color: ${Colors.gray_dark_mode_400};
`;
