import { ButtonSizes, ButtonVariants } from "../../../constants/styles";
import LogOut from "../../../Images/icons/log-out.svg";
import { LogoutBtn } from "./styled";
import { useLogout } from "../../../queries/auth";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { removeUser } from "../../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { LOGIN_SCREEN } from "../../../constants/routesNames";

const LogoutButton = ({ style }: { style?: React.CSSProperties }) => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: logout } = useLogout();
  
  const onClick = async () => {
    try {
      await logout();
      queryClient.clear();
      dispatch(removeUser());
      navigate(LOGIN_SCREEN);
    } catch (error: any) {
      showSnackbar({
        open: true,
        title: "Logout error",
        description: error.response.message,
        type: SnackbarTypes.ERROR,
      });
    }
  };

  return (
    <LogoutBtn
      variant={ButtonVariants.secondary_gray}
      size={ButtonSizes.lg}
      text="Logout"
      iconLeading={<img src={LogOut} alt="logout" width={20} height={20} />}
      onClick={onClick}
      style={style}
    />
  );
};

export default LogoutButton;
