import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const ContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-block: ${Spaces.spacing_3xl};
`;

export const Banner = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  padding: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_warning_primary};
  border: 1px solid ${Colors.fg_warning_secondary};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: ${Spaces.spacing_xl};
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
`;
export const BannerDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;

export const ContractName = styled(Typography)`
  color: ${Colors.text_secondary_hover};
`;

export const Signature = styled.p`
  font-family: Italianno;
  font-size: 30;
  font-weight: 400;
`;

export const ContractFileContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  padding: ${Spaces.spacing_xl};
  margin-bottom: ${Spaces.spacing_xl};
`;
export const DownloadButton = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.bg_brand_secondary};
  border-radius: ${Radiuses.radius_full};
  margin-left: auto;
`;
export const ContractFileName = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-left: ${Spaces.spacing_xl};
`;

export const CancelButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 7px;
  border-radius: ${Radiuses.radius_xs};
  background-color: ${Colors.bg_error_secondary};
  color: ${Colors.text_error_primary};
`;
