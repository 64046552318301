import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { CardRow, CardRowValuesContainer } from "../../shared/Card";

export const PayrollReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PayrollReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  width: 100%;
  align-items: start;
`;
export const PayrollReviewHeaderTitleContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
`;
export const PayrollReviewTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PayrollReviewSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const PayrollReviewHeadersButtonsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const PayrollReviewCardRow = styled(CardRow)`
  width: 40%;
  gap: ${Spaces.spacing_md};
`;
export const PayrollReviewCardRowValuesContainer = styled(
  CardRowValuesContainer
)`
  gap: unset;
`;

export const SearchContainer = styled.div`
  display: flex;
  padding: 10px ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_primary};
  background-color: ${Colors.bg_primary};
  margin-bottom: 13px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 23px;
`;

export const PayrollReviewTableContainer = styled.div`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  position: relative;
`;
export const PayrollReviewTableHeader = styled.th`
  background-color: ${Colors.bg_secondary};
  border-color: ${Colors.bg_secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: ${Radiuses.radius_xl};
  border-top-left-radius: ${Radiuses.radius_xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  padding-inline: ${Spaces.spacing_3xl};
  height: 42px;
`;
export const TableHeaderText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const Divider = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${Colors.border_secondary};
`;

export const DownloadButton = styled.a<{
  disabled?: boolean;
}>`
  color: ${({ disabled }) =>
    disabled ? Colors.text_disabled : Colors.text_quarterary_on_brand};
  font-size: ${FontSizes.TX_XS};
  font-weight: ${FontWeights.SEMIBOLD};
  text-decoration: none;
  text-align: right;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const NameCell = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};

  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;
export const EmailCell = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
export const NameCellText = styled(Typography)`
  color: ${Colors.text_quarterary_on_brand};
`;
export const CellSubtext = styled(Typography)`
  color: ${Colors.fg_disabled};
  text-align: right;
`;
export const CellText = styled(Typography)`
  color: ${Colors.text_primary};
  text-align: right;
`;
