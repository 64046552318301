import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const MultipleActionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: ${Spaces.spacing_md} ${Spaces.spacing_lg};
  padding-inline: ${Spaces.spacing_3xl};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  gap: ${Spaces.spacing_md};
`;

export const CardTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-block: ${Spaces.spacing_xl};
`;

export const ActionContainer = styled.div`
  display: flex;
  padding: ${Spaces.spacing_lg} ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.bg_primary_hover};
  background-color: ${Colors.bg_primary_hover};
  justify-content: space-between;
  align-items: center;
`;
export const ActionTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;
