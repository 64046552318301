import { useNavigate } from "react-router-dom";
import { Table, TableCell, TableRow } from "../../../../../components/shared/Table/styled";
import { InvoicesTableContainer, TableBoldText, TableCustomHeader, TableFadedText } from "./styled";
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from "../../../../../constants/styles";
import Badge from "../../../../../components/shared/Badge";
import { INVOICE_STATUS_BADGE_COLOR } from "../../../../../types/payments";
import { currencyFormatter } from "../../../../../utilities/currencyFormatter";
import moment from "moment";
import Button from "../../../../../components/shared/Button";
import { useAppSelector } from "../../../../../redux/reduxHooks";

const InvoicesTab = () => {
  const navigate = useNavigate();
  const { selected_payment } = useAppSelector((state) => state.payment)
 
   const renderRows = () => {
     return selected_payment?.invoices.map((invoice) => {
       return (
         <TableRow>
           <TableCell>
             <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
               {invoice.public_id}
             </TableBoldText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
               {invoice.description}
             </TableFadedText>
           </TableCell>
           <TableCell>
             <Badge
               dot
               text={invoice.status}
               color={INVOICE_STATUS_BADGE_COLOR[invoice.status]}
             />
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
               {currencyFormatter(invoice.total, selected_payment?.currency)}
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
               {moment(invoice.created_at).format("DD MMM, YYYY")}
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
               {moment(invoice.due_date).format("DD MMM, YYYY")}
             </TableFadedText>
           </TableCell>
           <TableCell>
            <Button
              variant={ButtonVariants.secondary_color}
              size={ButtonSizes.sm}
              text="View Details"
              onClick={() => {
                navigate(`/payment/invoice-details/${invoice.id}`);
              }}
            />
          </TableCell>
         </TableRow>
       );
     });
   };
 
   return (
     <InvoicesTableContainer>
       <Table>
         <TableCustomHeader height="20px">
           <TableCell>
             <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
               Invoice Number
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
               Description
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
               Status
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
               Value
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
               Issue Date
             </TableFadedText>
           </TableCell>
           <TableCell>
             <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
               Due Date
             </TableFadedText>
           </TableCell>
           <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TableFadedText>
          </TableCell>
         </TableCustomHeader>
         {renderRows()}
       </Table>
     </InvoicesTableContainer>
   );
 };
 
 export default InvoicesTab;
 