import React from "react";
import { StyledButton } from "./styled";
import { ButtonProps } from "../../../types/componentsProps";
import Loader from "../Loader";
import {
  ButtonVariants,
  LoaderSizes,
  LoaderVariants,
} from "../../../constants/styles";

const Button: React.FC<ButtonProps> = ({
  iconLeading,
  iconTrailing,
  text,
  loading,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === "Enter") {
          e.currentTarget.click();
        }
      }}
    >
      {loading ? (
        <Loader
          size={LoaderSizes.sm}
          variant={
            props.variant === ButtonVariants.primary ||
            props.variant === ButtonVariants.destructive_primary ||
            props.variant === ButtonVariants.destructive_secondary
              ? LoaderVariants.secondary
              : LoaderVariants.primary
          }
        />
      ) : (
        <>
          {iconLeading}
          {text}
          {iconTrailing}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
