import { useEffect, useState } from "react";
import {
  ExtraMessage,
  FormContainer,
  FormSubtitle,
  FormTitle,
  JobScopeContainer,
  JobScopeInput,
  JobScopeInputInfo,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import DropdownList from "../../../shared/DropdownList";
import { FormFields } from "../../NewEmployee/styled";
import Label from "../../../shared/Label";
import Button from "../../../shared/Button";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import {
  setDepartmentOptions,
  setJobTitleOptions,
  updateNewContractData,
} from "../../../../redux/slices/peopleSlice";
import { useGetJobHierarchy } from "../../../../queries/people";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../../types/componentsProps";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const JobDescriptionForm = ({ onNext }: { onNext: () => void }) => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const [activeJobOptions, setActiveJobOptions] = useState<
    { label: string; value: string; description: string; category: string }[]
  >([]);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    executive_level: { label: "", value: "" },
    job_title: { label: "", value: "", description: "", category: "" },
    job_scope: " ",
  });

  const { departmentOptions, jobTitleOptions } = useAppSelector(
    (state) => state.people
  );

  const { data: jobs, error: jobsError } = useGetJobHierarchy();

  const getDepartmentOptions = () => {
    let departments: DropdownListOption[] = [];
    let jobTitles: {
      label: string;
      value: string;
      description: string;
      category: string;
    }[] = [];

    jobs?.records.map((job) => {
      if (departments.findIndex((dep) => dep.value === job.category) === -1) {
        departments.push({ label: job.category, value: job.category });
      }
      jobTitles.push({
        label: job.role,
        value: job.role,
        description: job.description,
        category: job.category,
      });
    });

    dispatch(setDepartmentOptions(departments));
    dispatch(setJobTitleOptions(jobTitles));
  };

  useEffect(() => {
    if (jobTitleOptions.length) {
      setFormData({
        ...formData,
        job_title: { label: "", value: "", description: "", category: "" },
        job_scope: "",
      });
      setActiveJobOptions(
        jobTitleOptions.filter(
          (job) => job.category === formData.executive_level.value
        )
      );
    }
  }, [formData.executive_level]);

  useEffect(() => {
    if (formData.job_title) {
      setFormData({
        ...formData,
        job_scope: formData.job_title.description,
      });
    }
  }, [formData.job_title]);

  useEffect(() => {
    if (jobs) {
      getDepartmentOptions();
    }
  }, [jobs]);

  useEffect(() => {
    if (jobsError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Error while fetching job titles, please try again later",
      });
    }
  }, [jobsError]);

  const setDataPoint = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const validateForm = () => {
    if (formData.job_scope.length < 100) {
      setError(true);
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validateForm()) {
      dispatch(
        updateNewContractData({
          executive_level: formData.executive_level.label,
          job_title: formData.job_title.label,
          job_scope: formData.job_scope,
        })
      );
      onNext();
    }
  };

  return (
    <FormContainer>
      <FormTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
        Job Details
      </FormTitle>
      <FormSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
        Clearly outline the employee’s responsibilities and duties within{"\n"}
        the contract.
      </FormSubtitle>
      <FormFields>
        <DropdownList
          label="Department"
          options={departmentOptions}
          selectedOption={formData.executive_level}
          setSelectedOption={(option) =>
            setDataPoint("executive_level", option)
          }
        />
        <DropdownList
          label="Job title"
          options={activeJobOptions}
          selectedOption={formData.job_title}
          setSelectedOption={(option) => setDataPoint("job_title", option)}
        />
        <JobScopeContainer>
          <FormTitle size={FontSizes.TX_LG} weight={FontWeights.BOLD}>
            Job Details
          </FormTitle>
          <FormSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            This information will form the basis of the employment agreement. To
            {"\n"} avoid delays to onboarding and start dates, please ensure the
            following applies.
          </FormSubtitle>
          <Label text="Explanation of job scope" />
          <JobScopeInput
            maxLength={10000}
            minLength={100}
            value={formData.job_scope}
            onChange={(e) => setDataPoint("job_scope", e.target.value)}
            error={error}
            onFocus={() => setError(false)}
          />
          <JobScopeInputInfo>
            <ExtraMessage>Min scope length 100</ExtraMessage>
            <ExtraMessage>{`${formData.job_scope.length}/10000`} </ExtraMessage>
          </JobScopeInputInfo>
        </JobScopeContainer>
        <Button
          text="Next"
          onClick={onSubmit}
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{
            width: "50%",
            marginLeft: "auto",
          }}
        />
      </FormFields>
    </FormContainer>
  );
};

export default JobDescriptionForm;
