import styled from "styled-components";
import Typography from "../../../shared/Typography";
import { Colors, Spaces } from "../../../../constants/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex: 1;
  margin-bottom: 96px;
`;

export const Title = styled(Typography)`
  color: ${Colors.text_white};
  margin-block: ${Spaces.spacing_none};
`;

export const Subtext = styled(Typography)`
  color: ${Colors.text_white};
  margin-block: ${Spaces.spacing_none};
`;
