import { InputFieldProps } from "../../../types/componentsProps";
import { Input, InputContainer } from "./styled";
import Search from "../../../Images/icons/search.svg";

const SearchInput = ({ containerStyle, ...props }: InputFieldProps) => {
  return (
    <InputContainer style={containerStyle}>
      <img src={Search} alt="Search" />
      <Input {...props} />
    </InputContainer>
  );
};

export default SearchInput;
