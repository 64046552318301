export const downloadFile = (file: ArrayBuffer, fileName: string, fileType: string) => {
  const blob = new Blob([file], {
    type: fileType,
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const printPDF = (file: ArrayBuffer) => {
  const blob = new Blob([file], {
    type: "application/pdf",
  });
  const url = URL.createObjectURL(blob);
  const newWindow = window.open(url, undefined, "width=600,height=800");
  newWindow?.print();
  URL.revokeObjectURL(url);
};
