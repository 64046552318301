import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const AssetsListContainer = styled.div`
  width: 100%;
`;

export const AssetsListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Spaces.spacing_lg};
`;

export const AssetsListTableContainer = styled.div`
  border-radius: ${Radiuses.radius_xl};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  position: relative;
`;

export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;
