import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const PaymentAlertContainer = styled.div`
  display: flex;

  padding: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.fg_warning_secondary};
  background-color: ${Colors.bg_warning_primary};
  margin-bottom: ${Spaces.spacing_sm};
  gap: ${Spaces.spacing_xl};
`;

export const PaymentAlertContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentAlertTitle = styled(Typography)`
  color: ${Colors.text_warning_primary_600};
`;

export const PaymentAlertDescription = styled(Typography)`
color: ${Colors.text_tertiary_hover};
margin-bottom: ${Spaces.spacing_lg};

`