import styled from "styled-components";
import Typography from "../../../shared/Typography";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../../constants/styles";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled(Typography)`
  color: ${Colors.text_brand_primary};
`;
export const FormSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre-wrap;
  margin-bottom: ${Spaces.spacing_xl};
  margin-top: ${Spaces.spacing_xs};
`;
export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const JobScopeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const JobScopeInput = styled.textarea<{
  disabled?: boolean;
  error?: boolean;
}>`
  font-family: inter;
  padding: 10px 14px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Radiuses.radius_md};
  font-size: "1rem";
  font-weight: 400;
  color: ${Colors.text_primary};
  text-overflow: ellipsis;
  margin-top: ${Spaces.spacing_sm};
  height: 82px;

  ::placeholder {
    color: ${Colors.text_placeholder};
  }

  &:focus {
    outline: none;

    ${(props) =>
      !props.disabled &&
      !props.error &&
      `
  border: 1px solid ${Colors.border_brand};

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(158, 119, 237, 0.24);
`};

    ${(props) =>
      !props.disabled &&
      props.error &&
      `
  border: 1px solid ${Colors.border_error};

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);
`};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${Colors.bg_disabled_subtle};
  border: 1px solid ${Colors.border_disabled};
  color: ${Colors.text_disabled};
`};

  ${(props) =>
    props.error &&
    `
  border: 1px solid ${Colors.border_error};
  ~ ${JobScopeInputInfo} {

    color: ${Colors.text_error_primary};
  }
`};
`;

export const ExtraMessage = styled.p`
  display: block;
  margin-block-end: ${Spaces.spacing_none};
  margin-block-start: ${Spaces.spacing_sm};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.REGULAR};
`;

export const JobScopeInputInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${Colors.text_tertiary_600};
`;
