import { DASHBOARD_PAYMENTS_STATUS } from "../../types";

export const PAYMENTS = [
  {
    type: "Bank Transfer",
    status: DASHBOARD_PAYMENTS_STATUS.WaitingForPaymentToArrive,
    date: "18 Nov. 2023",
    amount: "33000",
  },
  {
    type: "Bank Transfer",
    status: DASHBOARD_PAYMENTS_STATUS.Processing,
    date: "18 Nov. 2023",
    amount: "351320",
  },
  {
    type: "Bank Transfer",
    status: DASHBOARD_PAYMENTS_STATUS.Processing,
    date: "14 Nov. 2023",
    amount: "23850",
  },
];

export const AssetsCard = [
  {
    name: "In-use",
    value: 50,
  },
  {
    name: "Inactive",
    value: 1,
  },
  {
    name: "Decommissioned",
    value: 1,
  },
];

export const AssetsCardColors = ["#17B26A", "#FDB022", "#98A2B3"];

export const PeopleCard = [
  {
    name: "EOR",
    value: 60,
  },
  {
    name: "Direct Employees",
    value: 40,
  },
  {
    name: "Contractors",
    value: 30,
  },
];

export const PeopleCardColors = ["#033B6E", "#B3B8DB", "#59ACFB"];
