import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../../constants/styles";
import Typography from "../../../shared/Typography";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 680px;
  flex-wrap: wrap;
  box-sizing: border-box;
  overflow-y: hidden;
  padding-inline: ${Spaces.spacing_none};
`;

export const ModalHeader = styled.div`
  padding: ${Spaces.spacing_3xl};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ModalTitleAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_xl};
`;
export const DataContainer = styled.div`
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${Spaces.spacing_6xl};
  padding-bottom: ${Spaces.spacing_7xl};
  padding-inline: ${Spaces.spacing_3xl};
  gap: ${Spaces.spacing_sm};
  border-bottom: 1px solid ${Colors.border_secondary};
  border-top: 1px solid ${Colors.border_secondary};
  min-width: 300px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_3xl};
  justify-content: end;
`;

export const CompanyDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block: ${Spaces.spacing_lg};
  padding-inline: ${Spaces.spacing_xl};
  border-radius: ${Spaces.spacing_md};
  background-color: ${Colors.bg_primary_hover};
`;

export const CompanyDetailsLabel = styled(Typography)`
  color: ${Colors.text_secondary_700};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.SEMIBOLD};
`;
export const CompanyDetailsValue = styled(Typography)`
  color: ${Colors.text_primary};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.SEMIBOLD};
`;

export const Flag = styled.img`
  border-radius: ${Radiuses.radius_full};
`;

export const Country = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-wrap: wrap;
  align-items: center;
`;
