import {
  CenterImg,
  PeopleEmptyStateBtn,
  PeopleEmptyStateContainer,
  PeopleEmptyStateDescription,
  PeopleEmptyStateTitle,
} from "./styled";
import File from "../../../Images/icons/fileWrap.svg";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";

const PeopleEmptyState = ({
  title,
  description,
  btnText,
  onBtnClick,
}: {
  title: string;
  description?: string;
  btnText?: string;
  onBtnClick?: () => void;
}) => {
  return (
    <PeopleEmptyStateContainer>
      <CenterImg src={File} width={100} height={100} />
      <PeopleEmptyStateTitle
        size={FontSizes.TX_LG}
        weight={FontWeights.SEMIBOLD}
      >
        {title}
      </PeopleEmptyStateTitle>
      {description && (
        <PeopleEmptyStateDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          {description}
        </PeopleEmptyStateDescription>
      )}
      {btnText && onBtnClick && (
        <PeopleEmptyStateBtn
          text={btnText}
          size={ButtonSizes.lg}
          variant={ButtonVariants.primary}
          onClick={() => onBtnClick()}
        />
      )}
    </PeopleEmptyStateContainer>
  );
};

export default PeopleEmptyState;
