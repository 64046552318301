import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PAYMENTS_LIST } from "../../mockServer/MockData";
import { sortDirection } from "../../types/apisTypes";
import { AppDispatch, RootState } from "../store";
import { Invoice, PAYMENT_STATUS_DROPDOWN, PaymentInterface } from "../../types/payments";
import { DropdownListOption } from "../../types/componentsProps";
export const PAYROLL_PROCESS_STEPS = [
  {
    title: "Review Payment",
    completed: false,
  },
  {
    title: "Choose how you want to pay",
    completed: false,
  },
  {
    title: "Choose Manual Transfer",
    completed: false,
  },
  {
    title: "Confirm payment",
    completed: false,
  },
];
interface PaymentState {
  payments_list: PaymentInterface[];
  selected_payment: PaymentInterface | null;
  selected_invoices: Invoice[];
  invoices_due_tableVariables: {
    sortKey: string;
    sortDirection: sortDirection;
    page: number;
    limit: number;
  };
  payments_table_Variables: {
    search: string;
    status: DropdownListOption;
    sortKey: keyof PaymentInterface;
    sortDirection: sortDirection;
    page: number;
    limit: number;
    currentPage: number;
    totalRecords: number;
    totalPages: number;
  };
  pay_invoices_steps: {
    title: string;
    completed: boolean;
  }[];
  pay_invoices_variables: {
    currency: string;
    paymentMethod: string;
    manualTransferMethod: string;
    transactionReferenceNumber: string;
  };

  current_pay_invoices_step: number;
  totalPayment: number;
}

const initialState: PaymentState = {
  payments_list: [],
  selected_payment: null,
  selected_invoices: [],
  invoices_due_tableVariables: {
    sortKey: "id",
    sortDirection: "ASC",
    page: 1,
    limit: 7,
  },
  payments_table_Variables: {
    search: "",
    status: PAYMENT_STATUS_DROPDOWN[0],
    sortKey: "public_id",
    sortDirection: "ASC",
    page: 1,
    limit: 7,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
  },
  pay_invoices_steps: PAYROLL_PROCESS_STEPS,
  pay_invoices_variables: {
    currency: "USD",
    paymentMethod: "Transfer",
    manualTransferMethod: "Bank Transfer",
    transactionReferenceNumber: "",
  },

  current_pay_invoices_step: 0,
  totalPayment: 0,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSelectedInvoices: (state, action: PayloadAction<Invoice[]>) => {
      if (state.selected_invoices.length === action.payload.length) {
        state.selected_invoices = [];
        return;
      }
      state.selected_invoices = action.payload;
    },

    setSelectedPayment: (state, action: PayloadAction<PaymentInterface>) => {
      state.selected_payment = action.payload;
      state.totalPayment = action.payload.invoice_total;
    },

    selectSingleInvoice: (state, action: PayloadAction<Invoice>) => {
      if (
        state.selected_invoices.find(
          (selectedInvoice) => selectedInvoice.id === action.payload.id
        )
      ) {
        state.selected_invoices = state.selected_invoices.filter(
          (invoice) => invoice.id !== action.payload.id
        );
        return;
      }
      state.selected_invoices.push(action.payload);
    },
    updateInvoicesDueTableVariables: (
      state,
      action: PayloadAction<
        Partial<PaymentState["invoices_due_tableVariables"]>
      >
    ) => {
      state.invoices_due_tableVariables = {
        ...state.invoices_due_tableVariables,
        ...action.payload,
      };
    },
    updatePaymentProcessSteps: (
      state,
      action: PayloadAction<PaymentState["pay_invoices_steps"]>
    ) => {
      state.pay_invoices_steps = action.payload;
    },

    setCurrentPayInvoicesStep: (state, action: PayloadAction<number>) => {
      state.current_pay_invoices_step = action.payload;
    },
    setTotalPayment: (state, action: PayloadAction<number>) => {
      state.totalPayment = action.payload;
    },
    updatePayInvoicesVariables: (
      state,
      action: PayloadAction<Partial<PaymentState["pay_invoices_variables"]>>
    ) => {
      state.pay_invoices_variables = {
        ...state.pay_invoices_variables,
        ...action.payload,
      };
    },
    resetPayInvoicesSteps: (state) => {
      state.pay_invoices_steps = state.pay_invoices_steps.map(step => ({
        ...step,
        completed: false,
      }));
    },
    updatePaymentTableVariables: (
      state,
      action: PayloadAction<Partial<PaymentState["payments_table_Variables"]>>
    ) => {
      state.payments_table_Variables = {
        ...state.payments_table_Variables,
        ...action.payload,
      };
    },
  },
});

export const onPayInvoicesNextStep =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const payment = getState().payment;
    const { current_pay_invoices_step, pay_invoices_steps } = payment;
    const newProcessSteps = pay_invoices_steps.map(
      (step: any, index: number) => {
        if (index === current_pay_invoices_step) {
          return { ...step, completed: true };
        }
        return step;
      }
    );
    dispatch(updatePaymentProcessSteps(newProcessSteps));
    dispatch(setCurrentPayInvoicesStep(current_pay_invoices_step + 1));
  };

export const onPayInvoicesPreviousStep =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const payment = getState().payment;
    const { current_pay_invoices_step, pay_invoices_steps } = payment;
    const newProcessSteps = pay_invoices_steps.map(
      (step: any, index: number) => {
        if (index === current_pay_invoices_step) {
          return { ...step, completed: false };
        }
        return step;
      }
    );
    dispatch(updatePaymentProcessSteps(newProcessSteps));
    dispatch(setCurrentPayInvoicesStep(current_pay_invoices_step - 1));
  };

export const {
  updateInvoicesDueTableVariables,
  updatePaymentProcessSteps,
  setSelectedInvoices,
  selectSingleInvoice,
  setCurrentPayInvoicesStep,
  setTotalPayment,
  updatePayInvoicesVariables,
  updatePaymentTableVariables,
  setSelectedPayment,
  resetPayInvoicesSteps,
} = paymentSlice.actions;

export default paymentSlice.reducer;
