import { useEffect, useState } from 'react'
import {
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalContent,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../../constants/styles";
import CloseIcon from "../../../../../Images/icons/alertClose.svg";
import Modal from '../../../../../components/shared/Modal';
import InputField from '../../../../../components/shared/InputField';
import Checkbox from '../../../../../components/shared/Checkbox';
import { Employee, PEOPLE_STATUS } from '../../../../../types/people';
import { ALL_STATUS_OPTIONS, CURRENCIES } from '../../../../../constants/dropDownLists';
import DropdownList from '../../../../../components/shared/DropdownList';
import { DropdownListOption, SnackbarTypes } from '../../../../../types/componentsProps';
import Button from '../../../../../components/shared/Button';
import { useUpdateEmployee } from '../../../../queries/people'
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const EditEmployeeModal = ({onClose, open, employee} : {onClose: () => void, open: boolean, employee: Employee | null}) => {
  const { showSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    status: {label: "", value: ""} as DropdownListOption,
    bankName: '',
    accountNumber: '',
    legalName: '',
    swiftCode: '',
    currency: '',
    check: false,
  });

  const {mutateAsync: updateEmployee, error} = useUpdateEmployee();

  const [ showBankDetails, setShowBankDetails ] = useState(false);
  
  useEffect(() => {
    if (employee) {
      const selectedStatus = ALL_STATUS_OPTIONS.find((option) => 
        option.value === employee.contract_status) || formData.status;

      setFormData({...formData, currency: employee.currency || "EGP", status: selectedStatus});
    }
  }, [employee]);

  useEffect(() => {
    if (
      (employee?.contract_status === PEOPLE_STATUS.pending_invite || 
      employee?.contract_status === PEOPLE_STATUS.invited || 
      employee?.contract_status === PEOPLE_STATUS.onboarding) &&
      formData.status.value === PEOPLE_STATUS.active
    ) {
      setShowBankDetails(true);
    }
    else {
      setShowBankDetails(false);
    }
  }, [formData.status])

  const handleClose = () => {
    if(employee) {
      const selectedStatus = ALL_STATUS_OPTIONS.find((option) => 
        option.value === employee.contract_status) || formData.status;
      setFormData({
        status: selectedStatus,
        bankName: '',
        accountNumber: '',
        legalName: '',
        swiftCode: '',
        currency: employee.currency || "EGP",
        check: false,
      });
    }
    onClose();
  }

  const handleSubmit = async () => {
    try {
      if (employee) {
        await updateEmployee({
          employee_id: employee.id,
          employee_status: formData.status.label,
          bank_name: formData.bankName === '' ? undefined : formData.bankName,
          swift_code: formData.swiftCode === '' ? undefined : formData.swiftCode,
          currency: formData.currency === '' ? undefined : formData.currency,
          account_number: formData.accountNumber === '' ? undefined : formData.accountNumber,
          legal_name: formData.legalName === '' ? undefined : formData.legalName,
        });
        handleClose();
        showSnackbar({
          open: true,
          type: SnackbarTypes.SUCCESS,
          title: "Success",
          description: "Status or bank details updated.",
        });
      }
    } catch (e: any) {
      handleClose();
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to update.",
      });
    }
  };

  return (
      <Modal open={open} onClose={handleClose}>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
              Please enter required data below
            </ModalTitle>
            <img src={CloseIcon} alt="close-icon" onClick={handleClose} />
          </ModalHeader>
          <ModalContent>
            <DropdownList
                label='Status'
                options={ALL_STATUS_OPTIONS.slice(1)}
                selectedOption={formData.status}
                setSelectedOption={(option) => setFormData({ ...formData, status: option })}
            />
          {showBankDetails ? (
            <>
              <InputField
                label="Legal Name"
                type="text"
                value={formData.legalName}
                onChange={(e) => {
                  setFormData({...formData, legalName: e.target.value})
                }}
              />
              <InputField
                label="Bank Name"
                type="text"
                value={formData.bankName}
                onChange={(e) => {
                  setFormData({...formData, bankName: e.target.value})
                }}
              />
              <InputField
                label="Bank Account Number"
                type="number"
                value={formData.accountNumber}
                onChange={(e) => {
                  setFormData({...formData, accountNumber: e.target.value})
                }}
              />
              <InputField
                label="Swift Code"
                type="text"
                value={formData.swiftCode}
                onChange={(e) => {
                  setFormData({...formData, swiftCode: e.target.value})
                }}
              />
              <InputField
                disabled={true}
                label="Currency"
                type="text"
                value={formData.currency}
                onChange={(e) => {
                  setFormData({...formData, currency: e.target.value})
                }}
              />
              <Checkbox
                label="I have double checked the information added."
                name="checkbox"
                checked={formData.check}
                onChange={() => {
                  setFormData({...formData, check: !formData.check})
                }}
              />
            </>
          ): null}
          </ModalContent>
          <ModalFooter>
            <Button
              text="Cancel"
              variant={ButtonVariants.secondary_gray}
              size={ButtonSizes.lg}
              style={{width: "100%"}}
              onClick={() => handleClose()}
            />
            <Button
              text="Submit"
              variant={ButtonVariants.primary}
              size={ButtonSizes.lg}
              style={{width: "100%"}}
              disabled={
                employee?.contract_status === formData.status.label
              }
              onClick={handleSubmit}
            />
          </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

export default EditEmployeeModal