import { useEffect, useState } from "react";
import {
  ManageHeader,
  PageSubtitle,
  PageTitle,
  ManageContentContainer,
  ContentContainer,
  FiltersContainer,
  TableContainer,
  DropdownContainer,
} from "./styled";
import {
  FontSizes,
  FontWeights,
  LoaderSizes,
} from "../../../../constants/styles";
import { useNavigate } from "react-router-dom";
import {
  DropdownListOption,
} from "../../../../types/componentsProps";
import SearchInput from "../../../../components/shared/SearchInput";
import DropdownList from "../../../../components/shared/DropdownList";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { setEmployeesTableVariables } from "../../../../redux/slices/peopleSlice";
import Loader from "../../../../components/shared/Loader";
import { ALL_STATUS_OPTIONS } from "../../../../constants/dropDownLists";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useGetCompaniesListAdmin } from "../../../queries/company";
import { useGetEmployeeListAdmin } from "../../../queries/people";
import { setUserCompaniesOptions } from "../../../../redux/slices/companiesSlice";
import EmployeeTable from "./EmployeeTable";

const ManageAdmin = () => {
  const {
    sortKey,
    sortDirection,
    search,
    selectedStatus,
    currentPage,
    filteredEmployees,
    itemsPerPage,
    totalRecords,
  } = useAppSelector((state) => state.people.employees_table_variables);

  const { userCompaniesOptions } = useAppSelector(
    (state) => state.companies
  );
  const [ openModal, setOpenModal ] = useState(false);
  
  const { data: companies, error: companiesError } = useGetCompaniesListAdmin();

  const allCompaniesOption = { label: 'All Companies', value: 'all' };
  const [ selectedCompany, setSelectedCompany ] = useState<DropdownListOption>(allCompaniesOption);
  const [searchValue, setSearchValue] = useState<string>(search);
  
  const debounceValue = useDebounce(searchValue);
  const dispatch = useAppDispatch();

  const { showSnackbar } = useSnackbar();
  
  const { data: employeesData, error: employeesError, isFetching } = useGetEmployeeListAdmin({
    company_id: companies?.find(company => company.name === selectedCompany.label)?.id.toString(),
    status: selectedStatus.label === "All Statuses" ? undefined : selectedStatus.label,
    name: search,
    sort: sortKey,
    sort_dir: sortDirection,
    page: currentPage,
    limit: itemsPerPage
  })

  useEffect(() => {
    if(employeesData) {
      dispatch(
        setEmployeesTableVariables({
          filteredEmployees: employeesData.employees,
          currentPage: employeesData._metadata.page,
          totalRecords: employeesData._metadata.total_records,
          totalPages: employeesData._metadata.last_page,
        })
      )
    }
  }, [employeesData, filteredEmployees])

  useEffect(() => {
    if(companies) {
      dispatch(setUserCompaniesOptions(companies));
    }
  }, [companies])
 
  useEffect(() => {
    dispatch(
      setEmployeesTableVariables({
        search: debounceValue,
        currentPage: 1
    }));
  }, [debounceValue]);

  useEffect(() => {
    return function() {
      dispatch(setEmployeesTableVariables({
        selectedStatus: ALL_STATUS_OPTIONS[0],
        search: "",
        sortDirection: "ASC",
        sortKey: "created_at",
        currentPage: 1
      }));
    }
  }, [])
 
  return (
    <>
      <ManageHeader>
        <PageTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Manage People
        </PageTitle>
        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Effortlessly monitor and manage your employees’ information.
        </PageSubtitle>
      </ManageHeader>
 
      <ManageContentContainer>
        <ContentContainer>
          <FiltersContainer>
            <SearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search for people"
              containerStyle={{ width: "30%" }}
            />
            <DropdownContainer>
              <DropdownList
                options={userCompaniesOptions}
                selectedOption={selectedCompany}
                setSelectedOption={(company: DropdownListOption) => {
                  setSelectedCompany(company);
                  dispatch(setEmployeesTableVariables({
                    currentPage: 1
                  }));
                }}
                style={{ height: "48px", marginTop: "0px" }}
              />
              <DropdownList
                options={ALL_STATUS_OPTIONS}
                selectedOption={selectedStatus}
                setSelectedOption={(status: DropdownListOption) =>
                  dispatch(
                    setEmployeesTableVariables({
                        selectedStatus: status,
                        currentPage: 1,
                    })
                  )
                }
                containerStyle={{ width: "100%" }}
                style={{ height: "48px", marginTop: "0px" }}
              />
            </DropdownContainer>
          </FiltersContainer>
          <TableContainer>
            {isFetching ? (
              <Loader size={LoaderSizes.lg} />
            ) : (
              <EmployeeTable />
            )}
          </TableContainer>
        </ContentContainer>
      </ManageContentContainer>
    </>
  );
}

export default ManageAdmin