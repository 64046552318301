export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const enum RegistrationSteps {
  account_type = "account-type",
  user_registration = "user-registration",
  email_confirmation = "email-confirmation",
  organization_details = "organization-details",
  personal_details = "personal-details",
  // solutions = "solutions",
  // account_setup = "account-setup",
}

export const howDidYouHearAboutOvarcOptions = [
  { value: "Employer/Company", label: "Employer/Company" },
  { value: "Employee/Contractor", label: "Employee/Contractor" },
  { value: "Referred/Recommended", label: "Referred/Recommended" },
  { value: "Email Invite", label: "Email Invite" },
  { value: "LinkedIn", label: "LinkedIn" },
];

export const organizationSizeOptions = [
  {
    value: {
      min: 1,
      max: 10,
    },
    label: "1-10",
  },
  {
    value: {
      min: 11,
      max: 20,
    },
    label: "11-20",
  },
  {
    value: {
      min: 21,
      max: 50,
    },
    label: "21-50",
  },
  {
    value: {
      min: 51,
      max: 100,
    },
    label: "51-100",
  },
  {
    value: {
      min: 100,
      max: 9999999,
    },
    label: "100+",
  },
];

export const organizationRoleOptions = [
  { value: 0, label: "Chief Executive Officer " },
  { value: 1, label: "Chief Marketing Officer" },
  { value: 2, label: "Chief Operating Officer" },
  { value: 3, label: "Founder" },
  { value: 4, label: "HR Manager" },
];

export const typeOfWorkerOptions = [
  { value: 2, label: "Contractor", comingSoon: true },
  { value: 1, label: "Direct Employee", comingSoon: true },
  { value: 0, label: "EOR Employee" },
];

export const organizationLocationOptions = [
  {
    value: "United States",
    label: "United States",
    iconLeading:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
    iconLeading:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
  {
    value: "United Arab Emirats",
    label: "United Arab Emirats",
    iconLeading:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
  {
    value: "Uganda",
    label: "Uganda",
    iconLeading:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
  },
];

export const organizationLocationOptionsWithoutIcon = [
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "United Arab Emirats",
    label: "United Arab Emirats",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
];
export let registrationSteps = [
  {
    title: "Sign up",
    description: "Register your account",
    completed: true,
  },
  {
    title: "Personal details",
    description: "Add your personal information",
    completed: false,
  },
  {
    title: "Select solutions",
    description: "Select the solutions you'd like to use",
    completed: false,
  },
];

export const OvarcSolutions = [
  {
    id: 0,
    title: "Payroll",
    description: "Manage and automate global/payroll",
  },

  {
    id: 1,
    title: "Incorporation",
    description: "Open/expand my business to a new country",
  },

  {
    id: 2,
    title: "Benefits",
    description: "Manage employees' onboarding and benefits",
  },

  {
    id: 3,
    title: "Logistics",
    description: "Manage assets, bus, travel, and more",
  },
];

export const PhoneCodes = [
  {
    value: "+1",
    label: "+1",
  },
  {
    value: "+44",
    label: "+44",
  },
  {
    value: "+971",
    label: "+971",
  },
  {
    value: "+256",
    label: "+256",
  },
  {
    value: "+20",
    label: "+20",
  },
];
