import styled from "styled-components";
import { BadgeTypes } from "../../../types/componentsProps";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";

export const BadgeDot = styled.div<{ color: BadgeTypes }>`
  width: 8px;
  height: 8px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${({ color }) => {
    switch (color) {
      case BadgeTypes.warning:
        return Colors.utility_warning_500;
      case BadgeTypes.blue:
        return Colors.utility_brand_500;
      case BadgeTypes.gray:
        return Colors.utility_gray_500;
      case BadgeTypes.blueLight:
        return Colors.utility_blue_500;
      case BadgeTypes.success:
        return Colors.utility_success_500;
      case BadgeTypes.error:
        return Colors.utility_error_500;
      case BadgeTypes.brand:
        return Colors.utility_brand_500;
      case BadgeTypes.grayBlue:
        return Colors.utility_gray_blue_500;
      case BadgeTypes.orange:
        return Colors.utility_orange_500;
      case BadgeTypes.purple:
        return Colors.utility_purple_500;
      case BadgeTypes.indigo:
        return Colors.utility_indigo_500;
    }
  }};
`;
export const BadgeContainer = styled.div<{ color: BadgeTypes }>`
  width: fit-content;
  padding-inline: ${Spaces.spacing_md};
  border-radius: ${Radiuses.radius_full};
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_sm};
  white-space: nowrap;
  ${({ color }) => {
    switch (color) {
      case BadgeTypes.warning:
        return `
          background-color: ${Colors.utility_warning_50};
          border: 1px solid ${Colors.utility_warning_200};
          color: ${Colors.utility_warning_700};
        `;
      case BadgeTypes.blue:
        return `
          background-color: ${Colors.utility_blue_50};
          border: 1px solid ${Colors.utility_blue_200};
          color: ${Colors.utility_blue_700};
        `;
      case BadgeTypes.gray:
        return `
          background-color: ${Colors.utility_gray_50};
          border: 1px solid ${Colors.utility_gray_200};
          color: ${Colors.utility_gray_700};
        `;
      case BadgeTypes.blueLight:
        return `
          background-color: ${Colors.Blue_light_50};
          border: 1px solid ${Colors.Blue_light_200};
          color: ${Colors.utility_light_blue_700};
      
`;
      case BadgeTypes.success:
        return `
          background-color: ${Colors.utility_success_50};
          border: 1px solid ${Colors.utility_success_200};
          color: ${Colors.utility_success_700};
        `;
      case BadgeTypes.error:
        return `
          background-color: ${Colors.utility_error_50};
          border: 1px solid ${Colors.utility_error_200};
          color: ${Colors.utility_error_700};
        `;
      case BadgeTypes.brand:
        return `
          background-color: ${Colors.utility_brand_50};
          border: 1px solid ${Colors.utility_brand_200};
          color: ${Colors.utility_brand_700};
        `;
      case BadgeTypes.grayBlue:
        return `
          background-color: ${Colors.utility_gray_blue_50};
          border: 1px solid ${Colors.utility_gray_blue_200};
          color: ${Colors.utility_gray_blue_700};
        `;
      case BadgeTypes.orange:
        return `
          background-color: ${Colors.utility_orange_50};
          border: 1px solid ${Colors.utility_orange_200};
          color: ${Colors.utility_orange_700};
        `;
      case BadgeTypes.purple:
        return `
          background-color: ${Colors.utility_purple_50};
          border: 1px solid ${Colors.utility_purple_200};
          color: ${Colors.utility_purple_700};
        `;
      case BadgeTypes.indigo:
        return `
          background-color: ${Colors.utility_indigo_50};
          border: 1px solid ${Colors.utility_indigo_200};
          color: ${Colors.utility_indigo_700};
        `;
    }
  }};
`;
