import styled from "styled-components";
import { Colors } from "../../../constants/styles";

export const ModalContainer = styled.dialog`
  position: relative;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  background-color: ${Colors.bg_primary};
  border: none;
  margin-right: 0px;

  min-height: 100vh;
  min-width: 30%;

  &::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
`;