import { useEffect, useState } from "react"

export const useDebounce = (value: string) => {
    const [debounceValue, setDebounceValue] = useState(value)
    useEffect (() => {
        const timeout = setTimeout(() => {
            setDebounceValue(value)
        }, 1000);

        return () => clearTimeout(timeout);
    }, [value])
    return debounceValue;
}