import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../constants/styles";
import Typography from "../../components/shared/Typography";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background-color: ${Colors.bg_active};
  padding-top: 41px;
`;

export const Logo = styled.img`
  width: 146px;
  height: 46px;
  margin-bottom: 10%;
`;

export const Title = styled(Typography)`
  margin-top: 19px;
  color: ${Colors.text_primary};
  text-align: center;
  white-space: pre-line;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const Subtext = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-bottom: 27px;
  text-align: center;
  white-space: pre-line;
`;
export const LinkButton = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_md};

  background-color: ${Colors.button_primary_bg};
  color: ${Colors.button_primary_fg};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.button_primary_bg};

  &:hover {
    background-color: ${Colors.button_primary_hover};
  }
  &:focus {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(158, 119, 237, 0.24);
  }

  height: 44px;
  padding: 10px ${Spaces.spacing_xl};
  box-sizing: border-box;
`;
