import styled from "styled-components";
import { Colors, Spaces, Radiuses } from "../../../constants/styles";
import Typography from "../Typography";
import Button from "../Button";

export const SnackbarContainer = styled.div<{ open: boolean }>`
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  background-color: ${Colors.bg_primary};
  padding: ${Spaces.spacing_lg};

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_primary};
  z-index: 1000;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: visibility 0.5s, opacity 0.5s linear;

  @media (max-width: 768px) {
    bottom: 10%;
  }
`;

export const SnackbarContent = styled.div`
  margin-inline-start: 7px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding-top: ${Spaces.spacing_md};
`;

export const SnackbarTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-bottom: 4px;
`;

export const SnackbarDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const SnackbarCloseIcon = styled.img`
  cursor: pointer;
`;

export const SnackBarButton = styled(Button)`
  padding: 0px;
  height: fit-content;
`;
export const SnackbarFooter = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  margin-top: ${Spaces.spacing_lg};
`;
