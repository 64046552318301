import React from "react";
import { BadgeContainer, BadgeDot } from "./styled";
import { BadgeTypes } from "../../../types/componentsProps";
import Typography from "../Typography";
import { FontSizes, FontWeights } from "../../../constants/styles";

interface BadgeProps {
  text: string;
  color: BadgeTypes;
  dot?: boolean;
  icon?: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ text, color, dot = false }) => {
  return (
    <BadgeContainer color={color}>
      {dot ? <BadgeDot color={color} /> : null}
      <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
        {text}
      </Typography>
    </BadgeContainer>
  );
};

export default Badge;
