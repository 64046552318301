import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const PageHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
`;

export const PageTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const PageSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
