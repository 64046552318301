import { SnackbarProps, SnackbarTypes } from "../../../types/componentsProps";
import {
  SnackBarButton,
  SnackbarCloseIcon,
  SnackbarContainer,
  SnackbarContent,
  SnackbarDescription,
  SnackbarFooter,
  SnackbarTitle,
} from "./styled";
import SuccessIcon from "../../../Images/icons/alertSuccess.svg";
import ErrorIcon from "../../../Images/icons/alertError.svg";
import WarningIcon from "../../../Images/icons/alertWarning.svg";
import InfoIcon from "../../../Images/icons/alertInfo.svg";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import CloseIcon from "../../../Images/icons/alertClose.svg";
import { useEffect } from "react";

const Snackbar = ({
  open,
  title,
  description,
  type,
  actionBtnCallback,
  actionBtnText,
  duration = 5000,
  onClose,
}: SnackbarProps) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [open, onClose]);

  const getIcon = () => {
    switch (type) {
      case SnackbarTypes.SUCCESS:
        return <img src={SuccessIcon} width={38} height={38} />;
      case SnackbarTypes.ERROR:
        return <img src={ErrorIcon} width={38} height={38} />;
      case SnackbarTypes.WARNING:
        return <img src={WarningIcon} width={38} height={38} />;
      case SnackbarTypes.INFO:
        return <img src={InfoIcon} width={38} height={38} />;
      default:
        return <img src={InfoIcon} width={38} height={38} />;
    }
  };

  return (
    <SnackbarContainer open={open}>
      {getIcon()}
      <SnackbarContent>
        <SnackbarTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
          {" "}
          {title}
        </SnackbarTitle>
        <SnackbarDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          {description}
        </SnackbarDescription>
        <SnackbarFooter>
          <SnackBarButton
            variant={ButtonVariants.link_gray}
            size={ButtonSizes.sm}
            text={"Dismiss"}
            onClick={onClose}
          />
          {actionBtnCallback && actionBtnText && (
            <SnackBarButton
              variant={ButtonVariants.link_color}
              size={ButtonSizes.sm}
              text={actionBtnText}
              onClick={actionBtnCallback}
            />
          )}
        </SnackbarFooter>
      </SnackbarContent>
      <SnackbarCloseIcon
        src={CloseIcon}
        width={36}
        height={36}
        onClick={onClose}
      />
    </SnackbarContainer>
  );
};
export default Snackbar;
