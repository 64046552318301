import React from "react";
import {
  PayrollEmptyStateContainer,
  PayrollEmptyStateDescription,
  PayrollEmptyStateTitle,
} from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import Image from "../../../Images/payollEmptyState.svg";

const PayrollEmptyState: React.FC = () => {
  return (
    <PayrollEmptyStateContainer>
      <div>
        <PayrollEmptyStateTitle
          size={FontSizes.XS}
          weight={FontWeights.SEMIBOLD}
        >
          Easily monitor and manage your{"\n"}company's payroll
        </PayrollEmptyStateTitle>

        <PayrollEmptyStateDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          Here, you can effortlessly oversee and control your company’s{"\n"}
          payroll. Start by adding employee details, setting up payment{"\n"}
          schedules, and managing tax information. Our user-friendly interface
          {"\n"}ensures smooth payroll processing, enabling you to generate
          {"\n"}accurate reports and handle deductions with ease. Whether it’s
          {"\n"}managing salaries, bonuses, or benefits, simplify your payroll
          tasks{"\n"}and ensure compliance effortlessly. Get started today to
          streamline{"\n"}your financial operations and keep your payroll on
          track.”
        </PayrollEmptyStateDescription>
      </div>
      <img src={Image} alt="Payroll Empty State" />
    </PayrollEmptyStateContainer>
  );
};

export default PayrollEmptyState;
