import styled from "styled-components";
import { Spaces } from "../../../../constants/styles";
import { Card, CardRow } from "../../../shared/Card";

export const Container = styled.div``;

export const ConfirmationCard = styled(Card)`
  margin-block: ${Spaces.spacing_md};
`;

export const ConfirmationCardRow = styled(CardRow)`
  gap: ${Spaces.spacing_md};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
