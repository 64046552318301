import { useEffect, useState } from 'react'
import { CompaniesTable, ContentContainer, FiltersContainer, TableBoldText, TableFadedText } from './styled'
import SearchInput from '../../../components/shared/SearchInput';
import DropdownList from '../../../components/shared/DropdownList';
import { DropdownListOption, SnackbarTypes } from '../../../types/componentsProps';
import { TableCell, TableHeader, TableHeadWithIcon, TableRow, TableRowDynamic } from '../../../components/shared/Table/styled';
import { FontSizes, FontWeights } from '../../../constants/styles';
import { Sort } from '../../../components/shared/SortIcon';
import { COMPANIES_STATUS_OPTIONS } from '../../../constants/dropDownLists';
import { useGetAllCompaniesAdmin, useImpersonateAdmin, usePasswordReset } from '../../queries/company';
import TablePagination from '../../../components/shared/TablePagination';
import { COMPANY_STATUS_BADGE_COLOR, CompanyInterface } from '../../../types/companies';
import Badge from '../../../components/shared/Badge';
import { useDebounce } from '../../../hooks/useDebounce';
import ActionsDropDown from '../../../components/shared/ActionsDropDown';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks';
import { setCompaniesTableVariables } from '../../../redux/slices/companiesSlice';
import { storeImpersonatedUser } from '../../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { HOME_SCREEN } from '../../../constants/routesNames';
import { useSnackbar } from '../../../hooks/useSnackbar';

const CompaniesList = () => {
  const { 
    search, 
    selectedStatus, 
    sortKey, 
    sortDirection,
    totalPages,
    totalRecords,
    itemsPerPage,
    currentPage,
    filteredCompanies
  } = useAppSelector((state) => state.companies.companies_table_variables);

  const { showSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>("");
  const debounceValue = useDebounce(searchValue);
  
  const { mutateAsync: impersonate } = useImpersonateAdmin();

  const { mutateAsync: passwordReset } = usePasswordReset();

  const { data: companiesDetails } = useGetAllCompaniesAdmin({
    status: selectedStatus.label === "All Statuses" ? undefined : selectedStatus.label,
    search: search,
    sort: sortKey,
    sort_dir: sortDirection,
    page: currentPage,
    limit: itemsPerPage
  });

  useEffect(() => {
    if(companiesDetails) {
      dispatch(setCompaniesTableVariables({
        filteredCompanies: companiesDetails.companies,
        currentPage: companiesDetails._metadata?.page || 0,
        totalRecords: companiesDetails._metadata?.total_records || 0,
        totalPages: companiesDetails._metadata?.last_page || 0,
      }));
    }
  }, [companiesDetails])

  useEffect(() => {
    dispatch(setCompaniesTableVariables({
      search: debounceValue, 
      currentPage: 1
    }));
  }, [debounceValue]);

  useEffect(() => {
    return function() {
      dispatch(setCompaniesTableVariables({
          filteredCompanies: null,
          sortKey: "name",
          sortDirection: "ASC",
          search: "",
          selectedStatus: COMPANIES_STATUS_OPTIONS[0],
          currentPage: 1,
          totalRecords: 0,
          totalPages: 0
      }))
    }
  }, [])
  
  const handleImpersonation = async (userId: number) => {
    try {
      const data = await impersonate(userId);
      dispatch(storeImpersonatedUser(data.user, data.access_token));  
      navigate(HOME_SCREEN);
    } catch(err: any) {
      showSnackbar({
        open: true,
        title: "Impersonation error",
        description: err.response?.data.message || "Something went wrong",
        type: SnackbarTypes.ERROR,
      });
    }
  }

  const handlePasswordReset = async (email: string) => {
    try {
      await passwordReset(email);
      showSnackbar({
        open: true,
        title: "Password reset email sent",
        description: "A password reset email is sent to the user",
        type: SnackbarTypes.SUCCESS,
      });
    } catch (err: any) {
      showSnackbar({
        open: true,
        title: "Password reset error",
        description: err.response?.data.message || "Something went wrong",
        type: SnackbarTypes.ERROR,
      });
    }
  }

  const renderRows = () => {
    return filteredCompanies && filteredCompanies.map((companyDetails: CompanyInterface) => (
      <TableRow key={companyDetails.id}>
        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {companyDetails.name}
          </TableBoldText>
        </TableCell>

        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {companyDetails.organization.name}
          </TableBoldText>
        </TableCell>

        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            <div>
              <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {companyDetails.organization.user.first_name} {companyDetails.organization.user.last_name}
              </TableBoldText>
              <TableFadedText
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {companyDetails.organization.user.email}
              </TableFadedText>
            </div>
          </TableBoldText>
        </TableCell>
        <TableCell>
          <Badge
            text={companyDetails.company_status}
            dot
            color={COMPANY_STATUS_BADGE_COLOR[companyDetails.company_status]}
          />
        </TableCell>
        <TableCell>
          <ActionsDropDown
            actions={[
              {
                text: "Impersonate User",
                onSelect: () => {
                  handleImpersonation(companyDetails.organization.user.id);
                }
              },
              {
                text: "Password Reset",
                onSelect: () => {
                  handlePasswordReset(companyDetails.organization.user.email);
                }
              },
            ]}
          />
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <ContentContainer>
      <FiltersContainer>
        <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search by company, username, email"
          containerStyle={{ width: "30%" }}
        />
        <DropdownList
          options={COMPANIES_STATUS_OPTIONS}
          selectedOption={selectedStatus}
          setSelectedOption={(option: DropdownListOption) => 
            dispatch(setCompaniesTableVariables({
              selectedStatus: option,
              currentPage: 1
            }))
          }
          containerStyle={{ width: "21.3%" }}
          style={{ height: "48px", marginTop: "0px" }}
        />
      </FiltersContainer>
      <CompaniesTable>
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(setCompaniesTableVariables({
                  sortDirection: sortKey !== "name" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "name"
                }));
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Company
              </TableFadedText>
              <Sort
                sortKey={"name"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(setCompaniesTableVariables({
                  sortDirection: sortKey !== "organization_name" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "organization_name"
                }));
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Organization
              </TableFadedText>
              <Sort
                sortKey={"organization_name"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(setCompaniesTableVariables({
                  sortDirection: sortKey !== "user_name" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "user_name"
                }));
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Created By
              </TableFadedText>
              <Sort
                sortKey={"user_name"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(setCompaniesTableVariables({
                  sortDirection: sortKey !== "company_status" ? "ASC" : (sortDirection === "ASC" ? "DESC" : "ASC"),
                  sortKey: "company_status"
                }));
              }}
            >
              <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Company Status
              </TableFadedText>
              <Sort
                sortKey={"company_status"}
                currentSortKey={sortKey}
                sortDirection={sortDirection}
                onClick={() => {}}
              />
            </TableHeadWithIcon>
          </TableCell>

          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TableFadedText>
          </TableCell>
        </TableHeader>
        {renderRows()}
        {totalPages > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(setCompaniesTableVariables({currentPage: page}))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </CompaniesTable>
    </ContentContainer>
  )
}

export default CompaniesList