import styled from "styled-components";
import Typography from "../../../shared/Typography";
import {
  Breakpoints,
  Colors,
  Radiuses,
  Spaces,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding-inline: ${Spaces.spacing_4xl};
    width: 100%;
  }
`;

export const TitlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xs};
  margin-block-end: 42px;
`;

export const Title = styled(Typography)`
  color: ${Colors.text_brand_primary};
  margin-block: ${Spaces.spacing_none};
`;

export const Subtitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-block: ${Spaces.spacing_none};
`;

export const AccountTypeContainer = styled.div`
  width: 480px;
  border: 1px solid ${Colors.border_secondary};
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_primary};
  padding: ${Spaces.spacing_xl};
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: ${Spaces.spacing_lg};
  margin-bottom: 22px;

  @media screen and (max-width: 850px) {
    width: 380px;
  }
  @media screen and (max-width: ${Breakpoints.tablet}) {
    width: 100%;
  }
`;

export const UserIConContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_brand_secondary};
`;

export const AccountTypeOption = styled.div`
  flex: 1;
`;

export const CheckBoxRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const OptionName = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-block: ${Spaces.spacing_none};
`;

export const OptionSubtext = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  margin-block: ${Spaces.spacing_none};
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const NextButton = styled(Button)`
  width: 48%;
`;
