import React from "react";
import GoogleIcon from "../../../Images/icons/google.svg";
import GoogleIconDisabled from "../../../Images/icons/googleDisabled.svg";
import { Button } from "./styled";
import Typography from "../Typography";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { SocialButtonProps } from "../../../types/componentsProps";

const renderSocialIcon = (
  social: "google" | "facebook" | "apple",
  disabled: boolean = false
) => {
  switch (social) {
    case "google":
      return (
        <img
          src={disabled ? GoogleIconDisabled : GoogleIcon}
          alt="google"
          width={24}
          height={24}
        />
      );
    default:
      return null;
  }
};
const SocialButton: React.FC<SocialButtonProps> = ({
  social,
  disabled,
  ...props
}) => {
  return (
    <Button {...props}>
      {renderSocialIcon(social, disabled)}
      <Typography size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
        {props.text}
      </Typography>
    </Button>
  );
};

export default SocialButton;
