import { sortDirection } from "../../../types/apisTypes";
import { SortIcon } from "./styled";
import ArrowDown from "../../../Images/icons/arrow-down.svg";

export const Sort = ({
  sortKey,
  currentSortKey,
  sortDirection,
  onClick,
}: {
  sortKey: string;
  currentSortKey: string;
  sortDirection: sortDirection;
  onClick: any;
}) => {
  return sortKey ===  currentSortKey? (
    <SortIcon
      src={ArrowDown}
      alt="Arrow Down"
      width={16}
      height={16}
      direction={sortDirection}
      onClick={onClick}
    />
  ) : null;
};
