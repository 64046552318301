import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import {
  editToBeAddedAsset,
  onNextAddAssetStep,
  onPreviousAddAssetStep,
} from "../../../../redux/slices/assetsHubSlice";
import Button from "../../../shared/Button";
import {
  DeliveryStepButtonsContainer,
  DeliveryStepContainer,
  ExtraMessage,
  NextButton,
  NotesContainer,
  NotesInput,
  NotesInputInfo,
  RadioOptionContainer,
  RadioOptionsContainer,
  Warning,
  WarningText,
} from "./styled";
import LeftArrow from "../../../../Images/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { Card, CardTitle } from "../../../shared/Card";
import WarningIcon from "../../../../Images/icons/alertWarning.svg";
import {
  AddAssetDeliveryStatus,
  AddAssetEquipmentStatusOptions,
} from "../../../../constants/assetsHub";
import Checkbox from "../../../shared/Checkbox";
import InputField from "../../../shared/InputField";
import Label from "../../../shared/Label";

const DeliveryPreferenceStep = () => {
  const dispatch = useAppDispatch();
  const { deliveryInfo } = useAppSelector(
    (state) => state.assetsHub.toBeAddedAsset
  );

  const renderEquipmentStatus = () => {
    return (
      <RadioOptionsContainer>
        {AddAssetEquipmentStatusOptions.map((option, index) => {
          return (
            <RadioOptionContainer
              key={index}
              active={deliveryInfo.equipmentStatus === option.id}
              onClick={() => {
                if (index === 0) {
                  return;
                }
                dispatch(
                  editToBeAddedAsset({
                    deliveryInfo: {
                      ...deliveryInfo,
                      equipmentStatus: option.id,
                    },
                  })
                );
              }}
              disabled={index === 0}
            >
              <Checkbox
                checked={deliveryInfo.equipmentStatus === option.id}
                radio={true}
                label={option.title}
                disabled={index === 0}
              />
            </RadioOptionContainer>
          );
        })}
      </RadioOptionsContainer>
    );
  };

  const renderShipmentStatus = () => {
    return deliveryInfo.equipmentStatus == "2" ? (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Choose the status of the shipment
        </CardTitle>
        <RadioOptionsContainer>
          {AddAssetDeliveryStatus.map((option, index) => {
            return (
              <RadioOptionContainer
                key={index}
                active={deliveryInfo.shipmentStatus === option.id}
                onClick={() => {
                  dispatch(
                    editToBeAddedAsset({
                      deliveryInfo: {
                        ...deliveryInfo,
                        shipmentStatus: option.id,
                      },
                    })
                  );
                }}
              >
                <Checkbox
                  checked={deliveryInfo.shipmentStatus === option.id}
                  radio={true}
                  label={option.title}
                />
              </RadioOptionContainer>
            );
          })}
        </RadioOptionsContainer>
      </Card>
    ) : null;
  };

  const renderCourierInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Courier Information
        </CardTitle>
        <RadioOptionsContainer>
          <InputField
            label="Tracking Number*"
            value={deliveryInfo.courierInfo.trackingNumber}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    courierInfo: {
                      ...deliveryInfo.courierInfo,
                      trackingNumber: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <InputField
            label="Courier*"
            value={deliveryInfo.courierInfo.courierName}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    courierInfo: {
                      ...deliveryInfo.courierInfo,
                      courierName: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <InputField
            label="Link"
            value={deliveryInfo.courierInfo.link}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    courierInfo: {
                      ...deliveryInfo.courierInfo,
                      link: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <InputField
            label="Sent Date"
            value={deliveryInfo.courierInfo.sentDate}
            type="date"
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    courierInfo: {
                      ...deliveryInfo.courierInfo,
                      sentDate: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <NotesContainer>
            <Label text="Noes" />
            <NotesInput
              maxLength={10000}
              value={deliveryInfo.locationInfo.notes}
              onChange={(e) => {
                dispatch(
                  editToBeAddedAsset({
                    deliveryInfo: {
                      ...deliveryInfo,
                      locationInfo: {
                        ...deliveryInfo.locationInfo,
                        notes: e.target.value,
                      },
                    },
                  })
                );
              }}
              placeholder="No  Notes"
            />
            <NotesInputInfo>
              <ExtraMessage>
                {`${deliveryInfo.locationInfo.notes.length}/10000`}{" "}
              </ExtraMessage>
            </NotesInputInfo>
          </NotesContainer>
        </RadioOptionsContainer>
      </Card>
    );
  };
  const renderLocationInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Choose Location
        </CardTitle>
        <RadioOptionsContainer>
          <InputField
            label="Country"
            value={deliveryInfo.locationInfo?.country}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    locationInfo: {
                      ...deliveryInfo.locationInfo,
                      country: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <InputField
            label="City*"
            value={deliveryInfo.locationInfo?.city}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    locationInfo: {
                      ...deliveryInfo.locationInfo,
                      city: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <InputField
            label="Zip Code*"
            value={deliveryInfo.locationInfo?.zipCode}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    locationInfo: {
                      ...deliveryInfo.locationInfo,
                      zipCode: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <InputField
            label="Street*"
            value={deliveryInfo.locationInfo.street}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  deliveryInfo: {
                    ...deliveryInfo,
                    locationInfo: {
                      ...deliveryInfo.locationInfo,
                      street: e.target.value,
                    },
                  },
                })
              );
            }}
          />
          <NotesContainer>
            <Label text="Noes" />
            <NotesInput
              maxLength={10000}
              value={deliveryInfo.locationInfo.notes}
              onChange={(e) => {
                dispatch(
                  editToBeAddedAsset({
                    deliveryInfo: {
                      ...deliveryInfo,
                      locationInfo: {
                        ...deliveryInfo.locationInfo,
                        notes: e.target.value,
                      },
                    },
                  })
                );
              }}
              placeholder="No  Notes"
            />
            <NotesInputInfo>
              <ExtraMessage>
                {`${deliveryInfo.locationInfo.notes.length}/10000`}{" "}
              </ExtraMessage>
            </NotesInputInfo>
          </NotesContainer>
        </RadioOptionsContainer>
      </Card>
    );
  };
  const renderExtraData = () => {
    return deliveryInfo.equipmentStatus == "3"
      ? renderLocationInfo()
      : deliveryInfo.shipmentStatus == "1"
      ? renderCourierInfo()
      : null;
  };

  return (
    <DeliveryStepContainer>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Choose the status of the equipment
        </CardTitle>
        <Warning>
          <img src={WarningIcon} alt="warning" />
          <WarningText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            For this type of employee, they must review and sign the equipment
            agreement before the items can be marked as supplied to prevent
            misclassification. The agreement will be sent to them in the next
            step.
          </WarningText>
        </Warning>
        {renderEquipmentStatus()}
      </Card>
      {renderShipmentStatus()}
      {renderExtraData()}
      <DeliveryStepButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={LeftArrow} alt="left arrow" />}
          onClick={() => {
            dispatch(onPreviousAddAssetStep());
          }}
        />
        <NextButton
          text="Confirm & Request"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => {
            dispatch(onNextAddAssetStep());
          }}
        />
      </DeliveryStepButtonsContainer>
    </DeliveryStepContainer>
  );
};

export default DeliveryPreferenceStep;
