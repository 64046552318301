import React, { memo, useEffect, useRef, useState } from "react";
import {
  DropdownListContainer,
  DropdownListInput,
  DropdownListValue,
  List,
  ListOption,
  CheckIcon,
  Option,
  ErrorMessage,
} from "./styled";
import {
  DropdownListOption,
  DropdownListProps,
} from "../../../types/componentsProps";
import Label from "../Label";
import { FontSizes, FontWeights } from "../../../constants/styles";
import ChevronDown from "../../../Images/icons/chevronDown.svg";
import CheckBlue from "../../../Images/icons/checkBlue.svg";

const DropdownList: React.FC<DropdownListProps> = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
  onFocus,
  error,
  style,
  containerStyle,
  hint,
}) => {
  const [open, setOpen] = useState(false);
  const menu = useRef<HTMLDivElement>(null);
  const list = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menu.current &&
        !menu.current.contains(event.target as Node) &&
        list.current &&
        !list.current.contains(event.target as Node) &&
        event.target !== menu.current
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSelect = (option: any) => {
    setSelectedOption(option);
    setOpen(false);
  };

  const renderOptions = () => {
    return options.map((option: DropdownListOption) => {
      const selected = option?.label === selectedOption?.label;
      const comingSoon = option?.comingSoon;
      return (
        <ListOption
          key={option.label}
          onClick={() => (comingSoon ? null : handleSelect(option))}
          selected={selected}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSelect(option);
            }
          }}
          disabled={!!comingSoon}
        >
          <Option comingSoon={comingSoon}>
            {option.iconLeading ? (
              <img src={option.iconLeading} alt="icon" width={16} height={16} />
            ) : null}
            {option.label} {comingSoon && "(Coming Soon)"}
          </Option>

          {selected && (
            <CheckIcon
              src={CheckBlue}
              alt="check-blue"
              width={20}
              height={20}
            />
          )}
        </ListOption>
      );
    });
  };

  return (
    <DropdownListContainer open={open} style={containerStyle}>
      {label && <Label text={label} />}
      <DropdownListInput
        open={open}
        onClick={() => {
          setOpen(!open);
        }}
        ref={menu}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setOpen(!open);
          }
        }}
        onFocus={onFocus}
        hasError={!!error}
        style={style}
        hint={!!hint}
      >
        <Option>
          {selectedOption?.iconLeading && (
            <img
              src={selectedOption.iconLeading}
              alt="icon"
              width={16}
              height={16}
            />
          )}

          <DropdownListValue size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
            {selectedOption?.label}
          </DropdownListValue>
        </Option>

        <img src={ChevronDown} alt="chevron-down" width={20} height={20} />
      </DropdownListInput>
      <ErrorMessage>{error?.length ? error : hint}</ErrorMessage>
      <List open={open} ref={list}>
        {renderOptions()}
      </List>
    </DropdownListContainer>
  );
};

export default memo(DropdownList);
