import React from "react";
import { Container, Title, Subtext } from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";

const AccountTypeContent: React.FC = () => {
  return (
    <Container>
      <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
        Start your journey with Ovarc
      </Title>
      <Subtext size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
        Choose your account type
      </Subtext>
    </Container>
  );
};

export default AccountTypeContent;
