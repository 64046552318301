import styled from "styled-components";
import Typography from "../../../../components/shared/Typography";
import { Colors, Spaces } from "../../../../constants/styles";

export const PaymentDetailsHeader = styled.div`
  width: 100%;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PaymentDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.bg_active};
  flex: 1;
  padding-inline: ${Spaces.spacing_xl};
  height: 100%;
  overflow-y: auto;
`;
