import React from "react";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { Tab, TabsContainer } from "./styled";
import { TabsProps } from "../../../types/componentsProps";



const Tabs: React.FC<TabsProps> = ({ tabsTitles, activeTab, setActiveTab }) => {
  const renderTabs = () => {
    return tabsTitles.map((title, index) => {
      return (
        <Tab
          key={title}
          size={FontSizes.TX_SM}
          weight={FontWeights.SEMIBOLD}
          active={activeTab === index}
          onClick={() => setActiveTab(index)}
        >
          {title}
        </Tab>
      );
    });
  };
  return <TabsContainer>{renderTabs()}</TabsContainer>;
};

export default Tabs;
