import { CSSProperties } from "react";
import { FontSizes, FontWeights } from "../../../constants/styles";
import Typography from "../Typography";
import { InitialsProfilePicContainer } from "./styled";

const InitialsProfilePic = ({
  fullName,
  style,
}: {
  fullName: string;
  style?: CSSProperties;
}) => {
  const getInitials = (name: string) => {
    const names = name.split(" ");
    return names
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  return (
    <InitialsProfilePicContainer style={style}>
      <Typography size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
        {getInitials(fullName)}
      </Typography>
    </InitialsProfilePicContainer>
  );
};

export default InitialsProfilePic;
