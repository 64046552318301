import styled from "styled-components";
import Typography from "../../shared/Typography";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";

export const InvoiceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
export const InvoiceDetailsHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  display: flex;
  justify-content: space-between;
`;

export const InvoiceDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const InvoiceDetailsSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const InvoiceDetailsHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: ${Spaces.spacing_lg};
`;

export const InvoiceDetailsCards = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 33px;
`;

export const ItemsTableContainer = styled.div`
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  border-collapse: separate;
  position: relative;
  scrollbar-width: none;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  margin-bottom: 13px;
`;

export const TableTextFaded = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TableTextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const Divider = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${Colors.border_secondary};
`;

export const TotalTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin-left: auto;
  gap: ${Spaces.spacing_lg};
`;

export const TotalText = styled(Typography)`
  width: 50%;
  color: ${Colors.text_secondary_on_brand};
`;
