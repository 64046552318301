import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: ${Spaces.spacing_lg};
  width: 30%;
  background-color: #fff;
  padding-inline: ${Spaces.spacing_lg};
  padding-block: ${Spaces.spacing_lg} ${Spaces.spacing_4xl};

  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-radius: ${Radiuses.radius_md};
  height: fit-content;
`;
export const StepContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: ${Spaces.spacing_xl};
  opacity: ${(props) => (props.isActive ? 1 : 0.6)};
  width: 100%;
`;
export const StepVisuals = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xs};
  align-items: center;
`;

export const StepperConnector = styled.div`
  width: 2px;
  height: 24px;
  border-radius: ${Radiuses.radius_xxs};
  background-color: ${Colors.border_secondary};
  display: block;
  margin-block: ${Spaces.spacing_xs};
`;

export const StepIConContainer = styled.div<{ isComplete: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spaces.spacing_lg};
  border-radius: ${Radiuses.radius_lg};
  background-color: ${(props) =>
    props.isComplete ? Colors.success_solid : "transparent"};
  border: 2px solid
    ${(props) =>
      props.isComplete ? "transparent" : Colors.border_disabled_subtle};
  box-sizing: border-box;
`;

export const StepDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-top: ${Spaces.spacing_lg};
`;
