import styled from "styled-components";
import Typography from "../../shared/Typography";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: ${Spaces.spacing_lg};
  width: 30%;
  background-color: #fff;
  padding-inline: ${Spaces.spacing_lg};
  padding-block: ${Spaces.spacing_lg} ${Spaces.spacing_4xl};

  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-radius: ${Radiuses.radius_md};
  height: fit-content;
`;
export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spaces.spacing_xl};
  align-items: flex-start;
  width: 100%;
`;

export const StepTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-top: ${Spaces.spacing_sm};
`;

export const StepIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Spaces.spacing_xs};
`;

export const StepIcon = styled.img<{
  isCurrent: boolean;
}>`
  border-radius: ${Radiuses.radius_full};

  ${(props) =>
    props.isCurrent &&
    `box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
    
    `}
`;

export const StepperConnector = styled.div<{
  isComplete: boolean;
  isLast?: boolean;
}>`
  width: 2px;
  height: 20px;
  background-color: ${Colors.border_secondary};
  border-radius: ${Radiuses.radius_xxs};
  background-color: ${Colors.border_secondary};
  display: block;
  margin-bottom: ${Spaces.spacing_xs};

  ${(props) =>
    props.isComplete &&
    `background-color: ${Colors.bg_brand_solid};
        `}

  ${(props) =>
    props.isLast &&
    `display: none;
        `}
`;
