import styled from "styled-components";
import { Colors, Spaces } from "../../../../constants/styles";
import Typography from "../../../../components/shared/Typography";

export const ContentContainer = styled.div`
   width: 300px;
   display: flex;
   flex-direction: column;
   padding: ${Spaces.spacing_3xl};
   justify-content: center;
   align-items: center;
   gap: ${Spaces.spacing_xl};
`;

export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
  text-align: center;
  white-space: pre-wrap;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const ModalHeader = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  width: 100%;
  justify-content: space-between;
  margin-top: ${Spaces.spacing_4xl};
`;
