import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const ProfileContainer = styled.div`
  width: 100%;
`;

export const ProfileHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors.border_secondary};
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_2xl};
`;
export const ProfilePersonalInfo = styled.div`
  width: 100%;
  display: flex;
  gap: ${Spaces.spacing_2xl};
  align-items: center;
`;

export const ProfileName = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const ProfilePP = styled.div`
  width: 64px;
  height: 64px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.avatar_bg};
  border: 1px solid ${Colors.bg_primary};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`;

export const ProfileHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BadgesContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
`;
