import React, { useEffect, useState } from "react";
import Sidebar from "../SideBar";
import {
  AppContainer,
  AppHeader,
  Information,
  LayoutContainer,
  MenuBurgerContainer,
  NotificationBanner,
  TextBold,
} from "./styled";
import { useAppSelector } from "../../../redux/reduxHooks";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import Menu from "../../../Images/icons/menu.svg";
import VerticalModal from "../VerticalModal";
import WarningIcon from "../../../Images/icons/alertWarning.svg";
import Button from "../Button";
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from "../../../constants/styles";
import MissingUserDataModal from "./MissingUserDataModal";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {loggedIn, user, isMissingUserFields} = useAppSelector((state) => state.auth);
  const {activeCompany} = useAppSelector((state) => state.companies);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [openMissingUserDataModal, setOpenMissingUserDataModal] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      !loggedIn ||
      location.pathname === "/login" ||
      location.pathname.includes("/register/")
    ) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [loggedIn, location.pathname]);

  return (
    <LayoutContainer>
      {showSidebar ? <Sidebar /> : null}
      <AppContainer showSideBar={showSidebar}>
        {showSidebar ? (
          <>
            {activeCompany && isMissingUserFields && user?.type !== "ovarc_admin" && (
              <NotificationBanner>
                <Information>
                  <img src={WarningIcon} alt="warning" />
                  <TextBold size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                    Action Required
                  </TextBold>
                  <TextBold size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                    Please complete missing information.
                  </TextBold>
                </Information>
                <Button
                  text="Update Information"
                  onClick={() => {setOpenMissingUserDataModal(true)}}
                  size={ButtonSizes.sm}
                  variant={ButtonVariants.primary}
                  style={{ width: "fit-content" }}
                />
              </NotificationBanner>
            )}
            <AppHeader>
              <Breadcrumbs />
              <MenuBurgerContainer
                onClick={() => {
                  setShowMenu(true);
                }}
              >
                <img src={Menu} alt="menu" width={30} height={30} />
              </MenuBurgerContainer>
            </AppHeader>
          </>
        ) : null}

        {children}
      </AppContainer>
      {activeCompany && isMissingUserFields && user?.type !== "ovarc_admin" && (
        <MissingUserDataModal open={openMissingUserDataModal} onClose={() => setOpenMissingUserDataModal(false)}/>
      )}
      <VerticalModal open={showMenu} onClose={() => setShowMenu(false)}>
        {showSidebar ? <Sidebar
          style={{
            display: "flex",
            width: "100%",
            borderRight: "none",
            paddingInline: "0px",
            position: "static",
          }}
        /> : null }
      </VerticalModal>
    </LayoutContainer>
  );
};

export default Layout;
