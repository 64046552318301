import styled from "styled-components";
import Typography from "../Typography";
import { Colors, Spaces } from "../../../constants/styles";

export const CopyRightTxt = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  position: absolute;
  bottom: ${Spaces.spacing_4xl};
  left: ${Spaces.spacing_4xl};
  @media (max-height: 700px) {
    display: none;
  }
`;
