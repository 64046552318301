import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { Card, CardRow } from "../../../shared/Card";

export const ConfirmationStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
export const StepTitleCard = styled.div`
  display: flex;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_3xl};
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  margin-bottom: ${Spaces.spacing_md};
`;

export const StepTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ConfirmationCard = styled(Card)`
  margin-block: ${Spaces.spacing_md};
`;

export const ConfirmationCardRow = styled(CardRow)`
  gap: ${Spaces.spacing_md};
`;

export const CheckboxCardRow = styled.div`
  margin: ${Spaces.spacing_lg};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
