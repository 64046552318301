import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../../components/shared/Typography";

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.bg_active};
  flex: 1;
  padding-inline: ${Spaces.spacing_xl};
  height: 100%;
  overflow-y: auto;
`;

export const PaymentHeader = styled.div`
  width: 100%;
`;

export const PaymentTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;