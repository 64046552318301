import React, { useEffect, useState } from "react";
import VerticalModal from "../../shared/VerticalModal";
import {
  AddBonusButton,
  AddReductionButton,
  CloseIcon,
  ModalContentContainer,
  ModalContentHeader,
  ModalContentTitle,
  ModalContentTitleAndImgContainer,
  ProfileLink,
  ReviewContainer,
  ButtonsContainer,
  InputsContainer,
  ModalFooter,
  ModalCardTitle,
} from "./styled";
import {
  Card,
  CardHeader,
  CardRow,
  CardRowValue,
  CardTitle,
} from "../../shared/Card";
import { PayrollReviewEmployee } from "../../../types/payroll";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import External from "../../../Images/icons/link-external-brand.svg";
import Typography from "../../shared/Typography";
import Close from "../../../Images/icons/alertClose.svg";
import moment from "moment";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Plus from "../../../Images/icons/plus-brand.svg";
import Button from "../../shared/Button";
import InputField from "../../shared/InputField";
import { useAddBonusReduction } from "../../../queries/payroll";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { BonusReduction } from "../../../types/payments";

interface EmployeePayrollDetailsModalProps {
  open: boolean;
  onClose: () => void;
  employee: PayrollReviewEmployee | null;
  edit: boolean | false
}

const EmployeePayrollDetailsModal: React.FC<
  EmployeePayrollDetailsModalProps
> = ({ open, onClose, employee, edit }) => {
  const { showSnackbar } = useSnackbar();

  const [openAddBonus, setOpenAddBonus] = useState(false);
  const [openAddReduction, setOpenAddReduction] = useState(false);

  const [bonuses, setBonuses] = useState<BonusReduction[]>([]);
  const [reductions, setReductions] = useState<BonusReduction[]>([]);

  const [newBonuses, setNewBonuses] = useState<BonusReduction[]>([]);
  const [newReductions, setNewReductions] = useState<BonusReduction[]>([]);
  
  const queryClient = useQueryClient();
  const { mutateAsync: addBonusReduction } = useAddBonusReduction();

  const [formData, setFormData] = useState({
    bonus: {
      name: "",
      amount: 0
    },
    reduction: {
      name: "",
      amount: 0
    }
  })

  const [formsError, setFormsError] = useState({
    bonus: {name: "", amount: ""},
    reduction: {name: "", amount: ""}
  })


  useEffect(() => {
    if(employee) {
      setBonuses(employee.bonuses);
      setReductions(employee.reductions);
    }
  }, [employee]);

  const handleClose = () => {
    setOpenAddBonus(false);
    setOpenAddReduction(false);
    setFormData({
      bonus: {
        name: "",
        amount: 0,
      },
      reduction: {
        name: "",
        amount: 0,
      }
    })
    setNewBonuses([]);
    setNewReductions([]);
    onClose();
  }

  const validateBonus = () => {
    if(formData.bonus.name === "" && formData.bonus.amount === 0) {
      setFormsError((prevData) => ({
        ...prevData, 
        bonus: {
          name: "Name can't be empty",
          amount: "Amount can't be 0"
        }
      }));
      return false;
    }
    else if(formData.bonus.name === "") {
      setFormsError((prevData) => ({
        ...prevData, 
        bonus: {
          ...prevData.bonus,
          name: "Name can't be empty"
        }
      }));
      return false;
    }
    else if(formData.bonus.amount === 0) {
      setFormsError((prevData) => ({
        ...prevData, 
        bonus: {
          ...prevData.bonus,
          amount: "Amount can't be 0"
        }
      }));
      return false;
    }
    else return true;
  }

  const validateReduction = () => {
    if(formData.reduction.name === "" && formData.reduction.amount === 0) {
      setFormsError((prevData) => ({
        ...prevData, 
        reduction: {
          name: "Name can't be empty",
          amount: "Amount can't be 0"
        }
      }));
      return false;
    }
    else if(formData.reduction.name === "") {
      setFormsError((prevData) => ({
        ...prevData, 
        reduction: {
          ...prevData.reduction,
          name: "Name can't be empty"
        }
      }));
      return false;
    }
    else if(formData.reduction.amount === 0) {
      setFormsError((prevData) => ({
        ...prevData, 
        reduction: {
          ...prevData.reduction,
          amount: "Amount can't be 0"
        }
      }));
      return false;
    }
    else return true;
  }

  const handleAddBonus = () => {
    const validated = validateBonus();
    if(validated) {
      setOpenAddBonus(false);
      setNewBonuses((prevData) => [...prevData, formData.bonus])
      setBonuses((prevData) => [...prevData, formData.bonus])
    }
  }

  const handleAddReduction = () => {
    const validated = validateReduction();
    if(validated) {
      setOpenAddReduction(false);
      setNewReductions((prevData) => [...prevData, formData.reduction])
      setReductions((prevData) => [...prevData, formData.reduction])
    }
  }

  const handleAddBonusReductions = async () => {
    if(!(openAddBonus || openAddReduction)) {
      try{ 
        await addBonusReduction({
          id: employee?.id,
          bonuses: newBonuses,
          reductions: newReductions
        })
        queryClient.invalidateQueries({ queryKey: ["payrollReviewEmployees"] });
        handleClose();
        showSnackbar({
          type: SnackbarTypes.SUCCESS,
          open: true,
          title: "Data added successfully.",
          description: "",
        });
      } catch (err) {
        handleClose();
        showSnackbar({
          type: SnackbarTypes.ERROR,
          open: true,
          title: "Data failed to be added.",
          description: "",
        });
      }
    }
  }

  return (
    <VerticalModal open={open} onClose={handleClose}>
      <ModalContentContainer>
        <ModalContentHeader>
          <ModalContentTitleAndImgContainer>
            <InitialsProfilePic
              fullName={`${employee?.first_name} ${employee?.last_name}`}
            />
            <div>
              <ModalContentTitle
                size={FontSizes.TX_XL}
                weight={FontWeights.SEMIBOLD}
              >
                {employee?.first_name} {employee?.last_name}
              </ModalContentTitle>
              <ProfileLink href={`/people/profile/${employee?.id}/contract`}>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                  View Contract
                </Typography>
                <img
                  src={External}
                  alt="external link"
                  width={16}
                  height={16}
                />
              </ProfileLink>
            </div>
            <CloseIcon src={Close} alt="close" onClick={onClose} />
          </ModalContentTitleAndImgContainer>
        </ModalContentHeader>

        <ReviewContainer>
          <Card>
            <CardHeader>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Contract Details
              </ModalCardTitle>
            </CardHeader>
            <CardRow>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Employee Status
              </ModalCardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {employee?.contract.status}
              </CardRowValue>{" "}
            </CardRow>

            <CardRow>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Payroll Cycle
              </ModalCardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {`${moment(employee?.pay_period_start).format(
                  "DD MMM"
                )} - ${moment(employee?.pay_period_end).format(
                  "DD MMM"
                )}`}
              </CardRowValue>{" "}
            </CardRow>
          </Card>

          <Card>
            <CardHeader>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Salary
              </ModalCardTitle>
            </CardHeader>
            <CardRow>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Rate
              </ModalCardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(employee?.contract_salary, employee?.currency)}
              </CardRowValue>{" "}
            </CardRow>

            <CardRow>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Pay Per
              </ModalCardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Monthly              
              </CardRowValue>
            </CardRow>
          </Card>

          <Card>
            <CardHeader>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Bonus
              </ModalCardTitle>

              {edit && !openAddBonus &&(
                <AddBonusButton
                  onClick={() => {
                    setOpenAddBonus(true);
                  }}
                >
                  <img src={Plus} alt="plus" width={20} height={20} />

                  <Typography
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    Add Bonus
                  </Typography>
                </AddBonusButton>
              )}
            </CardHeader>
            {bonuses.map((bonus, index) => (
              <CardRow key={index}>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  {bonus.name}
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(bonus.amount, employee?.currency)}
                </CardRowValue>{" "}
              </CardRow>
            ))}
            {openAddBonus && (
              <>
                <InputsContainer>
                  <InputField
                    type="text"
                    placeholder="Bonus Name"
                    containerStyle={{width: "100%"}}
                    value={formData.bonus.name}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        bonus: {
                          ...prevData.bonus,
                          name: e.target.value
                        }
                      }))
                    }}
                    error={formsError.bonus.name}
                    onFocus={() => {
                      setFormsError((prevError) => ({
                        ...prevError,
                        bonus: { ...prevError.bonus, name: "" }
                      }));
                    }}
                  />
                  <InputField
                    type="number"
                    placeholder="Bonus Amount"
                    containerStyle={{width: "100%"}}
                    value={formData.bonus.amount}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        bonus: {
                          ...prevData.bonus,
                          amount: parseFloat(e.target.value)
                        }
                      }))
                    }}
                    error={formsError.bonus.amount}
                    onFocus={() => {
                      setFormsError((prevError) => ({
                        ...prevError,
                        bonus: { ...prevError.bonus, amount: "" }
                      }));
                    }}
                  />
                </InputsContainer>

                <ButtonsContainer>
                  <Button
                    text="Cancel"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.secondary_gray}
                    onClick={() => {
                      setOpenAddBonus(false);
                    }}
                  />
                  <Button
                    text="Add"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.primary}
                    onClick={() => {
                      handleAddBonus();
                    }}
                  />
                </ButtonsContainer>
              </>
            )}
          </Card>

          <Card>
            <CardHeader>
              <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Deductions
              </ModalCardTitle>

              {edit && !openAddReduction && (
                <AddReductionButton
                  onClick={() => {
                    setOpenAddReduction(true);
                  }}
                >
                  <img src={Plus} alt="plus" width={20} height={20} />

                  <Typography
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    Add Deduction
                  </Typography>
                </AddReductionButton>
              )}
            </CardHeader>
            {reductions.map((reduction, index) => (
              <CardRow key={index}>
                <ModalCardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  {reduction.name}
                </ModalCardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(reduction.amount, employee?.currency)}
                </CardRowValue>{" "}
              </CardRow>
            ))}
            {openAddReduction && (
              <>
                <InputsContainer>
                  <InputField
                    type="text"
                    placeholder="Deduction Name"
                    containerStyle={{width: "100%"}}
                    value={formData.reduction.name}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        reduction: {
                          ...prevData.reduction,
                          name: e.target.value
                        }
                      }))
                    }}
                    error={formsError.reduction.name}
                    onFocus={() => {
                      setFormsError((prevError) => ({
                        ...prevError,
                        reduction: { ...prevError.reduction, name: "" }
                      }));
                    }}
                  />
                  <InputField
                    type="number"
                    placeholder="Deduction Amount"
                    containerStyle={{width: "100%"}}
                    value={formData.reduction.amount}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        reduction: {
                          ...prevData.reduction,
                          amount: parseFloat(e.target.value)
                        }
                      }))
                    }}
                    error={formsError.reduction.amount}
                    onFocus={() => {
                      setFormsError((prevError) => ({
                        ...prevError,
                        reduction: { ...prevError.reduction, amount: "" }
                      }));
                    }}
                  />      
                </InputsContainer>

                <ButtonsContainer>
                  <Button
                    text="Cancel"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.secondary_gray}
                    onClick={() => {
                      setOpenAddReduction(false);
                    }}
                  />
                  <Button
                    text="Add"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.primary}
                    onClick={() => {
                      handleAddReduction();
                    }}
                  />
                </ButtonsContainer>
              </>
            )}
          </Card>

          <ModalFooter>
            {edit && (
              <Button
                text="Update"
                size={ButtonSizes.md}
                variant={ButtonVariants.primary}
                disabled={!(newBonuses.length > 0 || newReductions.length > 0)}
                onClick={() => {
                  handleAddBonusReductions();
                }}
              />
            )}
          </ModalFooter>
        </ReviewContainer>
      </ModalContentContainer>
    </VerticalModal>
  );
};

export default EmployeePayrollDetailsModal;
