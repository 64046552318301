import React from "react";
import {
  PaymentEmptyStateContainer,
  PaymentEmptyStateDescription,
  PaymentEmptyStateTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Image from "../../../Images/payollEmptyState.svg";
import Button from "../../shared/Button";

const PaymentEmptyState: React.FC = () => {
  return (
    <PaymentEmptyStateContainer>
      <div>
        <PaymentEmptyStateTitle
          size={FontSizes.XS}
          weight={FontWeights.SEMIBOLD}
        >
          Effortlessly monitor and manage {"\n"} your payments
        </PaymentEmptyStateTitle>

        <PaymentEmptyStateDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.MEDIUM}
        >
          Here you can easily identify and pay outstanding invoices and access a detailed payment {"\n"} history for your records.
        </PaymentEmptyStateDescription>
      </div>
      <img src={Image} alt="Payroll Empty State" width={326} height={240} />
    </PaymentEmptyStateContainer>
  );
};

export default PaymentEmptyState;
