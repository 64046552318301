import {
  ContentContainer,
  ModalDescription,
  ModalFooter,
  ModalTitle,
} from "./styled";

import { ModalProps, SnackbarTypes } from "../../../types/componentsProps";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import ModalIcon from "../../../Images/icons/alert-circle-warning.svg";
import { useNavigate } from "react-router-dom";
import { PAYMENTS_SCREEN } from "../../../constants/routesNames";
import { useSubmitPayroll } from "../../../queries/payroll";
import { useEffect } from "react";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { PayrollDetails } from "../../../types/payroll";
import { useQueryClient } from "@tanstack/react-query";

const SubmitPayrollReportModal = ({
  open,
  onClose,
  payroll
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  payroll: PayrollDetails | null;
}) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { mutateAsync: submitPayroll, error } = useSubmitPayroll();

  const handleSubmission = async () => {
    if(payroll) {
      try{ 
        await submitPayroll(payroll.id);
        queryClient.invalidateQueries({
          queryKey: ["payrollDetails"],
        });
        queryClient.invalidateQueries({
          queryKey: ["payrolls"],
        });
        showSnackbar({
          type: SnackbarTypes.SUCCESS,
          open: true,
          title: "Success",
          description: "Payroll Pending Payment",
        });
        onClose();
        navigate(`/payroll/payroll-details/${payroll.id}`);
      } catch(err) {
        onClose();
        showSnackbar({
          type: SnackbarTypes.ERROR,
          open: true,
          title: "Something went wrong while submitting payroll.",
          description: "Please try again later.",
        });
      }
    }
  }

  return (
    <Modal onClose={onClose} open={open}>
      <ContentContainer>
        <img src={ModalIcon} alt="alert-icon" />
        <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Submit payroll report?
        </ModalTitle>
        <ModalDescription size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          Once submitted, you will not be able to make{`\n`} changes.
        </ModalDescription>
        <ModalFooter>
          <Button
            text="Go back"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={onClose}
          />

          <Button
            text="Submit Payroll"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmission();
            }}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};

export default SubmitPayrollReportModal;
