import { createElement } from "react";
import styled from "styled-components";
import { FontSizes, FontWeights, Spaces } from "../../../constants/styles";

export const DynamicTypography = styled(
  ({
    size,
    weight,
    children,
    ...props
  }: {
    size?: FontSizes;
    weight?: FontWeights;
    children: React.ReactNode;
  }) => createElement("p", props, children)
)`
  margin-block: ${Spaces.spacing_none};
  font-size: ${(props) => {
    return props.size ?? FontSizes.TX_LG;
  }};
  font-weight: ${(props) => {
    return props.weight ?? FontWeights.REGULAR;
  }};

  line-height: ${(props) => {
    switch (props.size) {
      case FontSizes.XXL:
        return "4.5rem";
      case FontSizes.XL:
        return "3.75rem";
      case FontSizes.LG:
        return "2.75rem";
      case FontSizes.MD:
        return "2.37rem";
      case FontSizes.SM:
        return "2rem";
      case FontSizes.XS:
        return "1.875rem";
      case FontSizes.TX_XL:
        return "1.75rem";
      case FontSizes.TX_LG:
        return "1.5rem";
      case FontSizes.TX_MD:
        return "1.25rem";
      case FontSizes.TX_SM:
        return "1.125rem";
      case undefined:
        return "1.5rem";
    }
  }};
`;
