import styled from "styled-components";
import { Breakpoints, Colors, Spaces } from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Typography from "../../../shared/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spaces.spacing_4xl};
  flex: 1;
  padding-right: ${Spaces.spacing_6xl};
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  background-color: ${Colors.bg_secondary_hover};

  @media screen and (max-width: ${Breakpoints.tablet}) {
    justify-content: flex-start;

    padding-inline: ${Spaces.spacing_4xl};
    padding-block: ${Spaces.spacing_4xl};
    width: 100%;
    height: 100vh;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
  flex-wrap: wrap;
  width: 47.6%;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    width: 100%;
  }
`;

export const Title = styled(Typography)`
  color: ${Colors.text_brand_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const SubTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-bottom: ${Spaces.spacing_md};
`;

export const NextButton = styled(Button)`
  margin-top: ${Spaces.spacing_lg};
  align-self: flex-end;
  width: 48%;
`;
