import { Container, Logo, Subtext, Title, LinkButton } from "./styled";
import LogoImg from "../../Images/icons/LogoColored.svg";
import Image from "../../Images/mobileViewImg.svg";
import { FontSizes, FontWeights } from "../../constants/styles";
import Typography from "../../components/shared/Typography";

const MobileView = () => {
  return (
    <Container>
      <Logo src={LogoImg} alt="Logo" width={146} height={46} />
      <img src={Image} alt="Mobile View" width={250} height={250} />
      <Title size={FontSizes.XS} weight={FontWeights.BOLD}>
        Hey there! We're currently{"\n"}working on enhancing our mobile{"\n"}{" "}
        experience.
      </Title>
      <Subtext size={FontSizes.XS} weight={FontWeights.MEDIUM}>
        Thanks a bunch for your patience!
      </Subtext>
      <LinkButton href="https://www.ovarc.io/">
        <Typography size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Visit our website
        </Typography>
      </LinkButton>
    </Container>
  );
};

export default MobileView;
