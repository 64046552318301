import { DashboardBanner } from "../types";
import BenefitsImg from "../Images/benifitsBannerImg.svg";
import WorkplaceImg from "../Images/workplaceBannerImg.svg";
import { Colors } from "./styles";

const openMailer = (subject: string) => {
  const email = "hello@ovarc.io";
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  window.location.href = mailtoLink;
};

export const DashboardBanners: DashboardBanner[] = [
  {
    id: "1",
    title: "Setup your Benefits & Perks",
    description: "Browse our wide range of benefits in Benefits Hub",
    btnText: "Benefits Hub",
    onClick: () => openMailer("Setting up Benefits & Perks"),
    color: Colors.Blue_light_50,
    image: <img src={BenefitsImg} width={156} height={107} />,
  },
  {
    id: "2",
    title: "Tailor Your Workspace",
    description: "Explore tools and resources to customize your workspace for optimal productivity.",
    btnText: "Assets Hub",
    onClick: () => openMailer("Assets Hub Inquiry"),
    color: Colors.Error_25,
    image: <img src={WorkplaceImg} width={156} height={107} />,
  },
];
