import { useMutation, useQuery } from "@tanstack/react-query";
import { GetPayrollListParams, GetPayrollListResponse } from "../../types/apisTypes";
import { apiClient } from "../../queries/apiClient";
import qs from "qs";

export const useGetAdminPayrolls = (params: GetPayrollListParams) => {
  return useQuery<GetPayrollListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/admin/payroll?${qs.stringify(params)}`);
      return response.data;
    },
    retry: false,

    queryKey: ["payrolls", params],
  });
};

export const useProcessPayrollAdmin = () => {
  return useMutation<void, Error, number | undefined>({
    mutationFn: async (id) => {
      const response = await apiClient.post(`/admin/process_payroll/${id}`);
      return response.data;
    },
  });
};

export const useGetAdminPayrollEmployeesCsv = ({ids, enabled} : {ids: number[] | undefined, enabled: boolean}) => {
  return useQuery<void, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/admin/payroll_employee_csv?ids=[${ids?.join(',')}]`, {
        responseType: 'arraybuffer',
      });
      return response.data;
    },
    enabled: enabled && ids?.length != 0,
    retry: false,
    queryKey: ["payroll_employees_csv",ids, enabled],
  });
};