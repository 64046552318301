import { useEffect, useState } from "react";
import {
  Container,
  InvitedBadge,
  PeopleDataContainer,
  PeopleDataHeader,
  PeopleDataHeaderTitle,
  PeopleDataList,
  PeopleDataListItem,
  PeopleDataListItemFirstRow,
  PeopleDataListItemImg,
  PeopleDataListItemInfo,
  PeopleDataListItemTitle,
  PeopleNumberContainer,
  Dot,
  PeopleDataListItemExtraInfo,
  Arrow,
  DetailsContainer,
  CloseBtn,
  ViewProfileBtn,
  Banner,
  BannerTitle,
  BannerDescription,
  BannerBtn,
  DataRow,
  DataLabel,
  DataValue,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import ArrowRight from "../../../../Images/icons/arrowRightBlue.svg";
import moment from "moment";
import CloseIcon from "../../../../Images/icons/bannerCloseIcon.svg";
import {
  useCancelContract,
  useGetContractsToOnboard,
} from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import PeopleEmptyState from "../../PeopleEmptyState";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";

const Onboarding = () => {
  const [extendedItem, setExtendedItem] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const { data: peopleToOnboard, error } = useGetContractsToOnboard();
  const { mutateAsync: cancelContract } = useCancelContract();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        title: "Error fetching data",
        description: "",
        open: true,
      });
    }
  }, [error]);

  const onCancelContract = async (id: string) => {
    try {
      await cancelContract(id);
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        title: "Contract cancelled",
        description: "",
        open: true,
      });
      queryClient.invalidateQueries({ queryKey: ["contractsToOnboard"] });
      setExtendedItem(null);
    } catch (error) {
      const status = (error as AxiosError)?.response?.status;
      const isCustomError = status && status > 404 && status < 500;
      const customErrorMessage =
        //@ts-ignore
        (error as AxiosError)?.response?.data?.message ||
        "Please contact support";
      showSnackbar({
        type: SnackbarTypes.ERROR,
        title: isCustomError ? customErrorMessage : "Error cancelling contract",
        description: "",
        open: true,
      });
    }
  };

  const renderPeopleDataList = () => {
    return peopleToOnboard?.map((person, index) => {
      return (
        <PeopleDataListItem key={person.id} active={extendedItem === index}>
          <PeopleDataListItemImg>
            <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.employee.user.first_name[0] +
                person.employee.user.last_name[0]}
            </Typography>
          </PeopleDataListItemImg>
          <PeopleDataListItemInfo>
            <PeopleDataListItemFirstRow>
              <PeopleDataListItemTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {person.employee.user.first_name}{" "}
                {person.employee.user.last_name}
              </PeopleDataListItemTitle>
              <InvitedBadge>
                <Dot />
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  {person.status}
                </Typography>
              </InvitedBadge>
            </PeopleDataListItemFirstRow>
            <PeopleDataListItemExtraInfo
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              {person.type} Employee . Starts on{" "}
              {moment(new Date(person.start_date)).format("D MMM, YYYY")}
            </PeopleDataListItemExtraInfo>
          </PeopleDataListItemInfo>
          <Arrow
            src={ArrowRight}
            alt="Arrow Right"
            onClick={() =>
              setExtendedItem(extendedItem === index ? null : index)
            }
            active={extendedItem === index}
          />
        </PeopleDataListItem>
      );
    });
  };

  const getBannerText = (status: string, created_at: string) => {
    switch (status) {
      case "Pending Invite":
        return {
          title: "This employee has not signed the Ovarc contract yet",
          description: `The contract was created on ${moment(created_at).format(
            "MMM DD, YYYY"
          )} and now it's ready to sign. An Ovarc admin will contact the the employee`,
        };
      case "Invited":
        return {
          title: "This employee has been invited to Ovarc",
          description: `The contract was created on ${moment(created_at).format(
            "MMM DD, YYYY"
          )} and now it's ready to sign.`,
        };
      case "Onboarding":
        return {
          title: "This employee is being onboarded",
          description:
            "The employee has been invited to Ovarc and is currently fulfilling the onboarding requirements.",
        };
    }
  };

  const renderExtendedItem = () => {
    if (extendedItem !== null && peopleToOnboard) {
      const person = peopleToOnboard?.[extendedItem];
      const { title, description } = getBannerText(
        person.status,
        person?.created_at
      ) || { title: "", description: "" };

      return (
        <DetailsContainer>
          <CloseBtn
            src={CloseIcon}
            alt="Close Icon"
            onClick={() => setExtendedItem(null)}
            width={20}
            height={20}
          />
          <PeopleDataHeader style={{ width: "100%" }}>
            <PeopleDataListItemImg style={{ width: "42px", height: "42px" }}>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {person.employee.user.first_name[0] +
                  person.employee.user.last_name[0]}
              </Typography>
            </PeopleDataListItemImg>
            <div>
              <PeopleDataListItemTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {person.employee.user.first_name}{" "}
                {person.employee.user.last_name}
              </PeopleDataListItemTitle>
              <PeopleDataListItemExtraInfo
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {person.type} Employee
              </PeopleDataListItemExtraInfo>
            </div>
            <ViewProfileBtn
              size={ButtonSizes.sm}
              variant={ButtonVariants.secondary_color}
              onClick={() => navigate(`/people/profile/${person.employee.id}`)}
              text="View Profile"
            />
          </PeopleDataHeader>
          <Banner>
            <BannerTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {title}
            </BannerTitle>

            <BannerDescription
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              {description}
            </BannerDescription>
            <BannerBtn
              size={ButtonSizes.sm}
              variant={ButtonVariants.tertiary_color}
              text="Discard Contract"
              onClick={() => {
                onCancelContract(person.id);
              }}
            />
          </Banner>

          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Start Date
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {moment(new Date(person.start_date)).format("D MMM, YYYY")}
            </DataValue>
          </DataRow>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Location of work
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.employee.nationality}
            </DataValue>
          </DataRow>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payroll frequency
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Monthly
            </DataValue>
          </DataRow>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Job title
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.job_title}
            </DataValue>
          </DataRow>
        </DetailsContainer>
      );
    }
  };

  return !peopleToOnboard ? null : peopleToOnboard?.length !== 0 ? (
    <Container>
      <PeopleDataContainer>
        <PeopleDataHeader>
          <PeopleDataHeaderTitle
            size={FontSizes.TX_MD}
            weight={FontWeights.SEMIBOLD}
          >
            People
          </PeopleDataHeaderTitle>
          <PeopleNumberContainer>
            <Typography size={FontSizes.TX_XS} weight={FontWeights.SEMIBOLD}>
              {peopleToOnboard?.length}
            </Typography>
          </PeopleNumberContainer>
        </PeopleDataHeader>
        <PeopleDataList>{renderPeopleDataList()}</PeopleDataList>
      </PeopleDataContainer>
      {renderExtendedItem()}
    </Container>
  ) : (
    <PeopleEmptyState
      title="You don’t have any onboarding contracts"
      description="Onboarded employees have completed all their onboarding tasks.  You can view contacts from employees profile."
      btnText="View People"
      onBtnClick={() => navigate("/people/manage")}
    />
  );
};

export default Onboarding;
