import React from "react";
import {
    FontSizes,
    FontWeights,
} from "../../../constants/styles";
import { BadgeTypes } from "../../../types/componentsProps";
import Badge from "../../shared/Badge";
import { ActionCardContainer, ActionCardTitle } from "../ActionCard/styled";
import {
    CardDataContainer,
    CardHeader,
} from "./styled";

interface ComingSoonCardProps {
    title: string;
}

const ComingSoonCard: React.FC<ComingSoonCardProps> = ({ title }) => {
    return (
        <ActionCardContainer>
            <CardHeader>
                <ActionCardTitle size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
                    {title}
                </ActionCardTitle>
            </CardHeader>
            <CardDataContainer>
                <Badge text="Coming Soon" color={BadgeTypes.blueLight} />
            </CardDataContainer>
        </ActionCardContainer>
    )
};

export default ComingSoonCard;
