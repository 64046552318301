import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import {
  AgreementLink,
  AssetContainer,
  AssetsAgreementWarning,
  ConfirmButton,
  ReviewStepButtonsContainer,
  ReviewStepContainer,
  WarningText,
} from "./styled";
import LeftArrow from "../../../../Images/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { onPreviousRequestAssetStep } from "../../../../redux/slices/assetsHubSlice";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import Warning from "../../../../Images/icons/alertWarning.svg";
import { useRequestAssets } from "../../../../queries/assetsHub";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useNavigate } from "react-router-dom";
const ReviewStep = () => {
  const { requestedAssets } = useAppSelector((state) => state.assetsHub);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutateAsync: requestAssets } = useRequestAssets({
    location: requestedAssets.location,
    assignee: requestedAssets.assignee.value,
    assets: requestedAssets.assets.map((asset) => ({
      category: asset.category.label,
      brand: asset.brand,
      model: asset.model,
      specifications: asset.specifications,
    })),
  });

  const onConfirmRequest = async () => {
    try {
      await requestAssets();
      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Request Sent successfully",
        description:
          "Your Assets request has been sent successfully. You will be notified once the request is approved.",
      });

      navigate("/assets-hub");
    } catch (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description:
          "An error occurred while sending the request. Please try again later.",
      });
    }
  };

  const renderAssets = () => {
    return requestedAssets.assets.map((asset, index) => {
      return (
        <CardRow key={index}>
          <AssetContainer>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.BOLD}>
              {asset.model}
            </CardRowTitle>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Brand: {asset.brand}
            </CardRowTitle>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Category: {asset.category.value}
            </CardRowTitle>
          </AssetContainer>
        </CardRow>
      );
    });
  };
  return (
    <ReviewStepContainer>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Assets Agreement
        </CardTitle>
        <AssetsAgreementWarning>
          <img src={Warning} alt="warning" />
          <WarningText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            This Asset agreement will be automatically sent to the employee, to
            decrease your exposure to potential employee misclassification. They
            will need to sign it before these items can be marked as supplied.
          </WarningText>
        </AssetsAgreementWarning>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Equipment Agreement
          </CardRowTitle>
          <AgreementLink
            download
            target="_blank"
            href="https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf"
          >
            View Agreement
          </AgreementLink>
        </CardRow>
      </Card>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Requested Asset(s)
        </CardTitle>
        {renderAssets()}
      </Card>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Location and Assignment
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Location
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {requestedAssets.location}
          </CardRowValue>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Assigned To
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {requestedAssets.assignee.label}
          </CardRowValue>
        </CardRow>
      </Card>
      <ReviewStepButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={LeftArrow} alt="left arrow" />}
          onClick={() => {
            dispatch(onPreviousRequestAssetStep());
          }}
        />
        <ConfirmButton
          text="Confirm & Request"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => {
            onConfirmRequest();
          }}
        />
      </ReviewStepButtonsContainer>
    </ReviewStepContainer>
  );
};

export default ReviewStep;
