import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import { Table } from "../../../components/shared/Table/styled";
import Typography from "../../../components/shared/Typography";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: ${Spaces.spacing_xl};
  gap: ${Spaces.spacing_lg}
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_lg} ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
`;

export const CompaniesTable = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  border-collapse: collapse;
  position: relative;
  scrollbar-width: none;
`;

export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;