import React, { useEffect, useState } from "react";
import {
  CompaniesContainer,
  CompaniesHeader,
  CompaniesList,
  CompaniesTitle,
  CompanyCard,
  CompanyCardTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import { Company } from "../../../types/companies";
import CompanyDetailsModal from "./CompanyDetailsModal";
import AddCompanyModal from "./AddCompanyModal";
import { useGetUserCompanies } from "../../../queries/companies";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useSnackbar } from "../../../hooks/useSnackbar";
import InitialsProfilePic from "../../shared/InitialsProfilePic";


const Companies: React.FC = () => {
  const { showSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const [chosenCompany, setChosenCompany] = useState<Company | null>(null);
  const { data: companies, error } = useGetUserCompanies();

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description:
          "An error occurred while fetching the data. Please try again later.",
      });
    }
  }, [error]);

  const renderCompanies = () => {
    return companies?.map((company: Company) => {
      return (
        <CompanyCard
          key={company.name}
          onClick={() => {
            setChosenCompany(company);
            setOpen(true);
          }}
        >
          <InitialsProfilePic fullName={company.name} />
          <CompanyCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {company.name}
          </CompanyCardTitle>
        </CompanyCard>
      );
    });
  };

  return (
    <CompaniesContainer>
      <CompaniesHeader>
        <CompaniesTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Companies
        </CompaniesTitle>
        <Button
          text={"Add Company"}
          variant={ButtonVariants.link_color}
          size={ButtonSizes.md}
          style={{ color: "#2191F9" }}
          onClick={() => {
            setOpenAddCompany(true);
          }}
        />
      </CompaniesHeader>
      <CompaniesList>
        {companies?.length ?? 0 > 0 ? renderCompanies() : null}
      </CompaniesList>
      <CompanyDetailsModal
        open={open}
        company={chosenCompany}
        onClose={() => {
          setOpen(false);
          setChosenCompany(null);
        }}
      />
      <AddCompanyModal
        open={openAddCompany}
        company={chosenCompany}
        onClose={() => setOpenAddCompany(false)}
      />
    </CompaniesContainer>
  );
};

export default Companies;
