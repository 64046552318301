import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { Card, CardRow, CardRowTitle, CardTitle } from "../../../shared/Card";
import {
  AgreementLink,
  AssetsAgreementWarning,
  ConfirmButton,
  ReviewStepButtonsContainer,
  ReviewStepContainer,
  WarningText,
} from "./styled";
import Warning from "../../../../Images/icons/alertWarning.svg";
import Button from "../../../shared/Button";
import LeftArrow from "../../../../Images/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import {
  onPreviousAddAssetStep,
  resetToBeAddedAsset,
} from "../../../../redux/slices/assetsHubSlice";
import { useAddAsset } from "../../../../queries/assetsHub";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { SnackbarTypes } from "../../../../types/componentsProps";

const ReviewStep = () => {
  const dispatch = useAppDispatch();
  const AssetData = useAppSelector((state) => state.assetsHub.toBeAddedAsset);
  const { mutateAsync: addAsset, error } = useAddAsset({
    ...AssetData,
    info: {
      ...AssetData.info,
      category: AssetData.info.category.value,
      assignee: AssetData.info.assignee.value,
    },
  });
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onAddAsset = async () => {
    try {
      const { data } = await addAsset();
      dispatch(resetToBeAddedAsset());
      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Asset Added successfully",
        description:
          "Your Asset has been added successfully. You will be notified once the request is approved.",
      });

      navigate(`/assets-hub/asset-details/${data.asset_id}`);
    } catch (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description:
          "An error occurred while adding the asset. Please try again later.",
      });
    }
  };
  const renderAssetInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Asset Information
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Name
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.name}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            ID/Tag
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.id}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Serial Number
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.serialNumber}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Category/Type
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.category.label}
          </CardRowTitle>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Brand
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.brand}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Model/Make
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.model}
          </CardRowTitle>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Specification
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.specifications}
          </CardRowTitle>
        </CardRow>
      </Card>
    );
  };

  const renderLocationInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Location and Assignment
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Location
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.location}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Assigned To
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.assignee.label}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Installation Date
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.installationDate}
          </CardRowTitle>
        </CardRow>
      </Card>
    );
  };
  const renderWarrantyInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Warranty And Supplier Information
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Warranty Period
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.warrantyPeriod}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Supplier Name
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.supplier}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Supplier Contact information
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.supplierContact}
          </CardRowTitle>
        </CardRow>
      </Card>
    );
  };

  const renderMaintenanceInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Maintenance and Service Records
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Maintenance Schedule
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.maintenancePeriod}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Last Service on
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.lastMaintenance}
          </CardRowTitle>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Service Provider
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.maintenanceProvider}
          </CardRowTitle>
        </CardRow>
      </Card>
    );
  };

  const renderDisposalInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Disposal Information
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Disposal Date
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.disposalDate}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Method of Disposal
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.disposalMethod}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Reason of Disposal
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.info.disposalReason}
          </CardRowTitle>
        </CardRow>
      </Card>
    );
  };

  const renderFinancialInfo = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Financial Information
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Purchase Date
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.financialInfo.purchaseDate}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Purchase Price
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.financialInfo.purchasePrice}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Depreciation Method
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.financialInfo.deprecationMethod}
          </CardRowTitle>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Current Value
          </CardRowTitle>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {AssetData.financialInfo.currentValue}
          </CardRowTitle>
        </CardRow>
      </Card>
    );
  };
  return (
    <ReviewStepContainer>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Equipment Agreement
        </CardTitle>
        <AssetsAgreementWarning>
          <img src={Warning} alt="warning" />
          <WarningText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            This equipment agreement will be automatically sent to the employee,
            to decrease your exposure to potential employee misclassification.
            They will need to sign it before these items can be marked as
            supplied.
          </WarningText>
        </AssetsAgreementWarning>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Equipment Agreement
          </CardRowTitle>
          <AgreementLink
            download
            target="_blank"
            href="https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf"
          >
            View Agreement
          </AgreementLink>
        </CardRow>
      </Card>
      {renderAssetInfo()}
      {renderLocationInfo()}
      {renderWarrantyInfo()}
      {renderMaintenanceInfo()}
      {renderDisposalInfo()}
      {renderFinancialInfo()}
      <ReviewStepButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={LeftArrow} alt="left arrow" />}
          onClick={() => {
            dispatch(onPreviousAddAssetStep());
          }}
        />
        <ConfirmButton
          text="Confirm & Add"
          size={ButtonSizes.lg}
          variant={ButtonVariants.primary}
          onClick={() => {
            onAddAsset();
          }}
        />
      </ReviewStepButtonsContainer>
    </ReviewStepContainer>
  );
};

export default ReviewStep;
