import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../queries/apiClient";
import qs from "qs";
import {
  GetUpdateEmployeeParams,
  GetEmployeesParams,
  GetEmployeesResponse,
} from "../../types/apisTypes";
import { Company } from "../../types/companies";

export const useGetEmployeeListAdmin = (params: GetEmployeesParams) => {
  return useQuery<GetEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/admin/employees?${qs.stringify({
          ...params,
        })}`
      );
      return response.data;
    },
    retry: false,
    queryKey: ["employeesListAdmin", params],
  });
};

export const useUpdateEmployee = () => {
  return useMutation<void, Error, GetUpdateEmployeeParams>({
    mutationFn: async (data) => {
      const response = await apiClient.post(
        `/admin/update_employee`,
        data
      );
      return response.data;
    },
  });
};