import { FontSizes, FontWeights } from "../../../../constants/styles";
import { StepperProps } from "../../../../types/componentsProps";
import {
  StepperWrapper,
  StepContainer,
  StepVisuals,
  StepperConnector,
  StepIConContainer,
  StepDataContainer,
  StepTitle,
  StepDescription,
} from "./styled";

const NewEmployeeContractStepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
}) => {
  const renderSteps = () => {
    return steps.map((step, index) => {
      return (
        <StepContainer isActive={index === currentStep}>
          <StepVisuals>
            <StepIConContainer isComplete={step.completed || index === 0}>
              <img
                src={
                  step.completed && index !== 0 ? step.completedIcon : step.icon
                }
                alt={step.title}
                width={24}
                height={24}
              />
            </StepIConContainer>

            <StepperConnector />
          </StepVisuals>
          <StepDataContainer>
            <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {step.title}
            </StepTitle>
            <StepDescription
              size={FontSizes.TX_MD}
              weight={FontWeights.REGULAR}
            >
              {step.description}
            </StepDescription>
          </StepDataContainer>
        </StepContainer>
      );
    });
  };

  return <StepperWrapper>{renderSteps()}</StepperWrapper>;
};

export default NewEmployeeContractStepper;
