import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const PaymentSectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.bg_primary};
  padding-block: ${Spaces.spacing_lg} ${Spaces.spacing_lg};
  padding-inline: ${Spaces.spacing_3xl};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.border_secondary};
`;

export const PaymentSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const PaymentSectionTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PaymentsContainer = styled.div`
  display: flex;
  padding: ${Spaces.spacing_lg} ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_md};
  border: 1px solid ${Colors.bg_primary_hover};
  background-color: ${Colors.bg_primary_hover};
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: fit-content;

`;

export const NoPaymentText = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const PaymentCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_sm};
`;
export const PaymentCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_xl};
  background-color: ${Colors.bg_primary_hover};
  border-radius: ${Radiuses.radius_md};
`;
export const PaymentCardLeftCol = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${Spaces.spacing_md};
`;
export const PaymentCardData = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PaymentCardType = styled(Typography)`
  color: ${Colors.text_quarterary_on_brand};
`;
export const PaymentCardDate = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;
export const PaymentCardRightCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const PaymentCardAmount = styled(Typography)`
  color: ${Colors.text_secondary_hover};
`;
