import styled from "styled-components";
import { Colors, Spaces } from "../../constants/styles";

export const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.bg_active};
  flex: 1;
  padding-inline: ${Spaces.spacing_xl};

  height: 100%;
  overflow-y: auto;
`;
