import { FontSizes, FontWeights } from "../../../constants/styles";
import {
  Container,
  Progress,
  Bar,
  ProgressText,
  ProgressSubtext,
  Row,
} from "./styled";

export const ProgressBar = ({
  condition,
  subtext,
}: {
  condition: string;
  subtext?: string;
}) => {
  return (
    <Container>
      <Row>
        <Bar>
          <Progress progress={condition} />
        </Bar>
        <ProgressText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          {condition}
        </ProgressText>
      </Row>

      {subtext && (
        <ProgressSubtext size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          {subtext}
        </ProgressSubtext>
      )}
    </Container>
  );
};
