import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Typography from "../../../shared/Typography";

export const DeliveryStepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;

export const DeliveryStepButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const NextButton = styled(Button)`
  width: 48%;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;

export const Warning = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  padding: ${Spaces.spacing_xl};
  align-items: flex-start;
  width: 100%;
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.fg_warning_primary};
  background-color: ${Colors.bg_warning_primary};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: ${Spaces.spacing_md};
`;

export const WarningText = styled(Typography)`
  color: ${Colors.text_tertiary_hover};
`;

export const RadioOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;
export const RadioOptionContainer = styled.div<{
  disabled?: boolean;
  active: boolean;
}>`
  display: flex;
  padding: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid
    ${({ active, disabled }) =>
      active
        ? Colors.border_brand_solid
        : disabled
        ? Colors.border_disabled_subtle
        : Colors.border_secondary};

  background-color: ${({ disabled }) =>
    disabled ? Colors.bg_disabled_subtle : Colors.bg_primary};
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NotesInput = styled.textarea<{
  error?: boolean;
}>`
  font-family: inter;
  padding: 10px 14px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.border_primary};
  border-radius: ${Radiuses.radius_md};
  font-size: "1rem";
  font-weight: 400;
  color: ${Colors.text_primary};
  text-overflow: ellipsis;
  margin-top: ${Spaces.spacing_sm};
  height: 82px;

  ::placeholder {
    color: ${Colors.text_placeholder};
  }

  &:focus {
    outline: none;

    ${(props) =>
      !props.disabled &&
      !props.error &&
      `
  border: 1px solid ${Colors.border_brand};

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(158, 119, 237, 0.24);
`};

    ${(props) =>
      !props.disabled &&
      props.error &&
      `
  border: 1px solid ${Colors.border_error};

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);
`};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${Colors.bg_disabled_subtle};
  border: 1px solid ${Colors.border_disabled};
  color: ${Colors.text_disabled};
`};

  ${(props) =>
    props.error &&
    `
  border: 1px solid ${Colors.border_error};
  ~ ${NotesInputInfo} {

    color: ${Colors.text_error_primary};
  }
`};
`;

export const ExtraMessage = styled.p`
  display: block;
  margin-block-end: ${Spaces.spacing_none};
  margin-block-start: ${Spaces.spacing_sm};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.REGULAR};
`;

export const NotesInputInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${Colors.text_tertiary_600};
`;
