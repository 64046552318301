import React from "react";
import Typography from "../../components/shared/Typography";
import { Link } from "react-router-dom";
import { FontSizes, FontWeights } from "../../constants/styles";
import { Colors } from "../../constants/styles";
import {
  LoginContainer,
  LoginImg,
  LoginInnerContainer,
  BGPattern,
  ScreenTitle,
  ScreenSubTitle,
  SignUpBtnContainer,
} from "./styled";
import Logo from "../../Images/logos/Logo.svg";
import LoginBG from "../../Images/loginBGPattern.svg";
import { CopyRightText } from "../../components/shared/CopyRight";
import { RegistrationSteps } from "../../constants/registration";
import LoginForm from "../../components/login/LoginForm";

const LoginScreen: React.FC = () => {
  return (
    <LoginContainer>
      <LoginInnerContainer>
        <BGPattern src={LoginBG} alt="logo" />

        <img src={Logo} alt="logo" />

        <ScreenTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Welcome back
        </ScreenTitle>
        <ScreenSubTitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Welcome back! Please enter your details.{" "}
        </ScreenSubTitle>

        <LoginForm />
        <SignUpBtnContainer>
          <Typography
            size={FontSizes.TX_SM}
            weight={FontWeights.REGULAR}
            style={{ color: Colors.text_tertiary_600 }}
          >
            Don't have an account?
          </Typography>
          <Link to={`/register/${RegistrationSteps.account_type}`}>
            {" "}
            <Typography
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
              style={{ color: Colors.button_tertiary_color_fg }}
            >
              Sign up
            </Typography>
          </Link>
        </SignUpBtnContainer>
      </LoginInnerContainer>

      <LoginImg />

      <CopyRightText />
    </LoginContainer>
  );
};

export default LoginScreen;
