import { ButtonText, EmailBtnContainer } from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import EmailIcon from "../../../Images/icons/email.svg";

const EmailButton = () => {
  return (
    <EmailBtnContainer href="mailto:help@ovarc.io" target="_blank">
      <img src={EmailIcon} width={16} height={16} alt="email-icon" />
      <ButtonText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
        help@ovarc.io
      </ButtonText>
    </EmailBtnContainer>
  );
};

export default EmailButton;
