import styled from "styled-components";
import { Breakpoints, Colors, Radiuses } from "../../../constants/styles";

export const ModalContainer = styled.dialog`
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  background-color: ${Colors.bg_primary};
  border-radius: ${Radiuses.radius_xl};
  border: none;
  padding: 0px;
  max-width: 80%;
  max-height: 90%;

  &::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 17, 29, 0.7);
    backdrop-filter: blur(8px);
  }

  @media (max-width: ${Breakpoints.tablet}) {
    max-width: 100%;
    max-height: 100%;
  }
`;
