import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  Radiuses,
  Spaces,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Typography from "../../../shared/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spaces.spacing_4xl};
  flex: 1;
  padding-right: ${Spaces.spacing_6xl};
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding-inline: ${Spaces.spacing_4xl};
  }
`;

export const Title = styled(Typography)`
  color: ${Colors.text_brand_primary};
  white-space: pre;
`;
export const SubtitleContainer = styled.div`
  margin-block-start: ${Spaces.spacing_xs};
  display: flex;
  gap: ${Spaces.spacing_xs};
  flex-wrap: wrap;
`;
export const SubTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const ResendEmailContainer = styled.div`
  width: fit-content;
  padding: ${Spaces.spacing_3xl};
  background-color: ${Colors.bg_primary};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: ${Radiuses.radius_xl};
  margin-top: ${Spaces.spacing_3xl};
`;

export const InfoIconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_tertiary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const ResendEmailTitle = styled(Typography)`
  margin-bottom: ${Spaces.spacing_xs};
  color: ${Colors.text_primary};
`;
export const ResendEmailSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre;
  margin-bottom: ${Spaces.spacing_4xl};
`;

export const ResendEmailButton = styled(Button)`
  width: 100%;
`;
