import React from "react";
import {
  MultipleActionCardContainer,
  CardTitle,
  ActionContainer,
  ActionTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";

interface MultipleActionCardProps {
  title: string;
  actions: {
    title: string;
    btnText?: string;
    onClick?: () => void;
  }[];
  style?: React.CSSProperties;
}

const MultipleActionCard: React.FC<MultipleActionCardProps> = ({
  title,
  actions,
  style,
}) => {
  const renderActions = () => {
    return actions.map((action) => {
      return (
        <ActionContainer key={action.title}>
          <ActionTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {action.title}
          </ActionTitle>
          {action.btnText && (
            <Button
              onClick={action.onClick}
              text={action.btnText}
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              style={{
                width: "fit-content",
              }}
            />
          )}
        </ActionContainer>
      );
    });
  };

  return (
    <MultipleActionCardContainer style={style}>
      <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
        {title}
      </CardTitle>
      <>{renderActions()}</>
    </MultipleActionCardContainer>
  );
};

export default MultipleActionCard;
