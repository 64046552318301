import styled from "styled-components";
import {
  ButtonSizes,
  ButtonVariants,
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";

export const StyledButton = styled.button<{
  size: ButtonSizes;
  variant: ButtonVariants;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  gap: ${Spaces.spacing_sm};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${(props) => {
    switch (props.variant) {
      case ButtonVariants.primary:
        return `
            background-color:${Colors.button_primary_bg};
            color:${Colors.button_primary_fg};
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border: 1px solid ${Colors.button_primary_bg};

            &:hover {
              background-color:${Colors.button_primary_hover};
            }
            &:focus{
              
              ${
                props.disabled
                  ? ""
                  : "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.24);"
              }

               }



            &:disabled {
              background-color: ${Colors.bg_disabled};
              color: ${Colors.fg_disabled};
              border: 1px solid ${Colors.border_disabled_subtle};

            }

            `;
      case ButtonVariants.secondary_gray:
        return `
            background-color:${Colors.button_secondary_bg};
            color:${Colors.button_secondary_fg};
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border: 1px solid ${Colors.button_secondary_border};

            &:hover {
              background-color:${Colors.button_secondary_bg_hover};
            }
            &:focus {
              
              ${
                props.disabled
                  ? ""
                  : "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14);"
              }

               }

            &:disabled {
              background-color: ${Colors.bg_primary};
              color: ${Colors.fg_disabled};
              border: 1px solid ${Colors.border_disabled_subtle};

            }

            `;
      case ButtonVariants.secondary_color:
        return `
                  background-color:${Colors.button_secondary_bg};
                  color:${Colors.button_secondary_color_fg};
                  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                  border: 1px solid ${Colors.button_secondary_color_border};
      
                  &:hover {
                    background-color:${Colors.button_secondary_color_bg_hover};
                  }
                  &:focus{
                    
                    ${
                      props.disabled
                        ? ""
                        : "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.24);"
                    }
      
                     }
      
      
      
                  &:disabled {
                    background-color: ${Colors.bg_primary};
                    color: ${Colors.fg_disabled};
                    border: 1px solid ${Colors.border_disabled_subtle};
      
                  }
      
                  `;
      case ButtonVariants.link_gray:
        return `
        padding-inline: 0px;
        background-color: transparent;
              color:${Colors.text_tertiary_600};
              border: none;
              box-shadow: none;
              &:hover {
                color:${Colors.button_tertiary_color_fg_hover};
              }

              &:disabled {
                color: ${Colors.fg_disabled};
              }
             `;
      case ButtonVariants.link_color:
        return `
        padding: 0;
        background-color: transparent;
        color:${Colors.button_tertiary_color_fg};
        border: none;
        box-shadow: none;

        &:hover {
         color:${Colors.bg_brand_section};
                 }
      
        &:disabled {
           color: ${Colors.fg_disabled};
                }
                   `;
      case ButtonVariants.tertiary_color:
        return `
      padding: 0;
      color: ${Colors.button_tertiary_color_fg};
      background-color:${Colors.button_tertiary_color_bg_hover};
      border: none;
      box-shadow: none;

                 `;
      case ButtonVariants.tertiary_gray:
        return `
      padding: 0;
      color: ${Colors.text_tertiary_600};
      background-color: transparent;
      border: none;
       box-shadow: none;

       &:hover {
        background-color:${Colors.button_secondary_bg_hover};
                }

      &:disabled {
                  color: ${Colors.fg_disabled};
                       }
          
                           `;
      case ButtonVariants.destructive_secondary:
        return `
        background-color:${Colors.bg_primary};
        color:${Colors.button_tertiary_error_fg};
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border: 1px solid ${Colors.border_error};


        &:hover {
          background-color:${Colors.utility_error_50};
          color:${Colors.error_fg_hover};
        }

        &:focus{
          background-color:${Colors.bg_primary};
          ${
            props.disabled
              ? ""
              : "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);"
          }

           }
           
        &:disabled {
          background-color: ${Colors.bg_primary};
          color: ${Colors.fg_disabled};
          border: 1px solid ${Colors.border_disabled_subtle};
        }
        `;
      case ButtonVariants.destructive_primary:
        return `
        background-color:${Colors.text_error_primary};
        color:${Colors.fg_white};
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border: 1px solid ${Colors.text_error_primary};

        &:hover {
          background-color:${Colors.button_tertiary_error_fg};
          color:${Colors.error_fg_hover};
        }

        &:focus{
          ${
            props.disabled
              ? ""
              : "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24);"
          }

           }

        &:disabled {

          background-color: ${Colors.bg_disabled};
          color: ${Colors.fg_disabled};
          border: 1px solid ${Colors.border_disabled_subtle};
        }
        `;
    }
  }}

  ${(props) => {
    switch (props.size) {
      case ButtonSizes.xxl:
        return `
            height: 60px;
            padding:  ${Spaces.spacing_xl} 22px;
            font-size: ${FontSizes.TX_LG};
            font-weight: ${FontWeights.SEMIBOLD};
            `;

      case ButtonSizes.xl:
        return `
            height: 48px;
            padding:  ${Spaces.spacing_lg} 18px;
            font-size: ${FontSizes.TX_LG};
            font-weight: ${FontWeights.SEMIBOLD};
            `;

      case ButtonSizes.lg:
        return `
            height: 44px;
            padding: 10px ${Spaces.spacing_xl};
            font-size: ${FontSizes.TX_MD};
            font-weight: ${FontWeights.SEMIBOLD};
            `;
      case ButtonSizes.md:
        return `
            height: 40px;
            padding: 8px 14px;
            font-size: ${FontSizes.TX_SM};
            font-weight: ${FontWeights.SEMIBOLD};
            `;

      case ButtonSizes.sm:
        return `
            height: 36px;
            padding: ${Spaces.spacing_md} ${Spaces.spacing_lg};
            font-size: ${FontSizes.TX_SM};
            font-weight: ${FontWeights.SEMIBOLD};
            `;
    }
  }}
`;
