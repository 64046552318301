import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../Typography";

export const EmailBtnContainer = styled.a`
  position: absolute;
  bottom: ${Spaces.spacing_4xl};
  right: ${Spaces.spacing_4xl};
  color: ${Colors.text_white};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: ${Spaces.spacing_md};
`;

export const ButtonText = styled(Typography)`
  color: ${Colors.text_white};
  text-decoration: none;
`;
