import React from "react";
import { CompanyContainer, ScreenTitle } from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import CompaniesList from "../../components/company";

const CompanyScreenAdmin: React.FC = () => {
  return (
    <CompanyContainer>
      <ScreenTitle size={FontSizes.MD} weight={FontWeights.SEMIBOLD}>
        Companies
      </ScreenTitle>
      <CompaniesList />
    </CompanyContainer>
  );
};

export default CompanyScreenAdmin;