import { UPCOMING_ACTIONS_DASHBOARD } from "../mockServer/MockData";
import { BadgeTypes } from "./componentsProps";

export type Company = {
  id: number;
  name: string;
  industry: string;
  phone: string;
  legal_company_name: string;
  tax_id: string | null;
  registration_number: string | null;
  country: number;
  org_id: number;
  plan: number;
  corporation_type: string;
  established: boolean;
  invoice_currency: string;
  billing_name: string;
  company_status: string;
};

export enum COMPANY_STATUS {
  ALL = "All",
  NEW = "New",
  PENDING = "Pending",
  VERIFIED = "Verified",
  REJECTED = "Rejected",
  ON_HOLD = "On Hold",
  TERMINATED = "Terminated",
};

export interface CompanyInterface extends Company {
  organization: {
    name: string
    user: {
      id: number,
      first_name: string,
      last_name: string,
      email: string
    }
  },
}

export const COMPANY_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [COMPANY_STATUS.REJECTED]: BadgeTypes.error,
  [COMPANY_STATUS.VERIFIED]: BadgeTypes.success,
  [COMPANY_STATUS.ON_HOLD]: BadgeTypes.warning,
  [COMPANY_STATUS.TERMINATED]: BadgeTypes.error,
  [COMPANY_STATUS.PENDING]: BadgeTypes.gray,
  [COMPANY_STATUS.NEW]: BadgeTypes.blue
};

export type UpcomingActionsDashboardType = typeof UPCOMING_ACTIONS_DASHBOARD
