import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const PaymentEmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 25px;
  border-radius: ${Spaces.spacing_md};
  background-color: ${Colors.Brand_500};
  padding: ${Spaces.spacing_3xl};
  box-sizing: border-box;
  white-space: pre-line;
  justify-content: space-between;
  height: 270px;
`;

export const PaymentEmptyStateTitle = styled(Typography)`
  color: #fff;
  margin-bottom: ${Spaces.spacing_xl};
`;

export const PaymentEmptyStateDescription = styled(Typography)`
  color: #fff;
  margin-bottom: ${Spaces.spacing_xl};
`;
