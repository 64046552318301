import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import {
  ContactUsBannerContent,
  ContactUsBannerContainer,
  ContactUsBannerTitle,
  ContactUDescription,
  BannerImage,
} from "./styled";

import ContactUsImg from "../../../Images/contactUsBannerImg.svg";
const ContactUsBanner = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <ContactUsBannerContainer style={style}>
      <ContactUsBannerContent>
        <ContactUsBannerTitle size={FontSizes.TX_XL} weight={FontWeights.BOLD}>
          We’d love to hear from you.
        </ContactUsBannerTitle>
        <ContactUDescription size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
          Our dedicated team is ready to assist you with any questions or
          concerns you may have.
        </ContactUDescription>
        <Button
          text={"Contact Us"}
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.sm}
          style={{ width: "fit-content" }}
          onClick={() => console.log("Contact Us")}
        />
      </ContactUsBannerContent>
      {/* <ImageContainer> */}
      <BannerImage
        src={ContactUsImg}
        alt="contact-us"
        width={149}
        height={139}
      />
      {/* </ImageContainer> */}
    </ContactUsBannerContainer>
  );
};

export default ContactUsBanner;
