import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Card, CardRow } from "../../shared/Card";

export const PayrollDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PayrollDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  width: 100%;
  align-items: start;
`;
export const PayrollDetailsHeaderTitleContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
`;

export const PayrollDetailsHeadersButtonsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const PayrollDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PayrollDetailsSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TransparentRow = styled(CardRow)`
  background-color: transparent;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;

export const PayrollDetailsCardRow = styled(CardRow)`
  width: 100%;
`;

export const PayslipDownloadSubtitle  = styled(Typography)`
  color: ${Colors.text_warning_primary_600};
`;

export const Header = styled(Card)`
  margin-bottom: ${Spaces.spacing_md};
;
`;
