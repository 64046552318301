import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import {
  StepContainer,
  StepDataContainer,
  StepIConContainer,
  StepTitle,
  StepVisuals,
  StepperConnector,
  StepperWrapper,
} from "./styled";

const AssetsStepper = ({
  steps,
  activeStep,
}: {
  steps: {
    title: string;
    icon: string;
    iconCompleted?: string;
    completed: boolean;
  }[];

  activeStep: number;
}) => {
  const renderSteps = () => {
    return steps.map((step, index) => {
      return (
        <StepContainer isActive={index === activeStep}>
          <StepVisuals>
            <StepIConContainer isComplete={step.completed}>
              <img
                src={step.completed ? step.iconCompleted : step.icon}
                alt={step.title}
                width={24}
                height={24}
              />
            </StepIConContainer>
            {index !== steps.length - 1 && <StepperConnector />}
          </StepVisuals>
          <StepDataContainer>
            <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {step.title}
            </StepTitle>
          </StepDataContainer>
        </StepContainer>
      );
    });
  };

  return <StepperWrapper>{renderSteps()}</StepperWrapper>;
};

export default AssetsStepper;
