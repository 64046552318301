import styled from "styled-components";
import { Colors, Spaces } from "../../../../../constants/styles";
import Typography from "../../../../../components/shared/Typography";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-inline: ${Spaces.spacing_xl};
  min-height: 30rem;
`;

export const ModalHeader = styled.div`
  width: 100%;
  padding-block: ${Spaces.spacing_3xl};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;

export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Spaces.spacing_xl};
  padding-bottom:${Spaces.spacing_xl};
  gap: ${Spaces.spacing_xl};
  margin-top: auto;
`;
