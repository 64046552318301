import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const RequestAssetContainer = styled.div`
  width: 100%;
`;
export const RequestAssetHeader = styled.div`
  width: 100%;
  margin-bottom: ${Spaces.spacing_lg};
  border-bottom: 1px solid ${Colors.border_secondary};
  padding-bottom: ${Spaces.spacing_xl};
`;

export const RequestAssetTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const RequestAssetSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const RequestAssetContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StepsContainer = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;
