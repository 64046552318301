import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../queries/apiClient";
import { Company } from "../../types/companies";
import { CompanyDetails, GetCompanyListParams, LoginResponse } from "../../types/apisTypes";
import qs from "qs";

export const useGetCompaniesListAdmin = () => {
   return useQuery<Company[], Error>({
   queryFn: async () => {
      const response = await apiClient.get(
         `/admin/company`
      );
      return response.data.companies;
   },
   queryKey: ["companiesListAdmin"],
   });
};

export const useGetAllCompaniesAdmin = (params: GetCompanyListParams) => {
  return useQuery<CompanyDetails, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/admin/companies?${qs.stringify(params)}`);
      return response.data;
    },
    queryKey: ["allCompanies", params]
  })
};

export const useImpersonateAdmin = () => {
  return useMutation<LoginResponse, Error, number>({
    mutationFn: async (id) => {
      const response = await apiClient.post("/admin/impersonate", {id});
      return response.data;
    },
  });
};

export const usePasswordReset = () => {
  return useMutation<string, Error, string>({
    mutationFn: async (email) => {
      const response = await apiClient.post("/admin/forgot_password", {email});
      return response.data;
    },
  });
};

