import React, { useEffect, useState } from "react";
import { PaymentContainer, PaymentHeader, PaymentTitle } from "./styled";
import { FontSizes, FontWeights } from "../../constants/styles";
import { PayrollSubtitle } from "../Payroll/styled";
import PaymentEmptyState from "../../components/payment/PaymentEmptyState";
import Tabs from "../../components/shared/Tabs";
import InvoicesDue from "../../components/payment/InvoicesDue";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PaymentsList from "../../components/payment/PaymentsList";
import { useGetInvoicesList } from "../../queries/payment";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SnackbarTypes } from "../../types/componentsProps";
import { resetPayInvoicesSteps, setCurrentPayInvoicesStep, setSelectedInvoices } from "../../redux/slices/paymentSlice";

const PaymentScreen: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOutlet, setIsOutlet] = useState(false);
  const { invoices_due_tableVariables } = useAppSelector(
    (state) => state.payment
  );
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { activeCompany } = useAppSelector((state) => state.companies);

  const { data, error } = useGetInvoicesList({
    sort: invoices_due_tableVariables.sortKey,
    sort_dir: invoices_due_tableVariables.sortDirection,
    company_id: activeCompany?.id,
    page: invoices_due_tableVariables.page,
    limit: invoices_due_tableVariables.limit,
  });

  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching invoices.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  useEffect(() => {
    if(!location.pathname.includes("pay-invoices")){
      dispatch(resetPayInvoicesSteps())
      dispatch(setCurrentPayInvoicesStep(0))
    }
    setIsOutlet(
      location.pathname.includes("invoice-details") ||
        location.pathname.includes("pay-invoices") ||
        location.pathname.includes("payment-details")
    );
  }, [location]);

  useEffect(() => {
    return function() {
      dispatch(
        setSelectedInvoices([])
      );
    }
  }, [])

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <InvoicesDue />;
      case 1:
        return <PaymentsList />;
      default:
        return null;
    }
  };

  return (
    <PaymentContainer>
      {isOutlet ? (
        <Outlet />
      ) : (
        <>
          {" "}
          <PaymentHeader>
            <PaymentTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
              Payment
            </PaymentTitle>
            <PayrollSubtitle
              size={FontSizes.TX_MD}
              weight={FontWeights.REGULAR}
            >
              View and manage payments here{" "}
            </PayrollSubtitle>
            <Tabs
              tabsTitles={["Invoices due", "Payments"]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </PaymentHeader>
          {data?.invoices?.length || activeTab == 1 ? (
            renderTabContent()
          ) : (
            <PaymentEmptyState />
          )}
        </>
      )}
    </PaymentContainer>
  );
};

export default PaymentScreen;
