import {
  BannerCloseIcon,
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerTitle,
  ImagesContainer,
} from "./styled";
import { DashboardBanner } from "../../../types";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import RightArrow from "../../../Images/icons/arrowRightWhite.svg";
import CloseIcon from "../../../Images/icons/bannerCloseIcon.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { storeDashboardBanners } from "../../../redux/slices/appState";
const Banner = ({
  id,
  title,
  color,
  description,
  image,
  btnText,
  onClick,
}: DashboardBanner) => {
  const banners = useAppSelector((state) => state.appState.dashboardBanners);

  const dispatch = useAppDispatch();

  const onClose = (id: string) => {
    const newBanners = banners.filter((banner) => banner.id !== id);
    dispatch(storeDashboardBanners([...newBanners]));
  };
  return (
    <BannerContainer color={color}>
      <BannerContent>
        <BannerTitle size={FontSizes.TX_XL} weight={FontWeights.BOLD}>
          {title}
        </BannerTitle>
        <BannerDescription size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
          {description}
        </BannerDescription>

        <Button
          text={btnText}
          variant={ButtonVariants.primary}
          iconTrailing={
            <img src={RightArrow} alt="right arrow" width={20} height={20} />
          }
          size={ButtonSizes.sm}
          style={{ width: "fit-content" }}
          onClick={onClick}
        />
      </BannerContent>
      <ImagesContainer>
        <BannerCloseIcon
          src={CloseIcon}
          alt="banner-close-icon"
          width={16}
          height={16}
          onClick={() => onClose(id)}
        />
        {image}
      </ImagesContainer>
    </BannerContainer>
  );
};

export default Banner;
