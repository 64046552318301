import { useEffect, useState } from 'react'
import { Container, Subtitle, Title, TitlesContainer, ResendContainer, RequirementItem, PasswordInformation } from './styled'
import { ButtonSizes, ButtonVariants, Colors, FontSizes, FontWeights, Spaces } from '../../../constants/styles'
import Typography from '../../shared/Typography'
import InputField from '../../shared/InputField'
import { emailRegex } from '../../../constants/registration'
import { useForgotPassword, useResetPassword } from '../../../queries/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AlertInfo from '../../../Images/icons/alertInfo.svg'
import Button from '../../shared/Button'
import { LOGIN_SCREEN } from '../../../constants/routesNames'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { SnackbarTypes } from '../../../types/componentsProps'

const ResetPassword = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  
  const [ searchParams ] = useSearchParams();
  const token = searchParams.get("token");

  const [ data, setData ] = useState({ email: "", password: "", confirmPassword: "" });
  const [ error, setError ] = useState({ email: "", password: false, confirmPassword: "" });
  const [ resendUI, setResendUI ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ showPasswordHints, setShowPasswordHints ] = useState(false);

  const { mutateAsync: sendEmail, isPending } = useForgotPassword({email: data.email});
  const { mutateAsync: resetPassword } = useResetPassword();

  const handleSendEmail = async () => {
    if (!emailRegex.test(data.email)) {
      setError({...error, email: "Please enter a valid email address"});
      return;
    }
    try {
      await sendEmail();
      setResendUI(true);
    } catch (err) {
      setError({ ...error, email: "Error resending email" });
    }
  };

  const handlePassword = async () => {
    if(data.password !== data.confirmPassword) {
      setError({...error, confirmPassword: "Password Mismatch"});
      return;
    }
    try {
      if(token) {
        await resetPassword({password: data.password, token});
        setSuccess(true);
      }
    }
    catch (err: any) {
      const strongPasswordError: boolean = err.response.data.errors?.some((error: string) => error.includes("strong"));
      if (strongPasswordError) {
        setError({...error, password: true})
        setShowPasswordHints(true);
      }
      else {
        showSnackbar({
          open: true,
          title: "Login error",
          description: err.response?.message || "Token Expired or Something Went Wrong",
          type: SnackbarTypes.ERROR,
        });
      }
    }
  }
  
  return (
    <Container>
      {success ? (
        <TitlesContainer>
          <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Password Reset
          </Title>
          <Subtitle size={FontSizes.TX_LG} weight={FontWeights.REGULAR}>
            Your password has been successfully reset.
          </Subtitle>
          <Button
            text="Continue to Login"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            onClick={() => {
              navigate(LOGIN_SCREEN);
            }}
          />
      </TitlesContainer>
      ) : token ? (
        <>
          <TitlesContainer>
            <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
              Set New Password
            </Title>
            <Subtitle size={FontSizes.TX_LG} weight={FontWeights.REGULAR}>
              Your new password must be different from<br />the previously used passwords.
            </Subtitle>
          </TitlesContainer>
          <InputField
            label="Password"
            type="password"
            name="password"
            containerStyle={{ marginBottom: Spaces.spacing_3xl, width: "100%" }}
            value={data.password}
            onChange={(e) => {
              setData({...data, password: e.target.value});
            }}
            onFocus={() => {
              setError({
                ...error,
                password: false,
                confirmPassword: ""
              });
            }}
            hint="Must be at least 8 characters long, and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
          />
          <InputField
            label="Confirm Password"
            type="password"
            name="confirm password"
            containerStyle={{ marginBottom: Spaces.spacing_3xl, width: "100%" }}
            value={data.confirmPassword}
            onChange={(e) => {
              setData({...data, confirmPassword: e.target.value});
            }}
            error={error.confirmPassword}
            onFocus={() => {
              setError({
                ...error,
                password: false,
                confirmPassword: ""
              });
            }}
          />
          {showPasswordHints ? (
            <PasswordInformation>
              <RequirementItem>
                <img src={AlertInfo} alt="Alert Info" style={{ width: '30px', height: '30px' }} />
                <Typography size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                  Must be at least 8 characters
                </Typography>
              </RequirementItem>
              <RequirementItem>
                <img src={AlertInfo} alt="Alert Info" style={{ width: '30px', height: '30px' }} />
                <Typography size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                  Must contain at least 1 lowercase character
                </Typography>
              </RequirementItem>
              <RequirementItem>
                <img src={AlertInfo} alt="Alert Info" style={{ width: '30px', height: '30px' }} />
                <Typography size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                  Must contain at least 1 uppercase character
                </Typography>
              </RequirementItem>
              <RequirementItem>
                <img src={AlertInfo} alt="Alert Info" style={{ width: '30px', height: '30px' }} />
                <Typography size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                  Must contain at least 1 number
                </Typography>
              </RequirementItem>
              <RequirementItem>
                <img src={AlertInfo} alt="Alert Info" style={{ width: '30px', height: '30px' }} />
                <Typography size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                  Must contain at least 1 symbol
                </Typography>
              </RequirementItem>
            </PasswordInformation>
          ) : null}
          <Button
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            text="Reset Password"
            onClick={handlePassword}
            disabled={error.password || error.confirmPassword !== ""}
            style={{width: "100%"}}
          />
        </>
      ) : isPending ? (
        <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
          Resending email...
        </Title>
      ) : !resendUI ? (
        <>
          <TitlesContainer>
            <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
              Forgot Password?
            </Title>
            <Subtitle size={FontSizes.TX_LG} weight={FontWeights.REGULAR}>
              No worries, we’ll send you reset instructions.
            </Subtitle>
          </TitlesContainer>
          <InputField
            label="Email"
            value={data.email ? data.email : ""}
            onChange={(e) => setData({...data, email: e.target.value})}
            placeholder=""
            containerStyle={{ marginBlock: Spaces.spacing_2xl, width: "100%" }}
            error={error.email}
            onFocus={() => setError({...error, email: ""})}
          />
          <Button
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            text="Send Email"
            onClick={handleSendEmail}
            disabled={data.email === ""}
          />
        </>
      ) : (
        <TitlesContainer>
          <Title size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Check your email
          </Title>
          <Subtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
            We sent a password reset link to {data.email}
          </Subtitle>
          <ResendContainer>
            <Subtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
              Didn’t receive the email?
            </Subtitle>
            <Typography
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
              style={{ color: Colors.button_tertiary_color_fg }}
              onClick={handleSendEmail}
            >
              Resend Email
            </Typography>
          </ResendContainer>
        </TitlesContainer>
      )}
    </Container>
  )
}

export default ResetPassword