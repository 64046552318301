import styled from "styled-components";
import { Table } from "../../../shared/Table/styled";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { Link } from "react-router-dom";

export const EmployeeTableContainer = styled(Table)`
  border-radius: ${Radiuses.radius_xl};
  border-color: ${Colors.border_secondary};
  border-style: solid;
  background-color: ${Colors.bg_primary};
  border-collapse: separate;
  position: relative;
  scrollbar-width: none;
  overflow: hidden;
`;

export const TextFaded = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xs};
`;
export const Divider = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${Colors.border_secondary};
`;

export const NameCell = styled(Link)`
  display: flex;
  gap: ${Spaces.spacing_lg};

  align-items: center;
  text-decoration: none;
`;

export const ActionsCell = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
`;

export const ActionCellLink = styled(Link)`
  display: flex;
  gap: ${Spaces.spacing_xs};
  text-decoration: none;
`;
export const ActionButton = styled.img`
  cursor: pointer;
  width: 20x;
  height: 20x;
`;
