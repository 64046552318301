import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../Typography";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
`;

export const Row = styled.div`
  display: flex;
  align-self: stretch;
  gap: ${Spaces.spacing_lg};
  align-items: center;
`;

export const ProgressText = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const ProgressSubtext = styled(Typography)`
  color: ${Colors.fg_disabled};
`;

export const Bar = styled.div`
  display: flex;
  height: 8px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.utility_gray_200};
  flex-grow: 1;
`;
export const Progress = styled.div<{ progress: string }>`
  height: 100%;
  width: ${({ progress }) => progress};
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_brand_solid};
`;
