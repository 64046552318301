import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { TableRowDynamic } from "../../../shared/Table/styled";

export const InvoicesTableContainer = styled.div`
  margin-top: ${Spaces.spacing_xl};
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${Radiuses.radius_xl};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  margin-top: ${Spaces.spacing_2xl};
  background-color: ${Colors.bg_primary};
`;

export const TableFadedText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TableBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const TableCustomHeader = styled(TableRowDynamic)`
  background-color: ${Colors.bg_secondary};
`;
