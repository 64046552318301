import styled from "styled-components";
import { Colors, Spaces } from "../../constants/styles";
import Typography from "../../components/shared/Typography";
export const AssetsHubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.bg_active};
  flex: 1;
  padding-inline: ${Spaces.spacing_xl};
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
`;

export const AssetsHubHeader = styled.div`
  width: 100%;
  margin-bottom: ${Spaces.spacing_3xl};
`;

export const AssetsHubHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const AssetsHubHeaderButtons = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const AssetsHubTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const AssetsHubSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;
