import { ReactNode } from "react";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { BadgeTypes } from "../../../../types/componentsProps";
import Badge from "../../../shared/Badge";
import Checkbox from "../../../shared/Checkbox";
import {
  ButtonsContainer,
  IconContainer,
  OptionContainer,
  PaymentMethodCard,
  PaymentMethodStepContainer,
  StepTitle,
  StepTitleCard,
  Subtext,
  TextContainer,
  Title,
  TitleContainer,
} from "./styled";
import Button from "../../../shared/Button";
import Bank from "../../../../Images/icons/bank.svg";
import CreditCard from "../../../../Images/icons/credit-card-02.svg";
import Crypto from "../../../../Images/icons/crypto.svg";
import Paypal from "../../../../Images/icons/paypal.svg";
import {
  onPayInvoicesNextStep,
  onPayInvoicesPreviousStep,
} from "../../../../redux/slices/paymentSlice";

const PaymentMethod = () => {
  const { pay_invoices_steps, current_pay_invoices_step } = useAppSelector(
    (state) => state.payment
  );
  const dispatch = useAppDispatch();

  const Option = ({
    title,
    subtext,
    icon,
    isActive,
    isDisabled,
  }: {
    title: string;
    subtext: string;
    icon: ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
  }) => {
    return (
      <OptionContainer isActive={isActive} isDisabled={isDisabled}>
        <IconContainer>{icon}</IconContainer>
        <TextContainer>
          <TitleContainer>
            <Title size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {title}
            </Title>
            {isDisabled ? (
              <Badge text="Coming Soon" color={BadgeTypes.blueLight} />
            ) : null}
          </TitleContainer>

          <Subtext size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
            {subtext}
          </Subtext>
        </TextContainer>
        <Checkbox
          checked={isActive}
          onChange={() => {}}
          disabled={isDisabled}
          label={""}
          subtext={""}
          containerStyle={{ marginLeft: "auto" }}
        />
      </OptionContainer>
    );
  };
  return (
    <PaymentMethodStepContainer>
      {" "}
      <StepTitleCard>
        <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Step {current_pay_invoices_step}:{" "}
          {pay_invoices_steps[current_pay_invoices_step].title}
        </StepTitle>
      </StepTitleCard>
      <PaymentMethodCard>
        <Option
          title="Send a manual transfer"
          subtext="Send a wire transfer from your bank"
          icon={<img src={Bank} alt="bank" />}
          isActive
        />
        <Option
          title="Paypal"
          subtext="Safe payment online"
          icon={<img src={Paypal} alt="paypal-logo" />}
          isDisabled
        />
        <Option
          title="Link a payment method"
          subtext="Supports credit and debit cards"
          icon={<img src={CreditCard} alt="credit-card" />}
          isDisabled
        />
        <Option
          title="Send a crypto-currency transfer"
          subtext="Supports USDC through your Coinbase account"
          icon={<img src={Crypto} alt="crypto" />}
          isDisabled
        />
      </PaymentMethodCard>
      <ButtonsContainer>
        <Button
          text="Cancel"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          onClick={() => {
            dispatch(onPayInvoicesPreviousStep());
          }}
        />
        <Button
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            dispatch(onPayInvoicesNextStep());
          }}
        />
      </ButtonsContainer>
    </PaymentMethodStepContainer>
  );
};

export default PaymentMethod;
