import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import Button from "../../../shared/Button";
import {
  ConfirmButton,
  FieldsContainer,
  FinancialInfoStepButtonsContainer,
  FinancialInfoStepContainer,
} from "./styled";
import LeftArrow from "../../../../Images/icons/arrow-left.svg";
import {
  editToBeAddedAsset,
  onNextAddAssetStep,
  onPreviousAddAssetStep,
} from "../../../../redux/slices/assetsHubSlice";
import { Card, CardTitle } from "../../../shared/Card";
import InputField from "../../../shared/InputField";
const FinancialInfoStep = () => {
  const dispatch = useAppDispatch();
  const { financialInfo } = useAppSelector(
    (state) => state.assetsHub.toBeAddedAsset
  );
  const checkIfAllFieldsAreFilled = () => {
    return Object.values(financialInfo).every((value) => value !== "");
  };
  return (
    <FinancialInfoStepContainer>
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Financial Information
        </CardTitle>
        <FieldsContainer>
          <InputField
            label="Purchase Date"
            value={financialInfo.purchaseDate}
            type="date"
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  financialInfo: {
                    ...financialInfo,
                    purchaseDate: e.target.value,
                  },
                })
              );
            }}
          />
          <InputField
            label="Purchase Price"
            value={financialInfo.purchasePrice}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  financialInfo: {
                    ...financialInfo,
                    purchasePrice: e.target.value,
                  },
                })
              );
            }}
          />
          <InputField
            label="Depreciation Method"
            value={financialInfo.deprecationMethod}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  financialInfo: {
                    ...financialInfo,
                    deprecationMethod: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Current Value"
            value={financialInfo.currentValue}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  financialInfo: {
                    ...financialInfo,
                    currentValue: e.target.value,
                  },
                })
              );
            }}
          />
        </FieldsContainer>
      </Card>
      <FinancialInfoStepButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={LeftArrow} alt="left arrow" />}
          onClick={() => {
            dispatch(onPreviousAddAssetStep());
          }}
        />
        <ConfirmButton
          text="Confirm & Request"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => {
            dispatch(onNextAddAssetStep());
          }}
          disabled={!checkIfAllFieldsAreFilled()}
        />
      </FinancialInfoStepButtonsContainer>
    </FinancialInfoStepContainer>
  );
};

export default FinancialInfoStep;
